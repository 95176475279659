<template>
  <div class="range_input">
    <input
      type="range"
      :id="id"
      :value="value"
      :min="min"
      :max="max"
      :disabled="disabled"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  name: 'RangeInput',
  props: {
    id: {
      type: String,
      default: 'rangeInput'
    },
    value: {
      type: [Number, String],
      required: true
    },
    min: {
      type: [Number, String],
      default: 0
    },
    max: {
      type: [Number, String],
      default: 100
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(event) {
      // Emit the new value whenever the range changes
      this.$emit('input', event.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.range_input {
  input[type="range"] {
    width: 100%;
  }
}


.range_input input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

.range_input input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #F2E7FE;
  border-radius: 16px;
}

 .range_input input[type="range"]::-moz-range-track {
  height: 15px;
  background: #F2E7FE;
  border-radius: 16px;
}

 .range_input input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #8D25F6;
  border-radius: 50%;
  border: 2px solid #8D25F6;
  box-shadow: -407px 0 0 400px #8D25F6;
}

.range_input input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #8D25F6;
  border-radius: 50%;
  border: 1px solid #8D25F6;
  box-shadow: -407px 0 0 400px #8D25F6;
}

</style>

