var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "column content-box" }, [
      _c("section", { staticClass: "details_container" }, [
        _c(
          "div",
          [
            _c(
              "woot-button",
              {
                attrs: {
                  "color-scheme": "success",
                  "class-names": "button--fixed-right-top add_order_item_btn",
                  icon: "add",
                  disabled: _vm.isAddItemDisabled
                },
                on: { click: _vm.openAddItemModal }
              },
              [
                _vm._v(
                  "\n                      " +
                    _vm._s(_vm.$t("ORDER.DETAILS.ADD_ITEM_BTN")) +
                    "\n              "
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div", [
          _c("div", {}, [
            _c("p", { staticClass: "order_label font_regular" }, [
              _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.ORDER_ID")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "order_value font_regular" }, [
              _vm._v(_vm._s(_vm.order.id))
            ]),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "order_hint font_regular order_hint_warning" },
              [_vm._v(_vm._s(_vm.paymentNotify.message))]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", {}, [
            _c("p", { staticClass: "order_label font_regular" }, [
              _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.STATUS")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "order_value font_regular" }, [
              _vm._v(_vm._s(_vm.order.order_status.title))
            ]),
            _vm._v(" "),
            _vm.order.delivery_service
              ? _c(
                  "p",
                  { staticClass: "order_hint font_regular order_hint_mute" },
                  [
                    _c("fluent-icon", {
                      staticClass: "order_hint_mute",
                      attrs: { icon: "clock" }
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                          " +
                          _vm._s(_vm.$t("ORDER.DETAILS.INFO.STATUS_HINT")) +
                          " " +
                          _vm._s(
                            _vm.formatDateToCustomString(
                              _vm.delivery_service.pickup_scheduled_at
                            )
                          ) +
                          "\n                      "
                      )
                    ])
                  ],
                  1
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.order.shipment_address
            ? _c("div", [
                _c("p", { staticClass: "order_label font_regular" }, [
                  _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.SHIP_ADDRESS")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "order_value font_regular" }, [
                  _vm._v(
                    "\n                      " +
                      _vm._s(_vm.order.shipment_address.line1) +
                      ",\n                      " +
                      _vm._s(_vm.order.shipment_address.city) +
                      ",\n                      " +
                      _vm._s(_vm.order.shipment_address.country) +
                      "\n                  "
                  )
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.order.delivery_service && _vm.delivery_service.tracking_number
            ? _c("div", [
                _c("p", { staticClass: "order_label font_regular" }, [
                  _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.CONSIGNMENT")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "order_value font_regular" }, [
                  _vm._v(_vm._s(_vm.delivery_service.tracking_number))
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "order_label font_regular" }, [
            _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.NAME")))
          ]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "order_value font_regular" },
            [
              _vm._v(
                "\n                  " +
                  _vm._s(_vm.contactName) +
                  "\n                  "
              ),
              _vm.orderConversationLink
                ? _c(
                    "router-link",
                    { attrs: { to: _vm.orderConversationLink } },
                    [
                      _c("woot-button", {
                        attrs: {
                          size: "small",
                          variant: "clear",
                          "color-scheme": "primary",
                          icon: "chat"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.order.delivery_service && _vm.order.delivery_service.price
            ? _c("div", [
                _c("p", { staticClass: "order_label font_regular" }, [
                  _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.DELVARY_PRICE")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "order_value font_regular" }, [
                  _vm._v(_vm._s(_vm.order.delivery_service.price))
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "order_label font_regular" }, [
            _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.ORDER_TIME")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "order_value font_regular" }, [
            _vm._v(
              _vm._s(new Date(_vm.order.created_at).toGMTString().slice(0, -7))
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "order_label font_regular" }, [
            _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.PHONE")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "order_value font_regular" }, [
            _vm._v(_vm._s(_vm.contactPhoneNumber))
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.order.delivery_service && _vm.order.delivery_service.status_text
            ? _c("div", [
                _c("p", { staticClass: "order_label font_regular" }, [
                  _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.DELVARY_STATUS")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "order_value font_regular" }, [
                  _vm._v(_vm._s(_vm.order.delivery_service.status_text))
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "order_label font_regular" }, [
            _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.CURRENCY")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "order_value font_regular" }, [
            _vm._v(_vm._s(_vm.order.currency))
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "order_label font_regular" }, [
            _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.PAID")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "order_value font_regular" }, [
            _vm._v(_vm._s(_vm.order.paid_total))
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.order.delivery_service && _vm.order.delivery_service.failed_reason
            ? _c("div", [
                _c("p", { staticClass: "order_label font_regular" }, [
                  _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.DELVARY_FAIL_MSG")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "order_value font_regular" }, [
                  _vm._v(_vm._s(_vm.order.delivery_service.failed_reason))
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "order_label font_regular" }, [
            _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.REMARK")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "order_value font_regular" }, [
            _vm._v(_vm._s(_vm.order.remark))
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { staticClass: "order_label font_regular" }, [
            _vm._v(_vm._s(_vm.$t("ORDER.DETAILS.INFO.REFUNDED")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "order_value font_regular" }, [
            _vm._v(_vm._s(_vm.order.refund_amount))
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.order.delivery_service && _vm.order.delivery_service.tracking_url
            ? _c("div", [
                _c("p", { staticClass: "order_label font_regular" }, [
                  _vm._v(
                    _vm._s(_vm.$t("ORDER.DETAILS.INFO.DELVARY_TRAKING_URL"))
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "order_value font_regular" }, [
                  _vm.order.delivery_service.tracking_url
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "order_value font_regular order_tracking",
                          attrs: {
                            href: _vm.order.delivery_service.tracking_url,
                            target: "_blank",
                            rel: "noopener noreferrer"
                          }
                        },
                        [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                _vm.$t(
                                  "ORDER.DETAILS.INFO.DELIVERY_TRAKING_BTN"
                                )
                              ) +
                              "\n                      "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", [
          _vm.order.delivery_service &&
          _vm.order.delivery_service.print_label_url
            ? _c("div", [
                _c("p", { staticClass: "order_label font_regular" }, [
                  _vm._v(
                    "\n                      " +
                      _vm._s(_vm.$t("ORDER.DETAILS.INFO.DELVARY_PRINT_URL")) +
                      "\n                      "
                  ),
                  _c(
                    "span",
                    { staticClass: "tooltip_info" },
                    [
                      _c("fluent-icon", {
                        staticClass: "help_icon",
                        attrs: { icon: "question-circle" }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "tooltip_card",
                          staticStyle: { bottom: "18px" }
                        },
                        _vm._l(
                          _vm.$t("ORDER.DETAILS.INFO.DELIVERY_HINTS"),
                          function(info, index) {
                            return _c(
                              "p",
                              { key: index, staticClass: "mb_0 font_12" },
                              [_vm._v(_vm._s(info))]
                            )
                          }
                        ),
                        0
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "order_value font_regular" }, [
                  _vm.order.delivery_service.print_label_url
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "order_value font_regular order_tracking",
                          attrs: {
                            href: _vm.order.delivery_service.print_label_url,
                            target: "_blank",
                            rel: "noopener noreferrer"
                          }
                        },
                        [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                _vm.$t("ORDER.DETAILS.INFO.DELVARY_PRINT_BTN")
                              ) +
                              "\n                      "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "padding-top mt_20" },
        [
          _c("OrderItemsTable", {
            attrs: {
              order_items: _vm.order.order_items,
              cols: _vm.cols,
              merchant: _vm.merchant,
              order: _vm.order,
              isLoading: _vm.isOrderLoading && !_vm.isUpdating,
              paymentNotify: _vm.paymentNotify,
              isOrderCancelOrComplete: _vm.isOrderCancelOrComplete,
              onRefundAmount: _vm.openRefundModal
            },
            on: {
              "on-sort-change": _vm.onSortChange,
              onQuantityChange: _vm.onQuantityChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "woot-button",
            {
              staticClass: "button--fixed-left-top",
              attrs: {
                size: "small",
                "color-scheme": "primary",
                isLoading: _vm.isUpdating,
                isDisabled: _vm.isUpdating || _vm.isOrderCancelOrComplete
              },
              on: { click: _vm.updateOrderDetails }
            },
            [_vm._v("\n              Update\n          ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showAddItemModal
        ? _c(
            "div",
            [
              _c("AddItemModal", {
                attrs: {
                  show: _vm.showAddItemModal,
                  "on-close": _vm.closeAddItemModal,
                  order: _vm.order,
                  categories: _vm.categories,
                  catalogueItems: _vm.catalogueItems
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showRefundModal
        ? _c(
            "div",
            [
              _c("RefundModal", {
                attrs: {
                  show: _vm.showRefundModal,
                  "on-close": _vm.closeRefundModal,
                  order: _vm.order,
                  onSuccess: _vm.onRefundSuccess
                }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", [
        _c("p", { staticClass: "order_label font_regular" }),
        _vm._v(" "),
        _c("p", { staticClass: "order_value font_regular" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", [
        _c("p", { staticClass: "order_label font_regular" }),
        _vm._v(" "),
        _c("p", { staticClass: "order_value font_regular" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }