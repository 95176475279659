<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('CAMPAIGN.EMAIL_TEMPLATE.LIST.HEADER')" />
    <hr />
    <div class="row template_container">
      <div
        v-if="emailTemplates.length === 0"
        class="columns medium-12"
        style="display: flex; justify-content: center;"
      >
        <woot-button
          @click="addNewTemplate"
        >{{ $t('CAMPAIGN.EMAIL_TEMPLATE.LIST.ADD_BUTTON_TEXT') }}</woot-button>
      </div>
      <div
        class="small-12 columns"
        style="padding: 10px;"
        v-show="emailTemplates.length > 0"
        v-for="template in emailTemplates"
        :key="template.id"
        @click="onSelect(template)"
      >
        <!-- @click="$emit('selectTemplate', {templateHtml: template.templateBody.html})" -->
        <div class="card" :class="{active_template:selectedTemplate  && selectedTemplate.id === template.id}">
          <div class="row" style="display: flex; align-items: center; margin-bottom: 10px;">
            <div class="medium-8">
              <span class="fs-small">{{ template.name }}</span>
            </div>
          </div>
          <div style="max-height: 200px; overflow-y: scroll;" v-html="template.templateBody.html"></div>
        </div>
      </div>
    </div>
    <div class="email_tpl_btns">
        <woot-button
        class="email_btn email_btn_solid"
        @click="onClickDone"
        >
        {{ $t('CAMPAIGN.EMAIL_TEMPLATE.BTNS.BROADCAST') }}
        </woot-button>
        <woot-button
        class="email_btn"
        variant="clear"
        @click="onClose"
        >
        {{ $t('CAMPAIGN.EMAIL_TEMPLATE.BTNS.CANCEL') }}
        </woot-button>
    </div>
  </woot-modal>
</template>

<script>
import EmailTemplateAPI from '../../../../../api/emailTemplates';

export default {
  name: 'EmailTemplateList',
  data() {
    return {
      show: true,
      isFetchinigEmailTemplate: false,
      emailTemplates: [],
      selectedTemplate: null,
    };
  },
  created() {
    this.getTemplates();
  },
  methods: {
    addNewTemplate() {
      this.$router.push({ name: 'email_template_add' });
    },
    onClose() {
      this.$emit('close');
    },
    async getTemplates(serverParams) {
      this.isFetchinigEmailTemplate = true;
      await EmailTemplateAPI.getTemplates(serverParams)
        .then(response => {
          let tempData = [];
          if (response.data) {
            response.data.map(data => {
              tempData.push({ ...data, templateBody: JSON.parse(data.body) });
            });
          }
          this.emailTemplates = tempData;
        })
        .catch(error => {})
        .finally(() => {
          this.isFetchinigEmailTemplate = false;
        });
    },
    onSelect(item){
      console.log(item);
      this.selectedTemplate = item
    },
    onClickDone(){
      this.$emit('selectTemplate', {templateHtml: this.selectedTemplate.templateBody.html});
      this.selectedTemplate = null;
      this.onClose()
    },
  },
};
</script>


<style lang="scss" scoped>
.template_container{
  max-height: 57vh;
  overflow-y: auto;
}
.email_tpl_btns{
    display: flex;
    gap: 16px;
    margin-top: 32px;
    .email_btn{
        width: 280px;
    }
    .email_btn_solid{
        background-color: #8D25F6 !important;
    }
}
.active_template{
  border: 1px solid #8D25F6;
}
</style>