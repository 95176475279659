var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "sidebar-item" },
    [
      _vm.hasSubMenu
        ? _c("span", { staticClass: "secondary-menu--title fs-small" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("SIDEBAR." + _vm.menuItem.label)) +
                "\n  "
            )
          ])
        : _c(
            "router-link",
            {
              staticClass:
                "secondary-menu--title secondary-menu--link fs-small",
              class: _vm.computedClass,
              attrs: { to: _vm.menuItem && _vm.menuItem.toState }
            },
            [
              _vm.menuItem.iconImage
                ? _c("img", {
                    staticClass: "icon-image",
                    attrs: {
                      src: require("../../../assets/images/menu-icons/" +
                        _vm.menuItem.iconImage),
                      alt: _vm.name + "-menu-icon"
                    }
                  })
                : _c("fluent-icon", {
                    staticClass: "secondary-menu--icon",
                    attrs: {
                      icon: _vm.menuItem.icon,
                      size: "14",
                      viewBox: _vm.menuItem.viewBox
                        ? _vm.menuItem.viewBox
                        : "0 0 24 24"
                    }
                  }),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("SIDEBAR." + _vm.menuItem.label)) +
                  "\n  "
              )
            ],
            1
          ),
      _vm._v(" "),
      _vm.hasSubMenu
        ? _c(
            "ul",
            { staticClass: "nested vertical menu" },
            [
              _vm._l(_vm.menuItem.children, function(child) {
                return _c("secondary-child-nav-item", {
                  key: child.id,
                  attrs: {
                    to: child.toState,
                    label: child.label,
                    "label-color": child.color,
                    "should-truncate": child.truncateLabel,
                    icon: _vm.computedInboxClass(child)
                  }
                })
              }),
              _vm._v(" "),
              _vm.showItem(_vm.menuItem)
                ? _c("router-link", {
                    attrs: { to: _vm.menuItem.toState, custom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var href = ref.href
                            var isActive = ref.isActive
                            var navigate = ref.navigate
                            return [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "button small clear menu-item--new secondary",
                                    class: { "is-active": isActive },
                                    attrs: { href: href },
                                    on: {
                                      click: function(e) {
                                        return _vm.newLinkClick(e, navigate)
                                      }
                                    }
                                  },
                                  [
                                    _c("fluent-icon", {
                                      attrs: { icon: "add", size: "16" }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "button__content" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t(
                                                "SIDEBAR." +
                                                  _vm.menuItem.newLinkTag
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      109933172
                    )
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }