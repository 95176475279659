<template>
  <div>
    <MultiselectDropdown
      :label="$t('COUPON.ADD_FORM.TYPE_FORM.DISCOUNT_TYPE.LABEL')"
      :error="$t('COUPON.ADD_FORM.TYPE_FORM.DISCOUNT_TYPE.ERROR')"
    >
      <RadioList
        v-model="discount_type"
        :options="discount_types"
        :classWrapper="'couponOptions'"
      />
    </MultiselectDropdown>
    <InputField
        v-model="discount_value"
        :label="$t('COUPON.ADD_FORM.TYPE_FORM.TITLE.LABEL')"
        :placeholder="$t('COUPON.ADD_FORM.TYPE_FORM.TITLE.PLACEHOLDER')"
        :error="$v.discount_value.$error ? $t('COUPON.ADD_FORM.TYPE_FORM.TITLE.ERROR') : ''"
        :infoList="[]"
        :textLength="discount_value"
        :postFix="'%'"
        @blur="$v.discount_value.$touch"
    />

<div>{{discount_value.length}}</div>

    <div class="submit_btns">
      <woot-button
        color-scheme="primary" size="small" :style="{width:'100%'}"
        :is-loading="false"
        :is-disabled="false"
        @click="onNext"
      >
        {{$t('COUPON.ADD_FORM.TYPE_FORM.BTNS.NEXT')}}
      </woot-button>
  </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import Spinner from 'shared/components/Spinner.vue';
import RadioList from "../../../../dashboard/arrange/RadioList.vue"
import MultiselectDropdown from "../../../../../components/ui/inputs/MultiselectDropdown.vue"
import InputField from '../../../../../components/ui/inputs/InputField.vue';
import ToggleField from '../../../../../components/ui/inputs/ToggleField.vue';


export default {
  components: {
    RadioList,
    MultiselectDropdown,
    InputField,
    ToggleField,
  },
  mixins: [alertMixin],
  data(){
    return {
      discount_type: 0,
      discount_value: 0,
    }
  },
  computed:{
    discount_types(){
      return [
          {
              id:'type1',
              label: this.$t('COUPON.ADD_FORM.TYPE_FORM.DISCOUNT_TYPE.LIST.PERCENT'),
              // description: this.$t('COUPON.ADD_FORM.TYPE_FORM.TYPE.LIST.UNIQUE_DESC'),
          },
          {
              id:'type2',
              label: this.$t('COUPON.ADD_FORM.TYPE_FORM.DISCOUNT_TYPE.LIST.FIXED'),
              // description: this.$t('COUPON.ADD_FORM.TYPE_FORM.TYPE.LIST.NON_UNIQUE_DESC'),
          },
      ]
    }
  },
  validations:{
    discount_value:{
      required
    }
  },
  methods:{
    onNext(){
      const nextStep = 3;
      this.$emit('onNext', nextStep)
    },
  },
}
</script>




<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.couponOptions{
    /* margin-top: 4px; */
    display: flex;
    gap: 24px;
}

.submit_btns{
    margin-top: 24px;
}
</style>

<style>
.couponOptions .radio-item{
    gap: 0;
}
.couponOptions .radio_label_wrap{
    margin-bottom: 2px;
}
.couponOptions .radio_label{
    font-weight: 500;
    color: #304352;
}
.couponOptions .radio_description{
    font-weight: 500;
    color: #858585;
    font-size: 12px;
}
</style>
