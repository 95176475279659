<template>
  <div class="content-box">
    <StepBasedForm
        :title="$t('COUPON.ADD_FORM.HEADER.TITLE')"
        :range_label="step_label"
        :current_step="step"
        :max_steps="max_steps"
    >
        <CouponType
            v-if="step === 1"
            @onNext="onNext"
        />
        <CouponDiscount
            v-if="step === 2"
        />
        <AddCouponPreview
            v-if="showPreview"
        />
    </StepBasedForm>
  </div>
</template>

<script>
import StepBasedForm from "dashboard/components/widgets/forms/StepBasedForm.vue"
import AddCouponPreview from "./addSteps/AddCouponPreview.vue"
import CouponDiscount from "./addSteps/CouponDiscount.vue"
import CouponType from "./addSteps/CouponType.vue"

export default {
    components:{
        StepBasedForm,
        CouponType,
        CouponDiscount,
        AddCouponPreview,
    },
    data(){
        return {
            step: 2,
            max_steps: 2,
            showPreview: false,
        }
    },
    computed:{
        step_label(){
            return ` ${this.step} ${this.$t('COUPON.ADD_FORM.HEADER.STEP_OF')} ${this.max_steps} ${this.$t('COUPON.ADD_FORM.HEADER.STEP_REMAINING')}`
        },
    },
    methods:{
        onNext(step){
            this.step = step;
        }
    },
}
</script>

<style>

</style>
