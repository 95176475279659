<template>
  <section class="orderItems_table_wrap">
      <!-- scroll-width="120rem" -->
    <ve-table
      :fixed-header="true"
      max-height="100%"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />

    <div>
      <div v-if="isLoading" class="loader_and_empty">
        <spinner />
        <span>{{ 'Order is loading' }}</span>
      </div>
      <empty-state
        v-else-if="!isLoading && !order_items.length"
        title="Empty order items"
      />
    </div>

  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';
import alertMixin from 'shared/mixins/alertMixin';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';

export default {
    components:{
        VeTable,
        Spinner,
        EmptyState,
    },
    mixins: [alertMixin],
    props:{
        order_items:{
            type: Array,
            default: () => []
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isOrderCancelOrComplete: {
            type: Boolean,
            default: false,
        },
        cols: {
            type: Array,
            default: () => [],
        },
        merchant: {
          type: Object,
          default: {},
        },
        order: {
          type: Object,
          default: {},
        },
        paymentNotify: {
          type: Object,
          default: {},
        },
        onRefundAmount: {
          type: Function,
          default: function(){},
        },

    },
    data(){
        return {
          sortConfig: {},
          sortOption: {
            sortAlways: true,
            sortChange: params => this.$emit('on-sort-change', params),
          },
        }
    },
    computed:{
        tableData(){
            // console.log(this.cols,this.order_items);
            if (this.isLoading || !this.order_items?.length) {
                return [];
            }

            const formattedOrders = this.order_items.map(item=>{
                const preSet = {
                  ...item,
                  total:parseFloat(parseFloat(item.item_price) * (parseInt(item.quantity)||0).toFixed(2))
                }
                if (!('original_inventory' in item)) {
                  preSet.original_inventory = item.available_inventory + item.quantity;
                }
                return preSet;
            })

            // calculate total, sst, grantTotal here
            const sumTotal = formattedOrders.reduce((acc, item) => acc + parseFloat(item.item_price) * (parseInt(item.quantity)||0), 0);

            const sst = this.merchant.sst === 'true' ? parseFloat(sumTotal * 0.08) : 0;
            // const sst = parseFloat(sumTotal * 0.08);
            const delivery_price = this.order.shipping_total ||0;
            const grantTotal = sumTotal + sst + delivery_price;

            const sumRows = [
              {
                item_price: "Price",                        // at 'item_price' column display text 'Total'
                total: parseFloat(sumTotal.toFixed(2)),      // at 'total' column display sum amount
                isTitle: true,
                class:"font_500"
              },
              {
                item_price: "Courier Fee",
                total: parseFloat((this.order.shipping_total ||0).toFixed(2)),
                isTitle: true,
                class:"font_500"
              },
              {
                item_price: "SST",                          // at 'item_price' column display text 'SST'
                total: parseFloat(sst.toFixed(2)),           // at 'total' column display sst amount
                isTitle: true,
                class:"font_500"
              },
              {
                item_price: "Order Amount",                        // at 'item_price' column display text 'Grand Total'
                total: parseFloat(grantTotal.toFixed(2)),          // at 'total' column display grantTotal amount
                isTitle: true,
                class:"font_600"
              },
              {
                item_price: "Paid Amount",
                total: parseFloat((this.order.paid_total || 0).toFixed(2)),
                isTitle: true,
                isPayMessage: true,
                class:"font_500"
              },
              {
                item_price: "Refund Amount",
                total: parseFloat((this.order.refund_amount || 0).toFixed(2)), // add inupt field so user can update
                isTitle: true,
                isRefundAmount: true,
                class:"font_500"
              },

            ]
            return [
              ...formattedOrders,
              ...sumRows,
            ]
        },
        columns(){
            let matches = {};

            matches.item_name = {
                field: 'item_name',
                key: 'item_name',
                title: 'Item Name',
                fixed: 'left',
                align: 'left',
                width: 240,
                sortBy: this.sortConfig.item_name || '',
                renderBodyCell: ({row})=>{
                    return <span>{row.item_name}</span>
                }
            }
            matches.item_price = {
                field: 'item_price',
                key: 'item_price',
                title: `Price`,
                currency: this.order?.currency,
                align: 'right',
                sortBy: this.sortConfig.item_price || '',
                renderBodyCell: ({row})=>{
                    const price = typeof row.item_price === "number"
                    ? row.item_price.toFixed(2) : row.item_price;
                    if (row.isTitle) {
                      return <span class={["order_edit_tbl_title",row.class].join(" ")}>{price}</span>
                    }
                    return <span class={row.isTitle ? "order_edit_tbl_title":""}>{price}</span>
                },
                renderHeaderCell:({ column })=>{
                    return <span class="order_head_col">
                        <span>{column.title}</span>
                        <span class="order_head">({column.currency})</span>
                    </span>
                }
            }
            matches.quantity = {
                field: 'quantity',
                key: 'quantity',
                title: 'Quantity',
                align: 'right',
                sortBy: this.sortConfig.quantity || '',
                renderBodyCell: ({row, rowIndex })=>{
                  // console.log({rowIndex });
                    // return <span>{row.quantity}</span>
                    if (typeof row.quantity !== "number") {
                      return <span></span>
                    }
                    return <div>
                      <input
                        class={this.isOrderCancelOrComplete ? "disable_field remove_field_spin" :"remove_field_spin"}

                        style="text-align: right; padding-right:10px; font-size:14px;"
                        type="number"
                        value={row.quantity}
                        disabled={this.isOrderCancelOrComplete}
                        step="1"
                        min="0"
                        onKeydown={(e) => this.handleQuantity(e, row,rowIndex)}
                        onKeyup={(e) => this.handleQuantity(e, row,rowIndex)}
                        onChange={(e) => this.handleQuantity(e, row,rowIndex)}
                      />
                    </div>
                }
            }
            matches.total = {
                field: 'total',
                key: 'total',
                title: `Total`,
                currency: this.order?.currency,
                align: 'right',
                sortBy: this.sortConfig.total || '',
                renderBodyCell: ({row})=>{
                  const price = typeof row.total === "number"
                    ? row.total.toFixed(2) : row.total;
                    return <span class={row.isTitle ? "order_edit_tbl_title " + row.class:""}>
                      {price}
                    </span>
                },
                renderHeaderCell:({ column })=>{
                  return <span class="order_head_col">
                    <span>{column.title}</span>
                    <span class="order_head">({column.currency})</span>
                  </span>
                }
            }
            matches.available_inventory = {
                field: 'available_inventory',
                key: 'available_inventory',
                title: 'Inventory',
                align: 'right',
                sortBy: this.sortConfig.available_inventory || '',
                renderBodyCell: ({row})=>{
                  if (row.isTitle && row.isPayMessage) {
                    return <span class={row.isTitle ? "order_edit_tbl_title " + row.class:""}>
                      <span class={`pay_msg ${this.paymentNotify.msgClass}`}>{this.paymentNotify.message}</span>
                    </span>
                  }

                  // if(row.isRefundAmount && this.paymentNotify.isRefund){
                  if(row.isRefundAmount && (this.order.paid_total > 0)){
                    // allow refund always when paid > 0
                    return <woot-button
                        variant="smooth"
                        size="small"
                        color-scheme="primary"
                        class="button small refund_btn"
                        // disabled={isDisable}
                        onClick={this.onRefundAmount}
                      > Refund
                      </woot-button>
                  }

                  return <span>{row.available_inventory}</span>
                }
            }
            matches.action = {
                field: 'action',
                key: 'action',
                title: 'Action',
                align: 'center',
                renderBodyCell: ({row})=>{
                  if (typeof row.id != "number") {
                    return <span></span>
                  }

                  const enableStatuslList = ['fresh','pending','packed','paid'];
                  const isDisable = !enableStatuslList.includes(this.order?.order_status?.id);

                    return (
                    <div>
                      <woot-button
                        variant="smooth"
                        size="small"
                        color-scheme="alert"
                        class="order_item_del"
                        icon="delete"
                        disabled={isDisable}
                        onClick={()=>this.deleteItem(row)}
                      >
                      </woot-button>
                    </div>
                    )
                }
            }







            const preSet = [];
            this.cols.forEach(col=>{
                if (col.visible) {
                    if (col.key in matches) {
                        preSet.push(matches[col.key])
                    }
                }
            })
            return preSet;
        },
        // end computed
    },

    methods:{
      handleQuantity(e,rowData,rowIndex){
        // console.log({rowData});
        const newQuantity  = parseInt(e.target.value||"0");
        const oldQuantity = rowData.quantity;
        const isIncrease = newQuantity > oldQuantity;
        if (isIncrease) {
            // check not exeeding available
            if (rowData.available_inventory <= 0) {
              this.showAlert(`You can add a maximum of ${rowData.available_inventory} quantities of ${rowData.item_name}`);
              // Revert to previous quantity value
              e.target.value = rowData.quantity;
              return;
            }else if(newQuantity > rowData.original_inventory){
              e.target.value = rowData.quantity;
              this.showAlert(`Insufficient inventory!`);
            }else{
              this.$emit('onQuantityChange',e,rowData,rowIndex)
            }
        }else{
          // chek not less than 0
          if (newQuantity < 0) {
            this.showAlert(`Invalid quantity!`);
          }else{
            this.$emit('onQuantityChange',e,rowData,rowIndex)
          }
        }
        /*
        if (rowData.available_inventory <= 0) {
          this.showAlert(`You can add a maximum of ${rowData.available_inventory} quantities of ${rowData.item_name}`);
          // Revert to previous quantity value
          e.target.value = rowData.quantity;
          return;
        }
        this.$emit('onQuantityChange',e,rowData,rowIndex)
        */
        /*
          const quantity = parseInt(e.target.value||"0");
          const index = this.order_items.findIndex(item=>item.id ===rowData.id)
          console.log({index});
          if (index > -1) {
            this.order_items[index].quantity = quantity
          }
        */
      },
      deleteItem(row){
        const index = this.order_items.findIndex(item => item.id === row.id);
        if (index > -1) {
            this.order_items.splice(index, 1);
        }
      },
      // end methods
    },

}
</script>




<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.orderItems_table_wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.orderItems_table_wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
    .ve-table-container{
      overflow-y: hidden;
      .ve-table-content{
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    background-color: var(--w-500) !important;
    color: var(--white) !important;
    text-transform: capitalize;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: 14px;
  }
  .ve-table-sort {
    top: -4px;
  }

}

.loader_and_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-default);
  padding: var(--space-big);
}

.remove_field_spin {
    font-weight: 500;
    background: #EEEEEE;
    /* Hide arrows for Chrome, Safari, and Edge */
    -webkit-appearance: none; /* Remove default appearance */
    -moz-appearance: textfield; /* Remove default appearance in Firefox */
    appearance: none; /* Remove default appearance in modern browsers */
}

/* Specific to WebKit browsers */
.remove_field_spin::-webkit-inner-spin-button,
.remove_field_spin::-webkit-outer-spin-button {
    display: none; /* Hide the spin buttons */
}

.order_edit_tbl_title{
  font-family: Figtree, Inter, sans-serif;
  font-size: 14px;
  color: #1F1F1F;
}

.pay_msg{
  // font-family: Figtree, Inter, sans-serif;
  font-weight: 500;
  // font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
  // color: #3c4858;
  margin-left: 10px;
}
.pay_waiting_msg{
  background-color: #F7FEE7;
}
.pay_receive_msg{
  background-color: #F2E7FE;
}
.pay_refund_msg{
  background-color: #E5F1FF;
}
.refund_btn{
  padding: var(--space-smaller) 1em;;
}
.disable_field{
    background-color: #ededed;
}
</style>

<style>
.orderItems_table_wrap .ve-table-body-td{
  font-size: 14px;
  font-weight: 500;
  color: #1F1F1F !important;
}
.orderItems_table_wrap .order_head_col{
    /* width: 80%; */
    display: inline-grid;
    text-align: right;
}
.orderItems_table_wrap .order_head_col .order_head{
    display: inline-block;
    width: 100%;
    font-size: 10px;
}
.orderItems_table_wrap .order_item_del,
.orderItems_table_wrap .order_item_del:hover{
  background: #fff !important;
}
.orderItems_table_wrap .order_item_del svg{
  width: 20px;
  height: 20px;
  color: #E0E0E0;
}
</style>