var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: { "header-title": _vm.$t("CAMPAIGN.EMAIL_TEMPLATE.LIST.HEADER") }
      }),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row template_container" },
        [
          _vm.emailTemplates.length === 0
            ? _c(
                "div",
                {
                  staticClass: "columns medium-12",
                  staticStyle: { display: "flex", "justify-content": "center" }
                },
                [
                  _c("woot-button", { on: { click: _vm.addNewTemplate } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("CAMPAIGN.EMAIL_TEMPLATE.LIST.ADD_BUTTON_TEXT")
                      )
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.emailTemplates, function(template) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.emailTemplates.length > 0,
                    expression: "emailTemplates.length > 0"
                  }
                ],
                key: template.id,
                staticClass: "small-12 columns",
                staticStyle: { padding: "10px" },
                on: {
                  click: function($event) {
                    return _vm.onSelect(template)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "card",
                    class: {
                      active_template:
                        _vm.selectedTemplate &&
                        _vm.selectedTemplate.id === template.id
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "margin-bottom": "10px"
                        }
                      },
                      [
                        _c("div", { staticClass: "medium-8" }, [
                          _c("span", { staticClass: "fs-small" }, [
                            _vm._v(_vm._s(template.name))
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticStyle: {
                        "max-height": "200px",
                        "overflow-y": "scroll"
                      },
                      domProps: {
                        innerHTML: _vm._s(template.templateBody.html)
                      }
                    })
                  ]
                )
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "email_tpl_btns" },
        [
          _c(
            "woot-button",
            {
              staticClass: "email_btn email_btn_solid",
              on: { click: _vm.onClickDone }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CAMPAIGN.EMAIL_TEMPLATE.BTNS.BROADCAST")) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-button",
            {
              staticClass: "email_btn",
              attrs: { variant: "clear" },
              on: { click: _vm.onClose }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CAMPAIGN.EMAIL_TEMPLATE.BTNS.CANCEL")) +
                  "\n      "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }