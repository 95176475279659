<template>
    <div v-if="isOpen">
        <!-- form data & current form Node -->
        <modal
            :show.sync="isOpen"
            :on-close="closeModal"
            modalMainContainerClass="botExpressModal"
            closeBtnClass="modal_close_btn_left"
            closeIcon="chevron-left"
        >
            <div class="botExpressModal_container">
                <div class="header">
                    <h2 class="header_title">{{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.TITLE")}}</h2>
                    <div class="video_icon text_purple_primary" @click="openTutorial">
                        <fluent-icon icon="video" />
                    </div>
                </div>
                <!-- video tutorial modal -->
                <!-- <div v-if="showTutorial">
                    <modal
                        :show.sync="showTutorial"
                        :onClose="closeTutorial"
                    >
                        <p>Bot Tutorial</p>
                        <iframe
                        width="560" height="315"
                        src="https://www.youtube.com/embed/R-1c2xGajXc?si=6NM69jXGexxPnVZB?autoplay=1" title="YouTube video player"
                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                        ></iframe>
                    </modal>
                </div> -->

                <div class="step_range">
                    <!-- <p>current step = {{labels[current_step].step}}/{{totalSteps+1}}</p> -->
                    <p>
                        {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.PROGRESS.PART_1")}}
                        {{labels[current_step].step}}
                        {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.PROGRESS.PART_2")}}
                        {{totalSteps+1}}
                        {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.PROGRESS.PART_3")}}
                    </p>
                    <input
                        id="expressBot_step_range" type="range" name="volume" :value="currentStepIndex"
                        :min="0"
                        :max="totalSteps"
                        disabled
                    />
                </div>

                <div v-if="!isFinalStepQrModalOpen">
                    <section class="expressBot">
                        <aside>
                            <!-- <h2 class="main_title">{{labels[current_step].formTitle}}</h2>
                            <p class="main_description">{{labels[current_step].formDescription}}</p> -->

                            <h2 class="main_title">{{$t(labels[current_step].formTitle)}}</h2>
                            <p class="main_description">{{$t(labels[current_step].formDescription)}}</p>

                            <div v-for="(item,index) in currentForm.contents" :key="index">

                                <!-- if type card  -->
                                <template v-if="item.type === 'card'">
                                    <CardExressEdit
                                    :item="item"
                                    :constraint="constraints.card"
                                    :label="labels[current_step].contents[index]"
                                    :actionButtonlabel="labels[current_step].contents[index].actionButtons"
                                    />
                                </template>

                                <!-- if type category_list  -->
                                <template v-if="item.type === 'category_list'">
                                    <ListExpressEdit
                                        :constraint="constraints.category_list"
                                        :item="item"
                                        :label="labels[current_step].contents[index]"
                                        :categoryOptions="categoryOptions"
                                    />
                                </template>


                                <!-- if type text  -->
                                <template v-if="item.type === 'text'">
                                    <TextExpressEdit
                                        :constraint="constraints.text"
                                        :item="item"
                                        :label="labels[current_step].contents[index]"
                                        :form_nodes="form_nodes"
                                    />
                                </template>


                                <!-- if type image  -->
                                <template v-if="item.type === 'image'">
                                    <ImageExpressEdit
                                        :constraint="constraints.image"
                                        :item="item"
                                        :label="labels[current_step].contents[index]"
                                    />
                                </template>

                            </div>

                        </aside>
                        <aside>
                            <ExpressPreview
                            :currentForm="formattedCurrentForm"
                            :constraints="constraints"
                            />
                        </aside>
                    </section>


                    <footer class="row submit_btns">
                        <aside class="medium-6 columns">
                            <div v-if="isLastLabel">
                                <label class="form_label font_regular text_12" style="margin-bottom:4px;">
                                    <!-- Which inbox would you like to create this chatbot on? -->
                                    {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.LABELS.BOT_INBOX")}}
                                </label>
                                <multiselect
                                    class="column small-12 express_bot_multiselect"
                                     v-model="selectedInbox"
                                    :options="whatsAppInboxList"
                                    track-by="id"
                                    label="name"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :hide-selected="true"
                                    :placeholder="$t('MESSAGE_MGMT.ADD.FORM.INBOX_PLACEHOLDER')"
                                    selected-label
                                    :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                                    :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                                    @select="onSelectInbox($event)"
                                />
                            </div>

                            <woot-button
                                v-if="!isLastLabel"
                                class-names="tmp_submit"
                                :is-loading="isSubmitting"
                                @click="onNextHandler(false)"
                            >
                                <!-- Next -->
                                {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.NEXT")}}
                            </woot-button>
                            <woot-button
                                v-if="isLastLabel"
                                class-names="tmp_submit"
                                :is-loading="isSubmitting"
                                @click="onNextHandler(true)"
                            >
                                <!-- Submit -->
                                {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.SUBMIT")}}
                            </woot-button>
                            <woot-button v-if="isPreviousAvailable" class-names="tmp_submit" variant="clear" @click="onPreviousHandler">
                                <!-- Previous -->
                                {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.PREVIOUS")}}
                            </woot-button>
                            <woot-button v-else class-names="tmp_submit" variant="clear" @click="closeModal">
                            {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.CANCEL")}}
                            </woot-button>
                        </aside>
                    </footer>
                </div>

                <div v-if="isFinalStepQrModalOpen">

                    <div>
                        <QRCode :shop_url="whatsappUrl" :isOnlyQR="true" />
                    </div>
                    <div class="complete_step">
                        <div class=" header info">
                            <h2 class="header_title">{{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.TEST_TITLE")}}</h2>
                            <p class="description">{{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.TEST_DESCRIPTION")}}</p>
                        </div>
                        <div class="btns">
                            <woot-button
                                class-names="tmp_submit"
                                @click="onTryChatbotClick"
                            >
                                {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.TRY")}}
                            </woot-button>
                            <woot-button class-names="tmp_submit" variant="clear" @click="closeModal">
                                {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.CLOSE")}}
                            </woot-button>

                        </div>
                    </div>


                </div>

            </div>
        </modal>
    </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import Input from '../../../../components/widgets/forms/Input.vue';
import ExpressPreview from "./ExpressPreview.vue";
import { mapGetters } from 'vuex';
import { formAndAccountMerger,countCharacters} from "./expressBot";
import QRCode from "../../catalogue/settings/QRCode.vue";
import alertMixin from 'shared/mixins/alertMixin';
import { required, maxLength } from "vuelidate/lib/validators";
import WootButton from '../../../../components/ui/WootButton.vue';
import CardExressEdit from './CardExressEdit.vue';
import ListExpressEdit from './ListExpressEdit.vue';
import TextExpressEdit from './TextExpressEdit.vue';
import ImageExpressEdit from './ImageExpressEdit.vue';



export default {
    components:{
        Modal,
        ExpressPreview,
        Input,
        QRCode,
        WootButton,
        CardExressEdit,
        ListExpressEdit,
        TextExpressEdit,
        ImageExpressEdit,
    },
    mixins: [alertMixin],
    props:{
        isOpen: {
            type: Boolean,
            default: false
        },
        isFinalStepQrModalOpen: {
            type: Boolean,
            default: false
        },
        isSubmitting: {
            type: Boolean,
            default: false
        },
        current_step: {
            type: String,
            default: ''
        },
        labels: {
            type: Object,
            default: {}
        },
        form_nodes: {
            type: Object,
            default: {}
        },
        botform: {
            type: Object,
            default: {}
        },
        onNext: {
            type: Function,
            default: (next_step)=>{}
        },
        onPrevious: {
            type: Function,
            default: (pre_step)=>{}
        },
        // formAndAccountMerger: {
        //     type: Function,
        //     default: ()=>{}
        // },
    },
    data(){
        return {
            // isOpen: false,
            showTutorial: false,
        }
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            merchant: 'merchant/getMerchant',
            uiFlags: "catalog/getUIFlags",
            categories: "catalog/getCatagories",
            inboxes: 'inboxes/getInboxes',
        }),
        currentForm(){
            return this.form_nodes[this.current_step]
        },
        isLastLabel(){
            const steps = Object.keys(this.labels);
            const currentIndex = steps.indexOf(this.current_step);
            return currentIndex === steps.length - 1;
        },
        isPreviousAvailable() {
            const steps = Object.keys(this.labels);
            const currentIndex = steps.indexOf(this.current_step);
            return currentIndex > 0; // True if not the first step
        },
        currentStepIndex() {
            const steps = Object.keys(this.labels);
            return steps.indexOf(this.current_step);
        },
        totalSteps() {
            return Object.keys(this.labels).length - 1; // Maximum index value
        },
        formattedCurrentForm(){
            const formresult = formAndAccountMerger(this.merchant,this.botform,this.currentUser).form_data.form_nodes[this.current_step];
            return formresult
        },
        categoryOptions(){
            if (this.categories) {
                return this.categories.map(({id,title})=>({id,title}))
            }
            return []
        },
        whatsappUrl(){
            return `https://wa.me/${ this.selectedInbox?.phone_number}?text=PZ:${this.currentUser.account_id}`;
        },
        whatsAppInboxList(){
            const inboxes = this.inboxes.filter(el=>el.channel_type === "Channel::Whatsapp");
            // find the selected inbox and update
            return inboxes;
        },
        selectedInbox: {
            get() {
                // Find the object by id in the options list
                return this.whatsAppInboxList.find(
                (item) => item.id === this.botform.form_data.inbox
                );
            },
            set(selected) {
                // Set only the id value
                this.botform.form_data.inbox = selected ? selected.id : null;
            },
        },
        constraints(){
            if (this.botform?.form_data?.constraints) {
                return this.botform.form_data.constraints;
            }
            return {
                text: {
                    value_limit: 4096
                },
                image: {
                    title_limit: 1024
                },
                card: {
                    title_limit: 1024,
                    subtitle_limit: 60,
                    button_title_limit: 20
                },
                category_list: {
                    title_limit: 60,
                    body_limit: 4096,
                    footer_limit: 60,
                    button_limit: 20,
                    section_title_limit: 24,
                    row_title_limit: 24,
                    row_description_limit: 72,
                    max_row_length: 10,
                    min_row_length: 1
                }
            }
        },

    },
    mounted(){
        this.$store.dispatch("catalog/getCatagories");
    },
    methods:{
        validateInput({ index, field, parent, btnIndex, limit, value }) {
            // check the length after replacing the variables
            // Check if the input value exceeds the limit
            const total = countCharacters(this.currentUser,this.merchant,value).length;
            if (total > limit) {
                // this.showAlert(`Maximul ${limit} characters allowed`);
                this.showAlert(`
                ${this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}
                ${limit}
                ${this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}
                `);

                if (parent === 'actionButtons') {
                    // For action buttons, validate based on button index
                    this.$set(this.currentForm.contents[index].actionButtons, btnIndex, {
                        ...this.currentForm.contents[index].actionButtons[btnIndex],
                        [field]: value.slice(0, limit)
                    });
                } else if (parent === 'category_list.sections') {
                    // For section titles, validate based on section index
                    this.$set(this.currentForm.contents[index].sections, sectionIdx, {
                        ...this.currentForm.contents[index].sections[sectionIdx],
                        [field]: value.slice(0, limit)
                    });
                }
                else {
                    // how can i slice if that include variables
                    // For other fields (card, category_list, etc.)
                    this.$set(this.currentForm.contents, index, {
                        ...this.currentForm.contents[index],
                        [field]: value.slice(0, limit)
                    });
                }
            }
        },
        closeModal(){
            this.$emit('onClose');
        },
       onPreviousHandler() {
            const steps = Object.keys(this.labels);
            let currentIndex = steps.indexOf(this.current_step);
            if (currentIndex === -1) {
                return null;
            }

            while (currentIndex > 0) {
                const previousIndex = currentIndex - 1;
                const previousStep = steps[previousIndex];
                const dependency = this.form_nodes[previousStep]?.dependency;

                if (dependency) {
                    const isDependencyMet = this.isPassDependency(dependency.actionButtons);
                    if (isDependencyMet) {
                        this.onPrevious(previousStep);
                        return;
                    }
                } else {
                    this.onPrevious(previousStep);
                    return;
                }
                currentIndex--;
            }
            const firstStep = steps[0];
            this.onPrevious(firstStep);
        },
        async onNextHandler(isSubmit=false){
            // if current currentForm is list type, then must min-select a select row
            for (const item of this.currentForm.contents){
                // check all the input fields for all type if exed the input limit, return
                if (item.type === 'card') {
                    const titleLength = countCharacters(this.currentUser,this.merchant,item.title).length;
                    console.log({titleLength},this.constraints.card.title_limit);
                    if (titleLength > this.constraints.card.title_limit) {
                        this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.NEXT_BTN_ALERT'))
                        return
                    }
                    const subtitleLength = countCharacters(this.currentUser, this.merchant, item.subtitle).length;
                    if (subtitleLength > this.constraints.card.subtitle_limit) {
                        this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.NEXT_BTN_ALERT'));
                        return;
                    }

                    for (let btnIndex = 0; btnIndex < item.actionButtons.length; btnIndex++) {
                        const buttonLength = countCharacters(this.currentUser, this.merchant, item.actionButtons[btnIndex].title).length;
                        if (buttonLength > this.constraints.card.button_title_limit) {
                            this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.NEXT_BTN_ALERT'));
                            return;
                        }
                    }
                }

                else if (item.type === 'category_list') {
                    const constraint = this.constraints.category_list;
                    const titleLength = countCharacters(this.currentUser, this.merchant, item.title).length;
                    if (titleLength > constraint.title_limit) {
                        this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.NEXT_BTN_ALERT'));
                        return;
                    }

                    const bodyLength = countCharacters(this.currentUser, this.merchant, item.body).length;
                    if (bodyLength > constraint.body_limit) {
                        this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.NEXT_BTN_ALERT'));
                        return;
                    }

                    const footerLength = countCharacters(this.currentUser, this.merchant, item.footer).length;
                    if (footerLength > constraint.footer_limit) {
                        this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.NEXT_BTN_ALERT'));
                        return;
                    }
                    const buttonLength = countCharacters(this.currentUser, this.merchant, item.button).length;
                    if (buttonLength > constraint.button_limit) {
                        this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.NEXT_BTN_ALERT'));
                        return;
                    }



                    const isSubtype = ["calendar_list", "appointment_list", "outstanding_appointment"].includes(item.sub_type);
                    if (isSubtype) {
                        continue;
                    }
                    for (const section of item.sections){
                        if (section?.rows?.length < this.constraints.category_list?.min_row_length) {
                            this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MIN_LIST_ITEM_MSG'))
                            return
                        }
                    }
                }
                else if (item.type === 'text') {
                    const length = countCharacters(this.currentUser, this.merchant, item.value).length;
                    if (length > this.constraints.text.value_limit) {
                        this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.NEXT_BTN_ALERT'));
                        return;
                    }
                }
                else if (item.type === 'image') {
                    const length = countCharacters(this.currentUser, this.merchant, item.title).length;
                    if (length > this.constraints.image.title_limit) {
                        this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.NEXT_BTN_ALERT'));
                        return;
                    }

                }
            }



            // if is submit, make inbox is mandatory
            if (isSubmit) {
                if (!this.botform.form_data?.inbox) {
                    this.showAlert(this.$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.INBOX_MSG'));
                    return
                }
            }
            // update all "account_id", "google location",
            // do a loop on current step and update any dynamic fields for account_id

            const steps = Object.keys(this.labels);
            let currentIndex = steps.indexOf(this.current_step);

            if (currentIndex === -1) {
                return null; // Invalid current step
            }

            // this.onNext('Greeting', true);
            // return

            if (isSubmit) {
                this.onNext(this.current_step, true);
                return;
            }

            // Iterate to find the next valid step
            while (currentIndex < steps.length - 1) {
                const nextIndex = currentIndex + 1;
                const nextStep = steps[nextIndex];
                const dependency = this.form_nodes[nextStep]?.dependency;

                if (dependency) {
                    const isDependencyMet = this.isPassDependency(dependency.actionButtons);
                    if (isDependencyMet) {
                        this.onNext(nextStep,isSubmit);
                        return;
                    }
                } else {
                    // No dependency, go to the next step
                    this.onNext(nextStep,isSubmit);
                    return;
                }

                // Skip this step if dependency is not met
                currentIndex++;
            }




            // const next_step = steps[currentIndex + 1];
            // this.onNext(next_step);
        },
        isPassDependency({node,btn_id}){
            const formNode = this.form_nodes[node];
            if (formNode.contents) {
                for(const content of formNode.contents){
                    if (content.type === 'card' && content.actionButtons) {
                        for(const btn of content.actionButtons){
                            if (btn.id === btn_id) {
                                if (btn.visibility) {
                                    return true
                                }else{
                                    return false
                                }
                            }
                        }
                    }
                }
            }
            return true
        },

        hasExtraConfig(item,key){
            if (item.extraConfig && item.extraConfig[key]) {
                return item.extraConfig[key]
            }
            return false;
        },
        updateButtonByIndex(node,contentIndex,btnIndex,value){
            // this.form_nodes[node].contents[contentIndex].actionButtons[btnIndex].title = value;
        },
        onTryChatbotClick(){
            const link = document.createElement("a");
            link.href = this.whatsappUrl;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async copyWhatsappLink() {
            try {
                // Copy to clipboard
                await navigator.clipboard.writeText(this.whatsappUrl);
                this.showAlert(this.$t('COMMUNAL_WP_SETTING.COPY_WP_SHARE_LINK_SUCCESS'));
            } catch (error) {
                console.error('Failed to copy WhatsApp link:', error);
            }
        },
        onSelectInbox(selectedItem){
            // console.log({selectedItem});
        },
        openTutorial(){
            this.showTutorial = true;
        },
        closeTutorial(){
            this.showTutorial = false;
        },
        // end methods
    },
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';


.botExpressModal_container{
    .header{
        position: relative;
        margin-left: 28px;
        .header_title{
            font-weight: 700;
            font-size: 20px;
            color: #1F1F1F;
        }
        .video_icon{
            position: absolute;
            top: 0;
            right: 12px;
        }
    }
}

.expressBot{
    .main_title{
        font-weight: 700;
        font-size: 14px;
        color: #1F1F1F;
    }
    .main_description{
        font-weight: 500;
        font-size: 14px;
        color: #858585;
    }
    .form_label{
        font-weight: 500;
        font-size: 12px;
        color: #1F1F1F;
        margin-top: 12px;

        input{
            font-size: 12px;
        }
        textarea{
            font-size: 12px;
        }

        .textField{
            margin-top: 8px;
            padding: 12px;
            font-weight: 500;
            font-size: 14px;
            color: #1F1F1F;
            border-color: #EEEEEE;
        }
        .textField::placeholder{
            color: #B8B8B8;
        }
    }
    .check_label{
        display: flex;
        align-items: center;
        gap: 4px;
        input{
            margin: 0;
        }
    }
}

.complete_step{
    max-width: 340px;
    margin: auto;
    text-align: center;
    .info{
        margin: 30px auto 24px auto;
    }

    .description{
        font-weight: 500;
        font-size: 14px;
        color: #858585;
    }
    .btns{
        button{
            margin-top: 16px;
        }
    }

}

.step_range {
  margin-top: 24px;
  text-align: center;
  input[type="range"] {
    width: 100%;
  }
}

.expressBot{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    margin-top: 24px;
}

.submit_btns{
    margin-top: 24px;
    .tmp_submit{
        margin-top: 16px;
    }
}
</style>


<style>
/* global css  */
.botExpressModal{
    padding: 40px 32px;
    max-height: fit-content;
    width: 724px;
}

.botExpressModal .step_range input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

.botExpressModal .step_range input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #F2E7FE;
  border-radius: 16px;
}

.botExpressModal .step_range input[type="range"]::-moz-range-track {
  height: 15px;
  background: #F2E7FE;
  border-radius: 16px;
}

.botExpressModal .step_range input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #8D25F6;
  border-radius: 50%;
  border: 2px solid #8D25F6;
  box-shadow: -407px 0 0 400px #8D25F6;
}

.botExpressModal .step_range input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #8D25F6;
  border-radius: 50%;
  border: 1px solid #8D25F6;
  box-shadow: -407px 0 0 400px #8D25F6;
}
.botExpressModal .tmp_submit{
    display: block;
    width: 100%;
}

.express_bot_multiselect .multiselect__tag span,
.express_bot_multiselect .multiselect__input,
.express_bot_multiselect .multiselect__single,
.express_bot_multiselect .multiselect__option span {
    font-size: 12px;
}
</style>