var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "label",
        {
          staticClass: "form_label",
          class: { error: _vm.$v.item.title.$error }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.label.title)) + "\n      "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.title,
                expression: "item.title"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.item.title },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "title", $event.target.value)
                },
                function($event) {
                  return _vm.validateInput({
                    field: "title",
                    value: _vm.item.title
                  })
                }
              ]
            }
          }),
          _vm._v(" "),
          _vm.$v.item.title.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1"
                      )
                    ) +
                    "\n          " +
                    _vm._s(_vm.constraint.title_limit) +
                    "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2"
                      )
                    ) +
                    "\n      "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "form_label",
          class: { error: _vm.$v.item.body.$error }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.label.body)) + "\n      "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.body,
                expression: "item.body"
              }
            ],
            attrs: { name: "", id: "", cols: "20", rows: "6" },
            domProps: { value: _vm.item.body },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "body", $event.target.value)
                },
                function($event) {
                  return _vm.validateInput({
                    field: "body",
                    value: _vm.item.body
                  })
                }
              ]
            }
          }),
          _vm._v(" "),
          _vm.$v.item.body.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1"
                      )
                    ) +
                    "\n          " +
                    _vm._s(_vm.constraint.body_limit) +
                    "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2"
                      )
                    ) +
                    "\n      "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "form_label",
          class: { error: _vm.$v.item.footer.$error }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.label.footer)) + "\n      "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.footer,
                expression: "item.footer"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.item.footer },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "footer", $event.target.value)
                },
                function($event) {
                  return _vm.validateInput({
                    field: "footer",
                    value: _vm.item.footer
                  })
                }
              ]
            }
          }),
          _vm._v(" "),
          _vm.$v.item.footer.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1"
                      )
                    ) +
                    "\n          " +
                    _vm._s(_vm.constraint.footer_limit) +
                    "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2"
                      )
                    ) +
                    "\n      "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "form_label",
          class: { error: _vm.$v.item.button.$error }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.label.button)) + "\n      "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.button,
                expression: "item.button"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.item.button },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "button", $event.target.value)
                },
                function($event) {
                  return _vm.validateInput({
                    field: "button",
                    value: _vm.item.button
                  })
                }
              ]
            }
          }),
          _vm._v(" "),
          _vm.$v.item.button.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1"
                      )
                    ) +
                    "\n          " +
                    _vm._s(_vm.constraint.button_limit) +
                    "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2"
                      )
                    ) +
                    "\n      "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.item.sub_type === "calendar_list" ? void 0 : _vm._e(),
      _vm._v(" "),
      _vm.item.sub_type === "appointment_list" ? void 0 : _vm._e(),
      _vm._v(" "),
      _vm.item.sub_type === "outstanding_appointment"
        ? void 0
        : _vm._l(_vm.item.sections, function(section, sectionIdx) {
            return [
              _c(
                "div",
                { key: "actionBtn" + sectionIdx },
                [
                  _c(
                    "label",
                    {
                      staticClass: "form_label",
                      class: {
                        error:
                          _vm.$v.item.sections.$each[sectionIdx].title.$error
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t(_vm.label.sections[sectionIdx].title)) +
                          "\n              "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: section.title,
                            expression: "section.title"
                          }
                        ],
                        attrs: { type: "text" },
                        domProps: { value: section.title },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(section, "title", $event.target.value)
                            },
                            function($event) {
                              return _vm.validateInput({
                                field: "sections",
                                value: section.title
                              })
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.item.sections.$each[sectionIdx].title.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t(
                                    "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1"
                                  )
                                ) +
                                "\n                  " +
                                _vm._s(_vm.constraint.section_title_limit) +
                                "\n                  " +
                                _vm._s(
                                  _vm.$t(
                                    "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2"
                                  )
                                ) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("multiselect", {
                    staticClass: "column small-12 express_bot_multiselect",
                    attrs: {
                      options: _vm.categoryOptions,
                      "track-by": "id",
                      label: "title",
                      multiple: true,
                      max: _vm.constraint.max_row_length,
                      "close-on-select": false,
                      "clear-on-select": false,
                      "hide-selected": true,
                      placeholder: _vm.$t(
                        "CATALOGUE.TOOLBAR.CATAGORY_PLACEHOLDER"
                      ),
                      "selected-label": "",
                      "select-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_SELECT"
                      ),
                      "deselect-label": _vm.$t(
                        "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                      )
                    },
                    model: {
                      value: section.rows,
                      callback: function($$v) {
                        _vm.$set(section, "rows", $$v)
                      },
                      expression: "section.rows"
                    }
                  })
                ],
                1
              )
            ]
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }