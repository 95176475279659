<template>
    <modal :show.sync="show" :on-close="onClose" :modalMainContainerClass="'schedule_pickup_modal'">
        <div>
            <div>
                <h2 class="modal_title">{{$t('ORDER.LIST_TABLE.PICKUP_MODAL.TITLE')}}</h2>
                <p class="modal_description font_regular text_14 font_500">{{$t('ORDER.LIST_TABLE.PICKUP_MODAL.DESCRIPTION')}}</p>
            </div>
            <div class="date_box">
                <label class="font_regular font_500 text_12">{{$t('ORDER.LIST_TABLE.PICKUP_MODAL.DATE.LABEL')}}</label>
                <div class="date_col">
                    <div class="radio-item">
                        <div>
                            <label class="radio_wrap" for="">
                                <input
                                type="radio"
                                :value="'date'"
                                v-model="selectedOption"
                                />
                            </label>
                        </div>
                    </div>

                    <div>
                        <woot-date-time-picker
                            :value="scheduledDate"
                            :disable-dates="-1"
                            :type="'date'"
                            displayFormat="YYYY-MM-DD"
                            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
                            :placeholder="$t('ORDER.LIST_TABLE.PICKUP_MODAL.DATE.DATE_PLACEHOLDER')"
                            @change="onDateChange"
                        />
                    </div>
                    <div>
                        <woot-date-time-picker
                            :value="scheduledTime"
                            :disable-dates="0"
                            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
                            :placeholder="$t('ORDER.LIST_TABLE.PICKUP_MODAL.DATE.TIME_PLACEHOLDER')"
                            :type="'time'"
                            displayFormat="HH:mm"
                            @change="onTimeChange"
                        />
                    </div>
                </div>
                <div class="time_col">
                    <div class="radio-item">
                        <div>
                            <label class="radio_wrap" for="">
                                <input
                                type="radio"
                                style="margin:0;"
                                :value="'now'"
                                v-model="selectedOption"
                                />
                            </label>
                        </div>
                    </div>
                    <span class="font_regular font_500 text_14">{{$t('ORDER.LIST_TABLE.PICKUP_MODAL.PICKUP_LABEL')}}</span>
                </div>
            </div>
            <div>
                <woot-button
                class="pickup_btn"
                :is-loading="false"
                :is-disabled="false"
                @click="updateSchedulePickup"
                >{{ $t('ORDER.LIST_TABLE.PICKUP_MODAL.SUBMIT_BTN') }}</woot-button>
            </div>
        </div>

    </modal>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import moment from 'moment';
import deliveryAPI from "dashboard/api/delivery_services.js"
import alertMixin from 'shared/mixins/alertMixin';

export default {
    components: {
        Modal,
        WootDateTimePicker,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        order:{
            default: {}
        }
    },
    mixins: [alertMixin],
    data(){
        return {
            selectedOption: null,
            scheduledDate: null,
            scheduledTime: null,
        }
    },
    methods:{
        onDateChange(value) {
            this.scheduledDate = value;
        },
        onTimeChange(value) {
            this.scheduledTime = value;
        },
        async updateSchedulePickup(){
            try {
                if (!this.selectedOption) {
                    this.showAlert(this.$t('ORDER.LIST_TABLE.PICKUP_MODAL.OPTION_ERR'));
                    return
                }
                let pickup_scheduled_at = null;
                if (this.selectedOption ==='now') {
                    pickup_scheduled_at = moment().format('YYYY-MM-DD HH:mm:ss')
                }else{
                    const scheduleDate = new Date(this.scheduledDate);
                    const scheduleTime = new Date(this.scheduledTime);

                    const date = scheduleDate.toLocaleDateString('fr-CA',{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    });
                    const time = scheduleTime.toLocaleTimeString('en-GB',{
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false
                    });
                    pickup_scheduled_at = `${date} ${time}`;
                }

                console.log({pickup_scheduled_at, order:this.order});
                const {data} = await deliveryAPI.updateDeliveryScheduleTime(this.order.delivery_service?.id,{pickup_scheduled_at})

                // stop updating from here, action cable will do that
                // this.$store.dispatch('orders/updateOrderLocalStatus', {
                //     order_id: this.order.id,
                //     order_status: "packed"
                // });
                this.$nextTick(() => {
                    this.$forceUpdate();
                });

                // this.showAlert(this.$t('ORDER.LIST_TABLE.PICKUP_MODAL.PICKUP_SCHEDULE_SUCCESS'));
                this.onClose()
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('ORDER.LIST_TABLE.PICKUP_MODAL.PICKUP_SCHEDULE_ERR'));
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            }
        },



        // end methods
    },

}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.modal_title{
    font-size: 20px;
}
.modal_description{
    margin-top: 24px;
}
.date_box{
    margin-top: 24px;

    .date_col{
        display: grid;
        grid-template-columns: 27px 1fr 1fr;
        align-items: center;
        margin-top: 8px;
    }
    .time_col{
        display: grid;
        grid-template-columns: 27px 1fr;
        span{
            margin-top: 2px;
        }
    }
}

// radio design start
.radio-item {

    .radio_wrap{
        margin-top: 2px;
    }

    label {
        display: flex;
        align-items: center;
    }

    input {
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid var(--w-500);
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        position: relative;
    }
}

.radio-item input:checked {
  background-color: white;
}


.radio-item input:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  // background: var(--w-500);
  background: #8D25F6;
  transform: translate(-50%, -50%);
}
// radio design end

.pickup_btn{
    width: 100%;
    margin-top: 32px;
}
</style>

<style>
.schedule_pickup_modal{
    max-width: 440px;
    padding: 32px;
}
.schedule_pickup_modal .modal--close svg {
    color: #858585;
}
.schedule_pickup_modal .date_col .date-picker input::placeholder{
    color: #B8B8B8;
}
</style>

