/* global axios */

import ApiClient from '../ApiClient';

class CategoryApi extends ApiClient {
    constructor() {
        super('categories', { accountScoped: true });
    }

    //   get account's default catalog
    getCategoryGroupList() {
        return axios.get(`${this.url}/lists`);
    }

}

export default new CategoryApi();

