var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.campaign_type === "one_off" && _vm.eligible_audience.isFetched
        ? _c("article", { staticClass: "audience_container" }, [
            _c("div", [
              _c("p", { staticClass: "font_regular font_500  text_14" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.ELIGIBLE_AUDIENCE")
                    ) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-top": "4px" } }, [
                _c(
                  "span",
                  { staticClass: "font_500 text_16 text_black_primary" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.eligible_audience.audience) +
                        "\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "font_500 text_12 text_black_secondary" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.eligible_audience.costs) +
                        "\n                    " +
                        _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.CREDIT")) +
                        "\n                "
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "font_regular font_500  text_14" }, [
                _vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.MAX_AUDIENCE")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "topup_box" }, [
                _vm.isBroadcasetLimitOpen
                  ? _c("div", { staticClass: "topup" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.broadcast_limit,
                            expression: "broadcast_limit"
                          }
                        ],
                        staticClass: "font_regular font_500 text_14 ",
                        attrs: {
                          type: "number",
                          min: 0,
                          max: _vm.eligible_audience.max_audience
                        },
                        domProps: { value: _vm.broadcast_limit },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.broadcast_limit = $event.target.value
                            },
                            _vm.validateBroadcastLimitInput
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "font_regular font_500 text_14 text_black_secondary"
                        },
                        [
                          _vm._v(
                            "\n                            /" +
                              _vm._s(_vm.eligible_audience.max_audience) +
                              "\n                        "
                          )
                        ]
                      )
                    ])
                  : _c(
                      "span",
                      { staticClass: "font_regular font_500  text_16" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.eligible_audience.max_audience) +
                            "\n                "
                        )
                      ]
                    ),
                _vm._v(" "),
                _c("div", { staticClass: "available_credit_box" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "available_credit font_regular font_500  text_12"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.AVAILABLE_CREDIT")
                        ) +
                          "\n                    " +
                          _vm._s(_vm.eligible_audience.available_credit) +
                          "\n                    "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "audience_note font_regular font_500  text_14" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.AUDIENCE_NOTE"))
                  )
                ]
              ),
              _vm._v(" "),
              _vm.eligible_audience.isAllowModify
                ? _c(
                    "div",
                    {
                      staticClass: "campaign_btns",
                      staticStyle: { margin: "0", "margin-top": "16px" }
                    },
                    [
                      _c(
                        "woot-button",
                        {
                          staticClass: "campaign_btn",
                          attrs: { icon: "add" },
                          on: { click: _vm.openTopup }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.BTNS.TOPUP")) +
                              "\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "woot-button",
                        {
                          staticClass: "campaign_btn",
                          attrs: { variant: "clear" },
                          on: { click: _vm.onReduceAudience }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("CAMPAIGN.ADD_STEP.BTNS.REDUCE_AUDIENCE")
                              ) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        : _vm.campaign_type === "running" && _vm.eligible_audience.isFetched
        ? _c("article", { staticClass: "audience_container" }, [
            _c("div", [
              _c("p", { staticClass: "font_regular font_500  text_14" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.ELIGIBLE_AUDIENCE")
                    ) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "credit_purple_box" }, [
                _c("div", [
                  _c(
                    "span",
                    { staticClass: "font_500 text_16 text_black_primary" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.eligible_audience.audience) +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "font_500 text_12 text_black_secondary" },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.eligible_audience.costs) +
                          "\n                        " +
                          _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.CREDIT")) +
                          "\n                    "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "available_credit_box" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "available_credit available_credit_purple font_regular font_500  text_12"
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.AVAILABLE_CREDIT")
                          ) +
                          "\n                        " +
                          _vm._s(_vm.eligible_audience.available_credit) +
                          "\n                    "
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("StripeComponent", {
        attrs: { "modal-value": _vm.isTopupModal },
        on: { "close-modal": _vm.closeTopup }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }