<template>
  <div class="column content-box onboard_container">
    <section class="row">
      <div class="column content-box">

        <!-- whatsapp verification -->
        <section class="business_container verify_container" v-if="!currentUser.confirmed">
          <div class="banner_heading">
              <h3 class="page-title">{{$t('ARRANGE.VERIFY.TITLE')}}</h3>
          </div>
          <div>
            <p style="margin:0;">{{$t('ARRANGE.VERIFY.MSG_PART_1')}} {{currentUser.user_phone_number}}</p>
            <p> {{$t('ARRANGE.VERIFY.MSG_PART_2')}}</p>
            <woot-button class="wp_btn" @click="handleOpenWhatsApp">{{$t('ARRANGE.VERIFY.WHATSAPP_BTN')}}</woot-button>
            <div class="resend_box">
              <p style="margin:0;">{{$t('ARRANGE.VERIFY.RESEND_TEXT')}}</p>
              <woot-button
              class="resend_btn"
              :classNames="isResendEnabled ? '' : 'btn_mute'"
              :disabled="!isResendEnabled || isReSending"
              @click="handleResendLink"
              >
              {{ isResendEnabled ? $t('ARRANGE.VERIFY.RESEND_BTN') : `${$t('ARRANGE.VERIFY.RESEND_BTN')} (${countdown}s)` }}
              </woot-button>
            </div>
            <div>
              <div class="code_container">
                <input
                  v-for="(code,index) in codes"
                  ref="codeInputs"
                  v-model="codes[index]"
                  type="number"
                  maxlength="1"
                  :key="index"
                  @input="moveToNext(index)"
                  @keydown.delete="moveToPrev(index,$event)"
                >
              </div>

              <woot-button
              classNames="verify_btn"
              :isDisabled="!isCodeComplete || isCodeLoading"
              :isLoading="isCodeLoading"
              @click="onVerifyCode"
              >
                {{$t('ARRANGE.VERIFY.VERIFY_BTN')}}
              </woot-button>
            </div>
          </div>
        </section>

        <!-- redesign the process  -->
        <div v-else>
          <section v-if="showOrderForm" class="business_service">
            <h2 class="title">{{$t('ARRANGE.BUSINESS_TYPE.ORDER_LEVEL')}}</h2>
            <div class="services">
              <woot-input
                v-model.trim="average_order"
                type="number"
                :class="{ error: $v.average_order.$error }"
                :label="''"
                :placeholder="'500'"
                :error="
                  $v.average_order.$error
                    ? $t('ARRANGE.BUSINESS_TYPE.ORDER_ERR')
                    : ''
                "
                @blur="$v.average_order.$touch"
              />
            </div>
            <div class="btn_wrap">
              <woot-button
                classNames="submit_business"
                color-scheme="primary"
                :is-loading="isLoading"
                :is-disabled="isLoading"
                @click="onBusinessSubmit"
              >
                {{$t('ARRANGE.BUSINESS_TYPE.BTN')}}
              </woot-button>
            </div>
          </section>
          <section v-else class="business_service">
            <h2 class="title">{{$t('ARRANGE.BUSINESS_SERVICES.TITLE')}}</h2>
            <ul class="services">
              <li v-for="item in businessServices" class="service" :class="active_service && active_service.id === item.id ?'active_service':''" :key="item.id" @click="onBusinessClick(item)">
                <div class="icon">
                  <div>
                    <fluent-icon :icon="item.icon" :viewBox="item.viewBox" />
                  </div>
                </div>
                <div class="info">
                  <h4>{{$t(item.name)}}</h4>
                  <p v-if="active_service && active_service.id === item.id">{{$t(item.description)}}</p>
                </div>
              </li>
            </ul>
            <div class="btn_wrap">
              <woot-button
                classNames="submit_business"
                :isDisabled="!active_service"
                @click="onServiceNext"
              >{{$t('ARRANGE.BUSINESS_SERVICES.BTNS.NEXT')}}</woot-button>
            </div>
          </section>
        </div>


      </div>
    </section>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import {businessTypes,services,businessServices} from "./constant"
import RadioList from './RadioList.vue';
import alertMixin from 'shared/mixins/alertMixin';
import AccountAPI from "../../../api/account"
import AuthAPI from "../../../api/auth"
import { mapGetters } from 'vuex';
import {getParamsFromUrl, frontendURL} from "../../../helper/URLHelper"
import {SIGNUP_FLOW} from "../../../helper/demoAutomationHelper"
import endPoints from '../../../api/endPoints';
import Button from '../../../components/buttons/Button.vue';
import WootButton from '../../../components/ui/WootButton.vue';

export default {
  components: {
    RadioList,
    WootButton,
  },
  mixins: [alertMixin],
  data() {
    return {
      businessServices,
      active_service: null,
      showOrderForm:false,
      isLoading: false,
      average_order: 0,
      countdown: 600, // 10 minutes countdown(600s)
      isResendEnabled: false,
      isReSending: false,
      codes:["","","","","",""],
      isCodeLoading:false,

    };
  },
  computed:{
    ...mapGetters({
      currentUser: 'getCurrentUser',
      userPlan: 'accounts/getUserPlan',
      getCurrentAccount: 'accounts/getAccount',
    }),
    isCodeComplete(){
      return this.codes.every(digit=>digit!=="")
    },
  },
  mounted() {
    this.startCountdown();
    // Do i need to remove this event ?
    bus.$on('WhatsAppVerificationBusEvent', async(data) => {

      if (data?.confirmed) {
        // if already have sector, then go to home
        const newUser = await this.$store.dispatch("fetchCurrentUserProfile");
        console.log({newUser});
        await this.$nextTick()
        if(newUser.sector){
          window.location = `/app/accounts/${this.currentUser.account_id}/target-dashboard/goal`
          return
        }
        window.location.reload()
      }
    });
    this.updateQueryParamsIfNeeded();
  },
  watch: {
    countdown(newCountdown) {
      if (newCountdown <= 0) {
        this.isResendEnabled = true;
      }
    },
  },
  validations: {
    average_order: {
      required,
      isNumeric(value){
        return /^[0-9]+$/.test(value);
      }
    },
  },
  methods: {
    async onBusinessSubmit(){
      const query = getParamsFromUrl();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }


      try {
        this.isLoading = true;

        const payload = {
          sector: this.active_service.sector,
          average_order:  parseInt(this.average_order),
        }

        const {data:attrRes} = await AccountAPI.setCustomAttributeToAdminContact(payload);

        await this.$store.dispatch('accounts/get'); // get updated account
        // call updated profile to know latest plan_id
        const newUser = await this.$store.dispatch("fetchCurrentUserProfile");
        await this.$nextTick()
        const nextsignupflow = {
          account_id:this.currentUser.account_id,
            isNewSignup:false, // no need to track now
            current_step: SIGNUP_FLOW.FLOWS.select_plan
          }
        SIGNUP_FLOW.setFlow(nextsignupflow)

        if (newUser.plan_id === 16) {
          window.location = frontendURL(endPoints('subscriptionPlans').url(this.currentUser.account_id),query)
        } else {
          this.$router.push({
            path: `/app/accounts/${newUser.account_id}/target-dashboard/goal`,
            query,
          })
        }
        // window.location = frontendURL(endPoints('subscriptionPlans').url(this.currentUser.account_id),query)

        this.showAlert(this.$t('ARRANGE.BUSINESS_TYPE.SUBMIT_SUCCESS_MSG'));
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('ARRANGE.BUSINESS_TYPE.SUBMIT_FAIL_MSG'));
      } finally{
        this.isLoading = false;
      }

    },
    onBusinessClick(item){
      this.active_service = item;
    },
    onServiceNext(){
      this.showOrderForm = true;
    },
    handleOpenWhatsApp() {
      const message = this.$t('ARRANGE.VERIFY.WHATSAPP_RESEND_SUCCESS_MSG');
      const phone = this.currentUser.phone_number;
      const url = `whatsapp://send?text=${encodeURIComponent(message)}&phone=${encodeURIComponent(phone)}`;
      window.location.href = url;  // This will trigger WhatsApp to open
    },
    async handleResendLink() {
      if (!this.isResendEnabled || this.isReSending) return;

      this.isReSending = true;
      try {
        await AccountAPI.resendVerificationCode();
        await this.startCountdown();
        this.isResendEnabled = false;
        this.showAlert(this.$t('ARRANGE.VERIFY.RESEND_SUCCESS'));
      } catch (error) {
        console.error(error);
        this.showAlert(error.message || this.$t('ARRANGE.VERIFY.FAIL_SERVER_ERR'));
      } finally {
        this.isReSending = false;
      }
    },
    async startCountdown() {

      await this.calculateRemainingTime();
      await this.$nextTick()
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(interval);  // Stop the countdown when it reaches 0
        }
      }, 1000);
    },
    updateQueryParamsIfNeeded() {
    const query = { ...this.$route.query };
    const signup_flow = SIGNUP_FLOW.getFlow();
    if (signup_flow?.buy_now_plan_id && signup_flow?.isNewSignup && !query.buy_now_plan_id) {
      query.buy_now_plan_id = signup_flow.buy_now_plan_id;
      this.$router.push({ path: this.$route.path, query });
    }
    },
    moveToNext(index){
      if (this.codes[index] && index < 5) {
        this.$refs.codeInputs[index+1].focus();
      }
    },
    moveToPrev(index, event){
      if (event.key === "Backspace" && !this.codes[index] && index > 0) {
        this.$refs.codeInputs[index-1].focus();
      }
    },
    async onVerifyCode(){
      const code = this.codes.join("");
      try {
        this.isCodeLoading = true;
        const data = await AuthAPI.verifyOTP({otp_token:code});
        const newUser = await this.$store.dispatch("fetchCurrentUserProfile");
        console.log({newUser});
        await this.$nextTick()
        console.log(this.currentUser,"curentuser");
        this.showAlert(this.$t('ARRANGE.VERIFY.SUCCESS_MSG'));
        if (this.currentUser.sector) {
          // call to update profile
          window.location = `/app/accounts/${this.currentUser.account_id}/target-dashboard/goal`
          return
        }
        window.location.reload()
        this.isCodeLoading = false;
      } catch (error) {
        console.log(error);
        this.codes = ["","","","","",""];
        this.showAlert(this.$t('ARRANGE.VERIFY.FAIL_MSG') + this.currentUser.user_phone_number);
        this.isCodeLoading = false;
      }

    },
    async calculateRemainingTime(confirmationSentAt) {
      const data = await this.$store.dispatch("fetchCurrentUserProfile");
      if (!data?.confirmation_sent_at) {
        return
      }

      const sentTime = new Date(data.confirmation_sent_at).getTime();
      const currentTime = new Date().getTime();
      const elapsedSeconds = Math.floor((currentTime - sentTime) / 1000);

      const remainingTime = 600 - elapsedSeconds;
      if (remainingTime > 0) {
        this.countdown = remainingTime;
        // this.isResendEnabled = false;
        // this.startCountdown();
      } else {
        this.countdown = 0;
        // this.isResendEnabled = true;
      }
    },
    // end method
  },
}
</script>

<style scoped lang="scss">
.verify_container{
  max-width: 355px;
  .wp_btn{
    font-weight: 700;
    color: var(--w-500);
    background: transparent;
    margin: 25px 0;
  }
  .resend_box{
    display: flex;
    justify-items: center;
    align-items: center;

    .resend_btn{
      background: transparent;
      color: var(--color-body);
    }
    .btn_mute {
      color: #000;
    }
  }
}
.business_container{
  min-height: 100vh;
  width: fit-content;
  margin: auto;

  .sub_title{
    font-weight: 600;
    margin-top: 16px;
  }
  .btn_wrap{
    margin: 20px auto;
  }
}

.business_service{
  max-width: fit-content;
  margin: auto;
  font-family: 'Figtree';
  .title{
    font-family: 'Figtree';
    font-size: 24px;
    font-weight: 700;
    color: #1F1F1F;
  }
  .services{
    list-style: none;
    margin: 24px auto 0 auto;
    width: 500px;
    .service{
      display: grid;
      grid-template-columns: 22px 1fr;
      gap: 8px;
      padding: 12px 16px;
      margin-top: 12px;
      border: 1px solid #EEEEEE;
      color: #8D25F6;
      cursor: pointer;
      .icon{
        width: 20px;
        height: 20px;
      }
      .info{
        h4{
          font-family: 'Figtree';
          font-weight: 600;
          font-size: 16px;
          color: #8D25F6;
        }
        p{
          font-weight: 500;
          font-size: 14px;
          margin-top: 4px;
          color: #1F1F1F;
        }
      }
    }
    .active_service{
      border-radius: 6px;
      background-color: #F2E7FE;
      border-color: #8D25F6;
    }
  }
  .btn_wrap{
    width: fit-content;
    margin: 40px auto;
  }
}

.code_container{
  display: flex;
  gap: 12px;
  input{
    // width: 40px;
    text-align: center;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
.verify_btn{
  width: 100%;
}
.submit_business{
  min-width: 300px;
}
</style>