/* eslint no-param-reassign: 0 */
import axios from 'axios';
import Vue from 'vue';
import * as types from '../mutation-types';
import authAPI from '../../api/auth';
import createAxios from '../../helper/APIHelper';
import actionCable from '../../helper/actionCable';
import { setUser, getHeaderExpiry, clearCookiesOnLogout } from '../utils/api';
import { DEFAULT_REDIRECT_URL } from '../../constants';
import { popupHelpers } from '../../helper/demoAutomationHelper';
import {updateCookiesUser} from "../../store/utils/api"

const state = {
  currentUser: {
    id: null,
    account_id: null,
    channel: null,
    email: null,
    name: null,
    provider: null,
    uid: null,
    subscription: {
      state: null,
      expiry: null,
    },
  },
  currentAccountId: null,
  profileFlags:{
    isFetching: false,
  }
};

// getters
export const getters = {
  isLoggedIn($state) {
    return !!$state.currentUser.id;
  },

  getCurrentUserID(_state) {
    return _state.currentUser.id;
  },

  getUISettings(_state) {
    return _state.currentUser.ui_settings || {};
  },

  getCurrentUserAvailability(_state) {
    const { accounts = [] } = _state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === _state.currentAccountId
    );
    return currentAccount.availability;
  },

  getCurrentAccountId(_, __, rootState) {
    if (rootState.route.params && rootState.route.params.accountId) {
      return Number(rootState.route.params.accountId);
    }
    return null;
  },

  getCurrentRole(_state) {
    const { accounts = [] } = _state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === _state.currentAccountId
    );
    return currentAccount.role;
  },

  getCurrentUser(_state) {
    return _state.currentUser;
  },

  ifAppsumoUser(_state) {
    return _state.currentUser?.appsumo;
  },


  getMessageSignature(_state) {
    const { message_signature: messageSignature } = _state.currentUser;

    return messageSignature || '';
  },

  getCurrentAccount(_state) {
    const { accounts = [] } = _state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === _state.currentAccountId
    );
    return currentAccount || {};
  },

  getUserAccounts(_state) {
    const { accounts = [] } = _state.currentUser;
    return accounts;
  },
};

// actions
export const actions = {
  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      authAPI
        .login(credentials)
        .then(async () => {
          commit(types.default.SET_CURRENT_USER);
          window.axios = createAxios(axios);
          actionCable.init(Vue);

          const user = await authAPI.getCurrentUser();
          if (!user) {
            reject({
              isCookieSetError: true,
              message: "Failed to keep login credentials",
            });
            return
          }
          // update the user.customAttribute => contact.customAttribute
          console.log({user,ctm:'CTM'});
          if (user.custom_attributes) {
            await popupHelpers.saveAtPeasySdkContactAttributes(user.email,user.name,{
              ...user.custom_attributes,
              step:user.step,
              onboard_limit: user.onboard_limit,
            });
          }

          const {isInstructPopupOpen,url: popupRedirectUrl} = await popupHelpers.navigateToPage();
          // console.log({isInstructPopupOpen,popupRedirectUrl});
          localStorage.setItem("recentLoginContestEvent","true")


          const appCuePayload = {
            access_token: user.access_token,
            account_id: user.account_id,
            step: user.step,
            trial: user.trial,
            payment: user.payment,
            source: user.source,
            account_message_limit: user.account_message_limit,
            company_description: user.company_description,
            company_name: user.company_name,
            phone_number: user.phone_number,
            account_top_amount: user.account_top_amount,
            total_top_amount: user.total_top_amount,
            low_credit_warning: user.low_credit_warning,
            stripe_customer_id: user.stripe_customer_id,
            plan_id: user.plan_id,
            sector: user.sector,
            chat: user.chat,
            order: user.order,
            current_gmv: user.current_gmv,
            appsumo: user.appsumo,
            end_date: user.end_date,
            available_name: user.available_name,
            avatar_url: user.avatar_url,
            default_currency: user.default_currency,
            confirmed: user.confirmed,
            user_phone_number: user.user_phone_number,
            email: user.email,
            id: user.id,
            name: user.name,
            provider: user.provider,
            pubsub_token: user.pubsub_token,
            role: user.role,
            pm_account_id: user.pm_account_id,
            sst_rate: user.sst_rate,
            catalog_items_count: user.catalog_items_count,
            completed_orders_count: user.completed_orders_count,
            cancelled_orders_count: user.cancelled_orders_count,
            pending_orders_count: user.pending_orders_count,
            total_orders_value: user.total_orders_value,
            chatbots_count: user.chatbots_count,
            automations_count: user.automations_count,
            broadcast_count: user.broadcast_count,
            contacts_count: user.contacts_count,
            inboxes_count: user.inboxes_count,
            avg_monthly_order_volume: user.avg_monthly_order_volume,
            appointment_links_count: user.appointment_links_count,
            appointments_count: user.appointments_count,
            conversations_count: user.conversations_count,
            level: user.level,
            level_name: user.level_name,
          }
          const appMixpanelPayload = {
            account_id: user.account_id,
            trial: user.trial,
            payment: user.payment,
            source: user.source,
            account_message_limit: user.account_message_limit,
            company_name: user.company_name,
            phone_number: user.phone_number,
            account_top_amount: user.account_top_amount,
            total_top_amount: user.total_top_amount,
            low_credit_warning: user.low_credit_warning,
            stripe_customer_id: user.stripe_customer_id,
            plan_id: user.plan_id,
            sector: user.sector,
            current_gmv: user.current_gmv,
            default_currency: user.default_currency,
            confirmed: user.confirmed,
            email: user.email,
            id: user.id,
            name: user.name,
            pubsub_token: user.pubsub_token,
            pm_account_id: user.pm_account_id,
            sst_rate: user.sst_rate,
            catalog_items_count: user.catalog_items_count,
            completed_orders_count: user.completed_orders_count,
            cancelled_orders_count: user.cancelled_orders_count,
            pending_orders_count: user.pending_orders_count,
            total_orders_value: user.total_orders_value,
            chatbots_count: user.chatbots_count,
            automations_count: user.automations_count,
            broadcast_count: user.broadcast_count,
            contacts_count: user.contacts_count,
            inboxes_count: user.inboxes_count,
            avg_monthly_order_volume: user.avg_monthly_order_volume,
            appointment_links_count: user.appointment_links_count,
            appointments_count: user.appointments_count,
            conversations_count: user.conversations_count,
            level: user.level,
            level_name: user.level_name,
          }
          // separate trycatch to handle error
          try {
            // console.log(process.env,"PROCES");

            // inform Appcues
            if (window.Appcues) {
              window.Appcues.identify(
                user.account_id,
                {
                  ...appCuePayload
                }
              );
            }
            // inform mixpanel
            if (window.mixpanel) {
              window.mixpanel.identify(user.account_id);
              window.mixpanel.people.set({
                ...appMixpanelPayload
              });
            }
          } catch (error) {
            console.log(error);
          }


          if (credentials.buy_now_plan_id !== '') {
            let externalUrl =
              DEFAULT_REDIRECT_URL +
              `accounts/${user.account_id}/settings/subscription-plans?buy_now_plan_id=${credentials.buy_now_plan_id}`;
            if (credentials.fpr !== '') {
              externalUrl = externalUrl + '&fpr=' + credentials.fpr;
            }

            window.location = externalUrl;
          } else if (credentials.fpr !== '') {
            let externalUrl = DEFAULT_REDIRECT_URL;
            externalUrl = externalUrl + '?fpr=' + credentials.fpr;
            window.location = externalUrl;
          }
          else if(isInstructPopupOpen){
            window.location = popupRedirectUrl;
          } else {
            // window.location = DEFAULT_REDIRECT_URL;
            window.location = `${DEFAULT_REDIRECT_URL}accounts/${user.account_id}/target-dashboard/goal`;
          }

          // window.location = DEFAULT_REDIRECT_URL;

          resolve();
        })
        .catch(error => {
          console.log('error', error);
          reject(error);
        });
    });

  },
  async validityCheck(context) {
    try {
      const response = await authAPI.validityCheck();
      setUser(response.data.payload.data, getHeaderExpiry(response), {
        setUserInSDK: true,
      });
      context.commit(types.default.SET_CURRENT_USER);
    } catch (error) {
      if (error?.response?.status === 401) {
        clearCookiesOnLogout();
      }
    }
  },
  setUser({ commit, dispatch }) {
    if (authAPI.isLoggedIn()) {
      commit(types.default.SET_CURRENT_USER);
      dispatch('validityCheck');
    } else {
      commit(types.default.CLEAR_USER);
    }
  },
  logout({ commit }) {
    commit(types.default.CLEAR_USER);
  },

  updateProfile: async ({ commit }, params) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await authAPI.profileUpdate(params);
      setUser(response.data, getHeaderExpiry(response));
      commit(types.default.SET_CURRENT_USER);
    } catch (error) {
      throw error;
    }
  },

  deleteAvatar: async ({ commit }) => {
    try {
      await authAPI.deleteAvatar();
      commit(types.default.SET_CURRENT_USER);
    } catch (error) {
      // Ignore error
    }
  },

  updateUISettings: async ({ commit }, params) => {
    try {
      commit(types.default.SET_CURRENT_USER_UI_SETTINGS, params);
      const response = await authAPI.updateUISettings(params);
      setUser(response.data, getHeaderExpiry(response));
      commit(types.default.SET_CURRENT_USER);
    } catch (error) {
      // Ignore error
    }
  },

  updateAvailability: async ({ commit, dispatch }, params) => {
    try {
      const response = await authAPI.updateAvailability(params);
      const userData = response.data;
      const { id } = userData;
      setUser(userData, getHeaderExpiry(response));
      commit(types.default.SET_CURRENT_USER);
      dispatch('agents/updatePresence', { [id]: params.availability });
    } catch (error) {
      // Ignore error
    }
  },

  setCurrentAccountId({ commit }, accountId) {
    commit(types.default.SET_CURRENT_ACCOUNT_ID, accountId);
  },

  setCurrentUserAvailability({ commit, state: $state }, data) {
    if (data[$state.currentUser.id]) {
      commit(
        types.default.SET_CURRENT_USER_AVAILABILITY,
        data[$state.currentUser.id]
      );
    }
  },
  async fetchCurrentUserProfile({ commit }){
        try {
          commit(types.default.SET_CURRENT_USER_PROFILE_FLAG,{isFetching: true});
          const response = await authAPI.getCurrentProfile();
          // update cookies for the new user info
          updateCookiesUser({
            plan_id: response.data.plan_id,
            confirmed: response.data.confirmed,
            sector: response.data.sector,
          })
          console.log("new Currentuser",response.data);
          commit(types.default.SET_CURRENT_USER_PROFILE,response.data);
          commit(types.default.SET_CURRENT_USER_PROFILE_FLAG,{isFetching: false});
          console.log("retrun st");
          return response.data;
        } catch (error) {
          console.log(error);
          commit(types.default.SET_CURRENT_USER_PROFILE_FLAG,{isFetching: false});
        }
  },

};

// mutations
export const mutations = {
   // mutate UI flags
  [types.default.SET_CURRENT_USER_PROFILE_FLAG](_state, data) {
    _state.profileFlags = {
      ..._state.profileFlags,
      ...data,
    };
  },
  [types.default.SET_CURRENT_USER_PROFILE](_state, data) {
    _state.currentUser = data;
  },
  [types.default.SET_CURRENT_USER_AVAILABILITY](_state, availability) {
    Vue.set(_state.currentUser, 'availability', availability);
  },
  [types.default.CLEAR_USER](_state) {
    _state.currentUser.id = null;
  },
  [types.default.SET_CURRENT_USER](_state) {
    const currentUser = {
      ...authAPI.getAuthData(),
      ...authAPI.getCurrentUser(),
    };

    Vue.set(_state, 'currentUser', currentUser);
  },
  [types.default.SET_CURRENT_USER_UI_SETTINGS](_state, { uiSettings }) {
    Vue.set(_state, 'currentUser', {
      ..._state.currentUser,
      ui_settings: {
        ..._state.currentUser.ui_settings,
        ...uiSettings,
      },
    });
  },
  [types.default.SET_CURRENT_ACCOUNT_ID](_state, accountId) {
    Vue.set(_state, 'currentAccountId', Number(accountId));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
