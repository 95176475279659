<template>
    <div v-if="isOnlyQR" class="qr_code_card">
        <div class="qr_code">
            <img :src="generateQRCode({text:shop_url,sizeMultiplier:5})" alt="QR Code">
        </div>
    </div>
    <div v-else class="columns small-12 medium-12 qr_code_card">
        <h2 class="page-title">👋{{$t('CATALOGUE.SETTING.QR_CODE.TITLE')}}</h2>
        <p class="shop_description">{{$t('CATALOGUE.SETTING.QR_CODE.CONTENT')}}</p>
        <!-- {{$t('CATALOGUE.SETTING.FIELDS.QR_CODE_LABLE')}} -->
        <!-- <div>
            <a :href="shop_url" target="_blank">{{ shop_url }}</a>
        </div> -->
        <div class="qr_code_box">
            <div class="qr_code">
                <img :src="generateQRCode({text:shop_url,sizeMultiplier:5})" alt="QR Code">
            </div>
            <div>
                <p class="qr_info">{{$t('CATALOGUE.SETTING.QR_CODE.INFO_CONTENT')}}</p>
                <div class="shop_btn">
                    <woot-button color-scheme="primary">
                        <a :href="shop_url" target="_blank">{{$t('CATALOGUE.SETTING.QR_CODE.BTN')}}</a>
                    </woot-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import methodHelperMixin from '../../../../mixins/methodHelperMixin';

export default {
    mixins:[methodHelperMixin,],
    props:{
        shop_url:{
            type: String,
            default: ""
        },
        isOnlyQR:{
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            qrCodeUrl: ''
        };
    },
    watch:{
        shop_url:{
            immediate: true,
            handler(newShopUrl){
                console.log({newShopUrl});
                this.qrCodeUrl = this.generateQRCode({text:this.newShopUrl,sizeMultiplier:10})
            },
        },
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.qr_code_card{
    text-align: center;
    margin-bottom: 4rem;
    .qr_code{
        width: fit-content;
        margin: auto;
    }
    .shop_btn a{
        color: #fff;
    }

}
</style>