var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "label",
      { staticClass: "form_label", class: { error: _vm.$v.item.title.$error } },
      [
        _vm._v(
          "\n        " + _vm._s(_vm.$t(_vm.label.title)) + "\n            "
        ),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.title,
              expression: "item.title"
            }
          ],
          attrs: { name: "", id: "", cols: "20", rows: "6" },
          domProps: { value: _vm.item.title },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "title", $event.target.value)
              },
              function($event) {
                return _vm.validateInput({
                  field: "title",
                  value: _vm.item.title
                })
              }
            ]
          }
        }),
        _vm._v(" "),
        _vm.$v.item.title.$error
          ? _c("span", { staticClass: "message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$t(
                      "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1"
                    )
                  ) +
                  "\n            " +
                  _vm._s(_vm.constraint.title_limit) +
                  "\n            " +
                  _vm._s(
                    _vm.$t(
                      "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2"
                    )
                  ) +
                  "\n        "
              )
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }