export const ORDER_STATUSES = [
    { id: 'fresh', title: 'Fresh', status_code: 0 },
    { id: 'pending', title: 'Pending', status_code: 1 },
    { id: 'cancelled', title: 'Cancelled', status_code: 2 },
    { id: 'packed', title: 'Packed', status_code: 3 },
    { id: 'out_for_delivery', title: 'Out for Delivery', status_code: 4 },
    { id: 'refunded', title: 'Refunded', status_code: 5 },
    { id: 'completed', title: 'Completed', status_code: 6 },
    { id: 'paid', title: 'Paid', status_code: 7 },
    { id: 'collected', title: 'Collected', status_code: 8 },
]
export const CURRENCY_LIST = [
    { id: 'MYR', title: 'MYR', },
]