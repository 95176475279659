import OrderAPI from "../../api/orders";
import types from "../mutation-types";
import CatalogAPI from "../../api/catalogue/catalog";
import ContactApi from '../../api/contacts'

export const state = {
    orders: [],
    paginatedOrders: [],
    meta: {
        count: 0,
        currentPage: 1,
        pageSize: 15
    },
    uiFlags: {
        isFetching: false,
        isCreating: false,
        isDeleting: false,
        isUpdating: false,
    },
    // sales order reports
    sales_reports:[],
    paginated_sales_reports:[],
    sales_meta: {
        count: 0,
        currentPage: 1,
        pageSize: 15
    },

    // product order reports
    product_reports:[],
    paginated_product_reports:[],
    product_meta: {
        count: 0,
        currentPage: 1,
        pageSize: 15
    },
};

// list of getters
const getters = {
    getUIFlags(_state){
        return _state.uiFlags;
    },
    getMeta(_state){
        return _state.meta;
    },
    getPaginatedOrders(_state){
        return _state.paginatedOrders;
    },
    // sales reports
    getReportsSalesMeta(_state){
        return _state.sales_meta;
    },
    getSalesReports(_state){
        return _state.sales_reports;
    },
    getPaginatedSalesReports(_state){
        return _state.paginated_sales_reports;
    },
    // product reports
    getReportsProductMeta(_state){
        return _state.product_meta;
    },
    getProductReports(_state){
        return _state.product_reports;
    },
    getPaginatedProductReports(_state){
        return _state.paginated_product_reports;
    },
}


// actions list
const actions = {
    getOrders: async function({commit},{page,limit,start_date,end_date,searchQuery,...rest}={}){
        commit(types.SET_ORDERS_UI_FLAG,{isFetching: true});
        try {
            const response = await OrderAPI.getAllOrders(start_date,end_date);
            const orders = response.data;
            // move this conversation_id part to button icon click
            // before set, add conversation_id for each order.contact
            // get default catalog,
            /*
            const {data:catalog} = await CatalogAPI.getDefaultCatalog();
             // Step 1: Create a unique list of contact IDs
            const uniqueContactIds = [...new Set(
                orders.filter(order => order.contact?.id).map(order=>order?.contact?.id)
            )];
            const conversationMap = new Map();

            const contactPromises = uniqueContactIds.map(contactId =>
                ContactApi.getShopContactConversationUrl({
                    contact_id: contactId,
                    inbox_id: catalog.inbox_id,
                }).then(({ data }) => {
                    const conversation_id = data?.length ? data[0] : null;
                    conversationMap.set(contactId, conversation_id);
                })
            );
            await Promise.all(contactPromises);

            // Step 4: Loop over the orders to attach the conversation ID
            const ordersWithConversation = orders.map(order => {
                const conversation_id = conversationMap.get(order.contact?.id) || null;
                return { ...order, conversation_id };
            });
            */

            // commit(types.SET_ORDERS_LIST, ordersWithConversation);
            commit(types.SET_ORDERS_LIST, orders);
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        } catch (error) {
            console.log(error);
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        }
    },
    getPaginatedOrders: async function({commit},option={sortBy:'id',sortOrder:'desc'}){
        const {currentPage,pageSize,searchText,sortBy, sortOrder,statuesList}= option;
        commit(types.SET_ORDERS_UI_FLAG,{isFetching: true});
        try {
            commit(types.SET_ORDERS_LIST_PAGINATED,{...option});
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        } catch (error) {
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        }
    },
    UpdateOrderById: async function({commit,state},{order_id,payload,}){
        commit(types.SET_ORDERS_UI_FLAG,{isUpdating: true});
        try {
            const {data} = await OrderAPI.updateOrderById(order_id,payload);
            const index = state.orders.findIndex(order => order.id === order_id);
            commit(types.SET_ORDER_UPDATE,{index,updatedOrder:data});
            commit(types.SET_ORDERS_UI_FLAG,{isUpdating: false});
        } catch (error) {
            commit(types.SET_ORDERS_UI_FLAG,{isUpdating: false});
            throw error;
        }
    },
    createOrder: async function({commit,state},payload){
        console.log(payload,"createOrder");
        commit(types.SET_ORDERS_UI_FLAG,{isCreating: true});
        try {
            // API fetch the order details by order id, then update state
            const {data} = await OrderAPI.getOrderById(payload.id);
            if (payload.delivery_service?.id) {
                data.delivery_service = data.delivery_service || {};
                data.delivery_service.id = payload.delivery_service.id;
            }
            console.log(data);
            commit(types.SET_ORDER_CREATE,data);
            commit(types.SET_ORDERS_UI_FLAG,{isCreating: false});
        } catch (error) {
            console.log(error);
            commit(types.SET_ORDERS_UI_FLAG,{isCreating: false});
            throw error;
        }
    },
    updateOrderLocalStatus: async function({commit,state},payload){
        console.log(payload,"updateOrderLocalStatus");
        try {
            const {order_id,order_status} = payload;

            const updatedOrders = state.orders.map(order => {
                if (order.id === order_id) {
                    return { ...order, order_status };
                }
                return order;
            });
            commit(types.SET_ORDERS_LIST, updatedOrders);
        } catch (error) {
            throw error;
        }
    },




    // sales report
    getSalesReports: async function({commit},{type,page,limit,start_date,end_date}={}){
        commit(types.SET_ORDERS_UI_FLAG,{isFetching: true});
        try {
            const response = await OrderAPI.getReportByType('sales',start_date,end_date);
            let  formattedReports = []
            if (Array.isArray(response.data)) {
                formattedReports = response.data.map(([amount,date])=>({amount,date,type}))
            }
            commit(types.SET_SALES_ORDERS_REPORTS,formattedReports);
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        } catch (error) {
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        }
    },
    getPaginatedSalesReports: async function({commit},option={sortBy:'date',sortOrder:'desc'}){
        const {currentPage,pageSize,sortBy, sortOrder}= option;
        commit(types.SET_ORDERS_UI_FLAG,{isFetching: true});
        try {
            commit(types.SET_SALES_ORDERS_REPORTS_PAGINATED,{...option});
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        } catch (error) {
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        }
    },
    // product report
    getProductReports: async function({commit},{type,page,limit,start_date,end_date}={}){
        commit(types.SET_ORDERS_UI_FLAG,{isFetching: true});
        try {
            const response = await OrderAPI.getReportByType('product',start_date,end_date);
            let  formattedReports = []
            if (Array.isArray(response.data)) {
                // formattedReports = response.data.map(([title,amount])=>({amount,title,type}))
                formattedReports = response.data.map((el)=>({
                    ...el,
                    amount: el.total,
                }))
            }
            commit(types.SET_PRODUCT_ORDERS_REPORTS,formattedReports);
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        } catch (error) {
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        }
    },
    getPaginatedProductReports: async function({commit},option={sortBy:'date',sortOrder:'desc'}){
        const {currentPage,pageSize,sortBy, sortOrder}= option;
        commit(types.SET_ORDERS_UI_FLAG,{isFetching: true});
        try {
            commit(types.SET_PRODUCT_ORDERS_REPORTS_PAGINATED,{...option});
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        } catch (error) {
            commit(types.SET_ORDERS_UI_FLAG,{isFetching: false});
        }
    },
}


// list of mutations
const mutations = {
    // mutate UI flags
    [types.SET_ORDERS_UI_FLAG](_state, data) {
        _state.uiFlags = {
          ..._state.uiFlags,
          ...data,
        };
    },

    [types.SET_ORDERS_LIST]: async($state,data)=>{
        if (Array.isArray(data)) {
            $state.orders = data;

            $state.meta = {
                ...$state.meta,
                count: data.length,
                currentPage: 1,
            };

            // update items based on pagination
            const pageSize = $state.meta.pageSize || 15;
            const startIndex = ($state.meta.currentPage - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            $state.paginatedOrders = $state.orders.slice(startIndex,endIndex);

        }else{
            $state.orders = [];
            $state.paginatedOrders = [];
            $state.meta = {
                ...$state.meta,
                count: 0,
                currentPage: 1,
            };
        }
    },


    [types.SET_ORDERS_LIST_PAGINATED]:($state,data)=>{
        const {currentPage,pageSize,searchText,sortBy, sortOrder,statuesList} = data;
        const shipmentAddressProperties = ['line1', 'line2', 'city', 'state', 'postalCode', 'country'];

        // Apply filters
        let filteredItems = $state.orders;
        // Search by contact name, and phone number
        if (searchText) {
            const searchRegex = new RegExp(searchText, 'i');
            filteredItems = filteredItems.filter(item =>
                    searchRegex.test(item.contact?.name || '')
                || searchRegex.test(item.contact?.phone_number || '')
            );
        }

        // filter by order status list
        if (statuesList && Array.isArray(statuesList) && statuesList.length > 0) {
            filteredItems = filteredItems.filter(item =>statuesList.includes(item.order_status));
        }

       // Sorting logic based on sortBy and sortOrder
       filteredItems.sort((a, b) => {
            let valueA = a[sortBy];
            let valueB = b[sortBy];
            if (sortBy === 'phone_number') {
                valueA = a.contact.phone_number;
                valueB = b.contact.phone_number;
            } else if (sortBy === 'name') {
                valueA = a.contact.name;
                valueB = b.contact.name;
            } else if (shipmentAddressProperties.includes(sortBy)) {
                // Check for nested properties under shipment_address
                valueA = a.shipment_address ? a.shipment_address[sortBy] : undefined;
                valueB = b.shipment_address ? b.shipment_address[sortBy] : undefined;
            }

            if (typeof valueA === 'string') {
                return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            } else if (typeof valueA === 'number') {
                return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
            } else if (typeof valueA === 'boolean') {
                return sortOrder === 'asc' ? (valueA === valueB ? 0 : (valueA ? 1 : -1)) : (valueB === valueA ? 0 : (valueB ? 1 : -1));
            } else {
                return 0;
            }
        });

        $state.meta = {
            ...$state.meta,
            count: filteredItems.length,
            currentPage: parseInt(currentPage),
            pageSize: parseInt(pageSize),
        };

        // we are considering infinity scroll, not page by page,
        const infinityScrollStartIndex = 0;
        // update items based on pagination
        const startIndex = (currentPage - 1) * parseInt(pageSize);
        const endIndex = startIndex + parseInt(pageSize);
        $state.paginatedOrders = filteredItems.slice(infinityScrollStartIndex,endIndex);
    },

    // create an order
    [types.SET_ORDER_CREATE]($state, newOrder) {
        console.log({newOrder});
        $state.orders.unshift(newOrder)
        $state.paginatedOrders.unshift(newOrder)
    },

    // update a order
    [types.SET_ORDER_UPDATE]($state, { index, updatedOrder }) {
        if (index !== -1) {
            $state.orders.splice(index, 1, updatedOrder);
        }

        const paginatedIndex = $state.paginatedOrders.findIndex(order => order.id === updatedOrder.id);
        if (paginatedIndex !== -1) {
            $state.paginatedOrders.splice(paginatedIndex, 1, updatedOrder);
        }
    },

    // set sales order reports
    [types.SET_SALES_ORDERS_REPORTS]($state,data){
        // $state.order_reports = reportData;
        if (Array.isArray(data)) {
            $state.sales_reports = data;

            $state.sales_meta = {
                ...$state.sales_meta,
                count: data.length,
                currentPage: 1,
            };

            // update items based on pagination
            const pageSize = $state.sales_meta.pageSize || 15;
            const startIndex = ($state.sales_meta.currentPage - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            $state.paginated_sales_reports = $state.sales_reports.slice(startIndex,endIndex);

        }else{
            $state.sales_reports = [];
            $state.paginated_sales_reports = [];
            $state.sales_meta = {
                ...$state.sales_meta,
                count: 0,
                currentPage: 1,
            };
        }
    },

    // set paginated sales order reports
    [types.SET_SALES_ORDERS_REPORTS_PAGINATED]:($state,data)=>{
        const {currentPage,pageSize,sortBy, sortOrder} = data;

        // Apply filters
        let filteredItems = $state.sales_reports;
        // amount, date
       // Sorting logic based on sortBy and sortOrder
       filteredItems.sort((a, b) => {
            let valueA = a[sortBy];
            let valueB = b[sortBy];


            if (sortBy === 'date') {
                valueA = new Date(a.date);
                valueB = new Date(b.date);
            }

            if (typeof valueA === 'string') {
                return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            } else if (typeof valueA === 'number') {
                return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
            } else if (typeof valueA === 'boolean') {
                return sortOrder === 'asc' ? (valueA === valueB ? 0 : (valueA ? 1 : -1)) : (valueB === valueA ? 0 : (valueB ? 1 : -1));
            } else if (valueA instanceof Date) {
                return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
            } else {
                return 0;
            }
        });

        $state.sales_meta = {
            ...$state.sales_meta,
            count: filteredItems.length,
            currentPage: parseInt(currentPage),
            pageSize: parseInt(pageSize),
        };

        // we are considering infinity scroll, not page by page,
        const infinityScrollStartIndex = 0;
        // update items based on pagination
        const startIndex = (currentPage - 1) * parseInt(pageSize);
        const endIndex = startIndex + parseInt(pageSize);
        $state.paginated_sales_reports = filteredItems.slice(infinityScrollStartIndex,endIndex);
    },

    // set product order reports
    [types.SET_PRODUCT_ORDERS_REPORTS]($state,data){
        // $state.order_reports = reportData;
        if (Array.isArray(data)) {
            $state.product_reports = data;

            $state.product_meta = {
                ...$state.product_meta,
                count: data.length,
                currentPage: 1,
            };

            // update items based on pagination
            const pageSize = $state.product_meta.pageSize || 15;
            const startIndex = ($state.product_meta.currentPage - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            $state.paginated_product_reports = $state.product_reports.slice(startIndex,endIndex);

        }else{
            $state.product_reports = [];
            $state.paginated_product_reports = [];
            $state.product_meta = {
                ...$state.product_meta,
                count: 0,
                currentPage: 1,
            };
        }
    },

    // set paginated product order reports
    [types.SET_PRODUCT_ORDERS_REPORTS_PAGINATED]:($state,data)=>{
        const {currentPage,pageSize,sortBy, sortOrder} = data;

        // Apply filters
        let filteredItems = $state.product_reports;
        // amount, date
       // Sorting logic based on sortBy and sortOrder
       filteredItems.sort((a, b) => {
            let valueA = a[sortBy];
            let valueB = b[sortBy];


            if (sortBy === 'date') {
                valueA = new Date(a.date);
                valueB = new Date(b.date);
            }

            if (typeof valueA === 'string') {
                return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            } else if (typeof valueA === 'number') {
                return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
            } else if (typeof valueA === 'boolean') {
                return sortOrder === 'asc' ? (valueA === valueB ? 0 : (valueA ? 1 : -1)) : (valueB === valueA ? 0 : (valueB ? 1 : -1));
            } else if (valueA instanceof Date) {
                return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
            } else {
                return 0;
            }
        });

        $state.product_meta = {
            ...$state.product_meta,
            count: filteredItems.length,
            currentPage: parseInt(currentPage),
            pageSize: parseInt(pageSize),
        };

        // we are considering infinity scroll, not page by page,
        const infinityScrollStartIndex = 0;
        // update items based on pagination
        const startIndex = (currentPage - 1) * parseInt(pageSize);
        const endIndex = startIndex + parseInt(pageSize);
        $state.paginated_product_reports = filteredItems.slice(infinityScrollStartIndex,endIndex);
    },




}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}