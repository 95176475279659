var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-right-top",
            icon: "add-circle",
            "is-disabled": !_vm.primaryCalendar.access_token
          },
          on: {
            click: function($event) {
              return _vm.clearAndOpen()
            }
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("CALENDAR_MGMT.ADD_HEADER_BTN_TXT")) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("sessions-table", {
        attrs: {
          sessions: _vm.sessions,
          refresh: _vm.getSessions,
          "is-loading": _vm.isFetchingSessions,
          currentUserId: _vm.currentUserId,
          openSessionEditor: _vm.openSessionEditor
        }
      }),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showAddPopup,
            size: "medium",
            "on-close": _vm.hideAddPopup
          },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [
          _c("CalendarMeetingAdd", {
            attrs: {
              "on-close": _vm.hideAddPopup,
              refresh: _vm.getSessions,
              currentUserId: _vm.currentUserId,
              prefilledForm: _vm.prefilledForm
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }