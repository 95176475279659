<template>
  <section class="orders-table-wrap orderActionMsgs">
    <ve-table
      :fixed-header="true"
      max-height="100%"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />

  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import CannedResponse from '../../../../components/widgets/conversation/CannedResponse.vue';
import { mapGetters } from "vuex";



export default {
    components:{
        VeTable,
        Spinner,
        CannedResponse,
    },
    props:{
        actionMessages:{
            type: Object,
            default: () => {}
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        cols: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        // onClickDelete: {
        //     type: Function,
        //     default: (action_id) => {},
        // },
        toggleMessage: {
          type: Function,
          default: (action_id) => {},
        },
        toggleMessageEditor: {
          type: Function,
          default: (action_id) => {},
        },
        onMessageChange: {
          type: Function,
          default: (action_id,message) => {},
        },


    },
    data(){
        return {
          sortConfig: {},
          sortOption: {
            sortAlways: true,
            sortChange: params => this.$emit('on-sort-change', params),
          },
        }
    },
    computed:{
        ...mapGetters({
          cannedMessages: 'getCannedResponses',
        }),
        tableData(){
            // console.log(this.cols,this.orders);
            if (this.isLoading) {
                return [];
            }
            const formattedArrayData = [];
            for(const key in this.actionMessages){
                if (Object.hasOwnProperty.call(this.actionMessages,key)) {
                    formattedArrayData.push(this.actionMessages[key])
                }
            }
            // console.log({formattedArrayData});
            const formattedOrders = formattedArrayData.map(message=>{
                const preSet = {
                    ...message,
                    action_id: message.action.id,
                    // message: message.message,
                    message: message.message,
                    title: message.action.title
                    // action: 'action button',
                }
                return preSet;
            })
            // console.log({formattedOrders});
            return formattedOrders
        },
        columns(){
            let matches = {};

            matches.action_id = {
                field: 'action_id',
                key: 'action_id',
                title: this.$t('CATALOGUE.SETTING.NOTIFICATION_MSG.TABEL.WHEN'),
                width: 240,
                fixed: 'left',
                align: 'left',
                renderBodyCell: ({row})=>{
                    return <span class="font_regular font_500 text_12">{row.action.title}</span>
                }
            }
            matches.message = {
                field: 'message',
                key: 'message',
                title: this.$t('CATALOGUE.SETTING.NOTIFICATION_MSG.TABEL.MESSAGE'),
                align: "left",
                renderBodyCell: ({row})=>{
                  const a= this.$t('MESSAGE_MGMT.CREATE_BTN')
                  // toggleMessageEditor
                  // make a edit icon btn and a textarea to edit the message
                  let messageValue = row.inputChanged
                    ? row.message
                    : row.message ? row.message : row.defaultMessage;

                    // <woot-button
                    //       variant="smooth"
                    //       size="small"
                    //       color-scheme="secondary"
                    //       class-names="grey-btn"
                    //       icon="edit"
                    //       onClick={()=>this.toggleMessageEditor(row.action_id)}
                    //     ></woot-button>

                  return <div>
                    {
                      row.isEditOpen && <div>
                        <textarea
                          value={messageValue}
                          style="padding:4px 8px;"
                          class="font_regular font_500 text_12"
                          placeholder={"Write message"}
                          onInput={(e)=>this.onMessageChange(row.action_id,e.target.value,false)}
                        ></textarea>
                        <div class="cann_replay">
                        {
                          row.showMentions && row.hasSlashCommand && <canned-response
                              // v-if="showMentions && hasSlashCommand"
                              top="37"
                              search-key={row.mentionSearchKey}
                              onClick={(message,template_name)=>this.onMessageChange(row.action_id,message,template_name,true)}
                              isPreview={true}
                              onPreviewClick={(item)=>console.log(item)}
                              isHideRejectItem={true}
                          />
                        }
                        </div>
                      </div>
                    }

                    {
                      !row.isEditOpen &&
                      <div>
                        {
                          row.isRealMsg
                          ? <span class="font_regular font_500 text_12">{row.message}</span>
                          : <span>
                              <i class="font_regular font_500 text_12">{ row.message ? row.message : row.defaultMessage}</i>
                            </span>
                        }
                      </div>
                    }

                  </div>
                }
            }
            matches.action = {
                field: 'action',
                key: 'action',
                title: 'Activate',
                width: 100,
                renderBodyCell: ({row})=>{
                    // return <span>{row.order_id}</span>
                    return (
                      <button
                        type="button"
                        class={`toggle-button ${row.isRealMsg ? "active" : ""}`}
                        role="switch"
                        aria-checked={row.isRealMsg}
                        onClick={() => this.toggleMessage(row.action_id)}
                      >
                        <span
                            aria-hidden="true"
                            class={row.isRealMsg ? "active" : ""}
                        ></span>
                      </button>
                    )
                }
            }
            matches.edit_action = {
                field: 'edit_action',
                key: 'edit_action',
                title: '',
                width: 100,
                renderBodyCell: ({row})=>{
                    const label = this.$t('CATALOGUE.SETTING.NOTIFICATION_MSG.TABEL.EDIT');
                    return (
                      <div class="edit_btn" onClick={()=>this.toggleMessageEditor(row.action_id)}>
                        <fluent-icon icon="edit" />
                        <span class="font_regular text_14 font_500">{label}</span>
                      </div>
                    )
                }
            }
            /*
            matches.action = {
                field: 'action',
                key: 'action',
                title: 'Action',
                renderBodyCell: ({row})=>{
                    // return <span>{row.order_id}</span>
                    return (
                      <woot-button
                          variant="smooth"
                            size="small"
                            color-scheme="alert"
                            icon="delete"
                            // onClick={() => this.onClickDelete(row.action_id)}
                            onClick={() => this.toggleMessage(row.action_id)}
                      >
                      {row.order_id}
                      </woot-button>
                    )
                }
            }
            */


            const preSet = [];
            this.cols.forEach(col=>{
                if (col.visible) {
                    if (col.key in matches) {
                        preSet.push(matches[col.key])
                    }
                }
            })
            return preSet;
        },
        // end computed
    },
    mounted(){
      bus.$on('MENTION_PREVIEW_CLICK_EVENT',(data)=>{
        this.onPreviewClick(data);
      })
    },
    watch:{},
    methods:{

      // end method
    },
}
</script>



<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.orders-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.orders-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    // background-color: var(--w-500) !important;
    // color: var(--white) !important;
    background-color: #fff !important;
    color: #8D25F6 !important;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
  .status_wrapper select{
    padding-left: 8px;
  }
}

.orders--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}




// toogle button design START
.toggle-button {
  // background-color: var(--s-200);
  background-color: #F0F0F0;
  position: relative;
  display: inline-flex;
  height: 19px;
  width: 34px;
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  cursor: pointer;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  flex-shrink: 0;
}

.toggle-button.active {
  // background-color: var(--w-500);
  background-color: #E5CEFD;
}

.toggle-button span {
  --space-one-point-five: 14px;
  height: var(--space-one-point-five);
  width: var(--space-one-point-five);
  display: inline-block;
  background-color: #B8B8B8;
  transform: translate(2px, 1px);
  border-radius: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}
.toggle-button span.active {
  background-color: #8D25F6;
  transform: translate(var(--space-one-point-five), 1px);
}
// toogle button design END
.font_normal{
  font-size: 14px;
}
.cann_replay{
  .create_temp_msg_btn{
    position: relative;
    // top: -18rem;
    top: var(--top-create-cann-padding);
    z-index: 101;
    background: #fff;
    padding: 12px;
    width: 100%;
    left: 0;
    color: #8D25F6;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    padding-left: 12px;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    cursor: pointer;
    &:hover{
      background: #8D25F6;
      color: #fff;
    }
    span{
      font-weight: 500;
    }
  }

}

.orderActionMsgs{

}
.edit_btn{
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg{
      color: #858585;
    }
}
</style>



<style >
.orderActionMsgs .ve-table-container{
  overflow-y: hidden !important;
}
.orderActionMsgs  .ve-table .ve-table-container .ve-table-content.ve-table-border-x tr:first-child > th, .ve-table .ve-table-container .ve-table-content.ve-table-border-x tr.ve-table-footer-tr:first-child > td {
    border-top: none;
}
</style>





