<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CATALOGUE.ADD_EDIT_FORM.HEADER.TITLE')"
        :header-content="$t('CATALOGUE.ADD_EDIT_FORM.HEADER.CONTENT')"
      />
      <form ref="form" class="row" @submit.prevent="onSaveCatalog()">
        <div class="medium-12 columns">
          <!-- <label :class="{ error: $v.title.$error }">
            {{ $t('CATALOGUE.ADD_EDIT_FORM.TITLE_LABEL') }}
            <input
              v-model.trim="title"
              type="text"
              :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.TITLE_PLACEHOLDER')"
              @input="$v.title.$touch"
            />
          </label> -->
          <woot-input
            :label="$t('CATALOGUE.ADD_EDIT_FORM.TITLE_LABEL')"
            :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.TITLE_PLACEHOLDER')"
            type="text"
            v-model="title"
            :class="{ error: $v.title.$error }"
            :error="$v.title.$error? $t('CATALOGUE.ADD_EDIT_FORM.TITLE_ERROR'): ''"
          />
        </div>
        <!-- make description field   -->
        <div class="medium-12 columns">
          <label :class="{ error: $v.description.$error }">
            {{ $t('CATALOGUE.ADD_EDIT_FORM.DESCRIPTION_LABEL') }}
            <textarea
              v-model.trim="description"
              rows="5"
              type="text"
              :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.DESCRIPTION_PLACEHOLDER')"
              @input="$v.description.$touch"
            />
          </label>
          <p v-if="$v.description.$error" class="error_message">
            {{$t('CATALOGUE.ADD_EDIT_FORM.DESCRIPTION_ERROR')}}
          </p>
        </div>
        <!-- make file field   -->
        <div class="medium-12 columns display-inline">
          <label>
            <span>{{ $t('CATALOGUE.ADD_EDIT_FORM.IMG_LABEL') }}</span>
            <input
              id="file"
              ref="file"
              type="file"
              accept="image/*,"
              @change="handleImageUpload"
            />
          </label>
        </div>
        <div class="medium-12 columns display-inline">
            <div class="medium-6 columns">
                <label :class="{ error: $v.price.$error }">
                    <span>{{ $t('CATALOGUE.ADD_EDIT_FORM.PRICE_LABEL') }}</span>
                    <input
                        v-model.trim="price"
                        type="number"
                        step="any"
                        :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.PRICE_PLACEHOLDER')"
                        @input="$v.price.$touch"
                    />
                </label>
                <p v-if="$v.price.$error" class="error_message">
                  {{$t('CATALOGUE.ADD_EDIT_FORM.PRICE_ERROR')}}
                </p>
              <!-- <woot-input
                v-model="price"
                :label="$t('CATALOGUE.ADD_EDIT_FORM.PRICE_LABEL')"
                :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.PRICE_PLACEHOLDER')"
                type="number"
                :class="{ error: $v.price.$error }"
                :error="$v.price.$error? $t('CATALOGUE.ADD_EDIT_FORM.PRICE_ERROR'): ''"
              /> -->

            </div>
            <div class="medium-6 columns">
                <label>
                    <span>{{ $t('CATALOGUE.ADD_EDIT_FORM.CURRENCY_LABEL') }}</span>
                    <select v-model="currency">
                        <option value="MYR">MYR</option>
                        <option value="USD">USD</option>
                    </select>
                </label>
            </div>
        </div>
        <div class="medium-12 columns display-inline">
          <div class="medium-6 columns">
            <label>
                <span>{{ $t('CATALOGUE.ADD_EDIT_FORM.AVAILABILITY_LABEL') }}</span>
                <select v-model="availability">
                    <option value="in stock">In Stock</option>
                    <option value="out stock">Out of Stock</option>
                </select>
            </label>
          </div>
          <div class="medium-6 columns">
            <!-- <label :class="{ error: $v.inventory.$error }">
                <span>Inventory</span>
                <input
                  v-model.trim="inventory"
                  class="remove_field_spin"
                  type="number"
                  step="1"
                  min="0"
                  placeholder="eg, 1, 2, 3....."
                  @input="$v.inventory.$touch"
                />
            </label> -->
            <woot-input
              :label="$t('CATALOGUE.ADD_EDIT_FORM.INVENTORY_LABEL')"
              :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.INVENTORY_PLACEHOLDER')"
              type="number"
              v-model="inventory"
              :class="{ error: $v.inventory.$error }"
              :error="$v.inventory.$error? $t('CATALOGUE.ADD_EDIT_FORM.INVENTORY_ERROR'): ''"
            />
          </div>
        </div>
        <div class="medium-12 columns">
          <label>
            <div class="dimensions">
                <div class="field_wrap">
                    <woot-input
                    :label="$t('CATALOGUE.ADD_EDIT_FORM.WEIGHT.LABEL')"
                    :placeholder="''"
                    type="number"
                    step="any"
                    v-model="shipping_weight"
                    :class="{ error: $v.shipping_weight.$error }"
                    :error="$v.shipping_weight.$error? $t('CATALOGUE.ADD_EDIT_FORM.WEIGHT.ERROR'): ''"
                  />
                  <span class="unit">{{$t('CATALOGUE.ADD_EDIT_FORM.WEIGHT.UNIT')}}</span>
                </div>
                <div>
                  <div class="field_wrap">
                    <woot-input
                      :label="$t('CATALOGUE.ADD_EDIT_FORM.HEIGHT.LABEL')"
                      :placeholder="''"
                      type="number"
                      step="any"
                      v-model="height"
                      :class="{ error: $v.height.$error }"
                      :error="$v.height.$error? $t('CATALOGUE.ADD_EDIT_FORM.HEIGHT.ERROR'): ''"
                    />
                    <span class="unit">{{$t('CATALOGUE.ADD_EDIT_FORM.HEIGHT.UNIT')}}</span>
                  </div>
                </div>
                <div>
                  <div class="field_wrap">
                    <woot-input
                      :label="$t('CATALOGUE.ADD_EDIT_FORM.WIDTH.LABEL')"
                      :placeholder="''"
                      type="number"
                      step="any"
                      v-model="width"
                      :class="{ error: $v.width.$error }"
                      :error="$v.width.$error? $t('CATALOGUE.ADD_EDIT_FORM.WIDTH.ERROR'): ''"
                    />
                    <span class="unit">{{$t('CATALOGUE.ADD_EDIT_FORM.WIDTH.UNIT')}}</span>
                  </div>
                </div>
                <div>
                  <div class="field_wrap">
                    <woot-input
                      :label="$t('CATALOGUE.ADD_EDIT_FORM.LENGTH.LABEL')"
                      :placeholder="''"
                      type="number"
                      step="any"
                      v-model="length"
                      :class="{ error: $v.length.$error }"
                      :error="$v.length.$error? $t('CATALOGUE.ADD_EDIT_FORM.LENGTH.ERROR'): ''"
                    />
                    <span class="unit">{{$t('CATALOGUE.ADD_EDIT_FORM.LENGTH.UNIT')}}</span>
                  </div>
                </div>
            </div>
          </label>
        </div>
        <!-- categories field make  -->

        <div class="medium-12 columns">
          <label>
            <span>{{ $t('CATALOGUE.ADD_EDIT_FORM.ASSIGN_CTG_LABEL') }}</span>
            <CategoryDropdown
              v-model="selectedCategories"
              :options="categories"
            />
          </label>
        </div>


        <div class="medium-12 columns">
          <div class="addVariant_label">
            <label><span>{{ $t('CATALOGUE.ADD_EDIT_FORM.VARIANT_LABEL') }}</span></label>
            <div>
                <div>
                    <woot-button
                      color-scheme="primary"
                      variant="smooth"
                      size="small"
                      icon="add"
                      @click="addVariant"
                    />
                </div>
            </div>
          </div>
            <div v-for="(variant, index) in variants" class="variant_inputs" :key="index">
                <div class="medium-4 columns" :class="{ error: isFieldError('title', index) }">
                    <input
                        v-model.trim="variant.title"
                        type="text"
                        :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.VARIANT_TITLE_PLACEHOLDER')"
                    />
                    <p v-if="isFieldError('title',index)" class="error_message">
                      {{$t('CATALOGUE.ADD_EDIT_FORM.VARIANT_TITLE_REQUIRED_ERR')}}
                    </p>
                </div>
                <div class="medium-3 columns" :class="{ error: isFieldError('price', index) }">
                    <input
                        v-model.trim="variant.price"
                        type="number"
                        step="any"
                        :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.VARIANT_PRICE_PLACEHOLDER')"
                    />
                    <p v-if="isFieldError('price',index)" class="error_message">
                      {{$t('CATALOGUE.ADD_EDIT_FORM.VARIANT_PRICE_REQUIRED_ERR')}}
                    </p>
                </div>
                <div class="medium-3 columns" :class="{ error: isFieldError('inventory', index) }">
                    <input
                        v-model.trim="variant.inventory"
                        type="number"
                        step="any"
                        :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.VARIANT_INVENTORY_PLACEHOLDER')"
                    />
                    <p v-if="isFieldError('inventory',index)" class="error_message">
                      {{$t('CATALOGUE.ADD_EDIT_FORM.VARIANT_INVENTORY_REQUIRED_ERR')}}
                    </p>
                </div>
                <div class="medium-2 columns">
                    <div>
                        <button
                            @click="removeVariant(index)"
                            type="button"
                            style="margin-top: 10px;"
                        >
                            <fluent-icon icon="dismiss" />
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div>
                <div>
                    <button class="add_variant_btn" @click="addVariant" type="button">
                        <fluent-icon icon="add" />
                    </button>
                </div>
            </div> -->
        </div>



        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :button-text="$t('CATALOGUE.ADD_EDIT_FORM.ADD_BTN')"
              :loading="isSaveAndCloseBtnLoading"
              :disabled="uiFlags.isCreating_catalog_items"
            />

            <woot-button
              color-scheme="primary"
              type="button"
              :is-loading="isAddAnotherBtnLoading"
              :is-disabled="uiFlags.isCreating_catalog_items"
              @click="addAnotherItem"
            >
              Add Another Item
            </woot-button>
            <woot-button
              v-if="isTutorialBtnShow"
              color-scheme="primary"
              type="button"
              @click="onContinueTutorial"
            >
              Continue Tutorial
            </woot-button>

            <button class="button clear" @click.prevent="onClose">
              {{ $t('TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength, numeric, decimal , minValue} from 'vuelidate/lib/validators';
import methodHelperMixin from '../../../../mixins/methodHelperMixin';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import {SIGNUP_FLOW} from "../../../../helper/demoAutomationHelper"
import CategoryDropdown from "./CategoryDropdown.vue"

export default {
  components: {
    WootSubmitButton,
    Modal,
    CategoryDropdown,
  },
  mixins: [alertMixin,methodHelperMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
        show: true,
        title:"",
        description:"",
        price:"",
        currency:"MYR",
        availability:"in stock",
        inventory:"",
        file: "",
        shipping_weight: null,
        width: null,
        height: null,
        length: null,
        variants: [
          // { title: '', price: '', inventory:0 }
        ],
        selectedCategories:[],   // selected Categories
        isSaveAndCloseBtnLoading: false,
        isAddAnotherBtnLoading: false,
        isTutorialBtnShow: false,
    };
  },
  validations: {
    title: {
      required,
    },
    description: {
      required,
    },
    price: {
      required,
      // numeric,
      minValue: minValue(0),
    },
    inventory: {
      required,
      numeric,
      minValue: minValue(0),
    },
    width: {
      decimal,
      minValue: minValue(0),
    },
    height: {
      decimal,
      minValue: minValue(0),
    },
    length: {
      decimal,
      minValue: minValue(0),
    },
    shipping_weight: {
      decimal,
      minValue: minValue(0),
    },
    variants:{
      $each:{
        title:{
          required
        },
        price:{
          required,
          // numeric,
          minValue: minValue(0),
        },
        inventory:{
          required,
          numeric,
          minValue: minValue(0),
        },
      }
    },
    availability: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      categories: "catalog/getCatagories",
      uiFlags: "catalog/getUIFlags",
    }),
  },
    mounted() {
    this.checkInitialTutorialBtnOpen();
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
    },
    async addCatalogueItem() {
        const payload = {
            title: this.title,
            description: this.description,
            price:`${this.price} ${this.currency}`,
            availability: this.availability,
            inventory: this.inventory,
            width: this.width,
            height: this.height,
            length: this.length,
            shipping_weight: this.shipping_weight,
            categories: this.selectedCategories.map(el=>el.id),
            file: this.file,
            variants: this.variants
        }

        this.$v.$touch();
        if (this.$v.$invalid) {
            console.log(payload,"addCatalogueItem");
            return;
        }

        // Create a new FormData object
        const formData = new FormData();
        formData.append('title', payload.title);
        formData.append('description', payload.description);
        formData.append('price', payload.price);
        formData.append('availability', payload.availability);
        formData.append('inventory', payload.inventory);
        formData.append('width', payload.width);
        formData.append('height', payload.height);
        formData.append('length', payload.length);
        formData.append('shipping_weight', payload.shipping_weight);
        formData.append('categories', JSON.stringify(payload.categories));
        formData.append('file', payload.file);
        formData.append('variants', JSON.stringify(payload.variants));


        try {
            const ctgResult = await this.$store.dispatch("catalog/createCatalogueItem",formData)
           await this.$store.dispatch("catalog/getCatalogProductItems");

            this.showAlert(this.$t('CATALOGUE.CRUD.CATALOGUE.CREATE_SUCCESS'));
            this.resetForm();
            // clear fields
            this.selectedCategories = [];
            this.variants = [];
            this.description = "";
            this.price = 0;
            // this.onClose();
          return true
        } catch (error) {
            console.log(error);
            this.showAlert(this.$t('CATALOGUE.CRUD.CATALOGUE.CREATE_FAIL'));
        }
    },
    async handleImageUpload(event) {
      this.file = event.target.files[0];

      if (this.file.size > 1024 * 1024 * 40) {
        alert('File is too big. Please select file of size less then 40mb.');
        this.$refs.file.value = null;
        this.file = '';
      }
      // compress and resize the image
      if (event.target.files[0]) {
        const resizedImage = await this.resizeImage(event.target.files[0], { maxWidth: 500, maxHeight: 500 });
        // console.log({resizedImage,original:event.target.files[0]});
        const compressedImage = await this.compressImage(resizedImage, { maxSizeKB: 800, initialQuality: 0.9, qualityStep: 0.1 });
        this.file = compressedImage
      }

    },
    addVariant() {
        this.variants.push({ title: '', price: '' });
    },
    removeVariant(index) {
        this.variants.splice(index, 1);
    },
    isFieldError(fieldName, index) {
        const result = this.$v.variants.$each[index]?.[fieldName]?.$error || false;
        return result
    },
    async addAnotherItem(){
      // add current item and clan the current one
      this.isAddAnotherBtnLoading = true;
      await this.addCatalogueItem();
      this.isAddAnotherBtnLoading = false;
    },
    async onSaveCatalog(){
      // add separate loader
      this.isSaveAndCloseBtnLoading = true;
      const result = await this.addCatalogueItem();
      if (result) {
        this.isSaveAndCloseBtnLoading = false;
        this.onClose();
      }
      this.isSaveAndCloseBtnLoading = false;
    },
    onContinueTutorial(){
      // if first time signup, show QR page & clear the localstorage
      const signup_flow = SIGNUP_FLOW.getFlow()
      if (signup_flow?.isNewSignup && signup_flow?.current_step === SIGNUP_FLOW.FLOWS.catalog_item) {
        SIGNUP_FLOW.clearFlow()
        this.isTutorialBtnShow = false;
        this.$router.push({
          path: `/app/accounts/${this.currentUser.account_id}/catalog/settings`,
          query: {}
        });
      }
      this.onClose();
    },
    checkInitialTutorialBtnOpen(){
      const signupFlow = SIGNUP_FLOW.getFlow();
      if(signupFlow?.isNewSignup && signupFlow?.current_step === SIGNUP_FLOW.FLOWS.catalog_item ){
        this.isTutorialBtnShow = true;
      }
    },
    // end methods
  },
};
</script>
<style scoped lang="scss">
.display-inline {
  display: inline-flex;
  justify-content: space-between;
}

.variant_inputs{
    display: flex;
    gap: 0.5rem;

    input{
        margin: 0;
    }
}
.addVariant_label{
  display: flex;
  gap: 1rem;
  margin-bottom: 10px;
  align-items: center;
}
.error_message{
    color: red;
}
.remove_field_spin {
    /* Hide arrows for Chrome, Safari, and Edge */
    -webkit-appearance: none; /* Remove default appearance */
    -moz-appearance: textfield; /* Remove default appearance in Firefox */
    appearance: none; /* Remove default appearance in modern browsers */
}

/* Specific to WebKit browsers */
.remove_field_spin::-webkit-inner-spin-button,
.remove_field_spin::-webkit-outer-spin-button {
    display: none; /* Hide the spin buttons */
}

.dimensions{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 8px;
  .field_wrap{
    position: relative;
    .unit{
      position: absolute;
      top: 32px;
      right: 5px;
    }
  }
}
</style>

<style>
.dimensions input::-webkit-outer-spin-button,
.dimensions input::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}
.dimensions input{
  -moz-appearance: textfield;
}
.dimensions .field_wrap input{
  padding-right: 30px;
}
</style>
