var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "column content-box" },
      [
        _c(
          "woot-tabs",
          {
            attrs: { index: _vm.selectedTabIndex },
            on: { change: _vm.onClickTabChange }
          },
          _vm._l(_vm.tabs, function(tab) {
            return _c("woot-tabs-item", {
              key: tab.key,
              attrs: { name: tab.name, "show-badge": false }
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "woot-button",
          {
            attrs: {
              "color-scheme": "success",
              "class-names": "button--fixed-right-top",
              icon: "add-circle"
            },
            on: { click: _vm.openTopUpModal }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("TOP_UP.BUTTON_TITLE")) + "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "columns" },
          [
            _c("stripe-component", {
              attrs: { "modal-value": this.topUpModal },
              on: { "close-modal": _vm.closeModal }
            }),
            _vm._v(" "),
            _vm.selectedTabIndex == 0
              ? _c("paid-messaging", { key: _vm.selectedTabIndex })
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedTabIndex == 1
              ? _c("bots-transactions", { key: _vm.selectedTabIndex })
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedTabIndex == 2
              ? _c("order-usages", { key: _vm.selectedTabIndex })
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedTabIndex == 3
              ? _c("expired-credit", { key: _vm.selectedTabIndex })
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedTabIndex == 4
              ? _c("MeetingUsages", { key: _vm.selectedTabIndex })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }