var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "toggle_field mb_16" }, [
    _c(
      "button",
      {
        staticClass: "toggle-button",
        class: { active: _vm.value },
        attrs: {
          type: "button",
          role: "switch",
          "aria-checked": _vm.value.toString()
        },
        on: { click: _vm.onToggleChange }
      },
      [
        _c("span", {
          class: { active: _vm.value },
          attrs: { "aria-hidden": "true" }
        })
      ]
    ),
    _vm._v(" "),
    _c("span", { staticClass: "text_14" }, [
      _vm._v("\n          " + _vm._s(_vm.label) + "\n          "),
      _vm.infoList.length
        ? _c(
            "span",
            { staticClass: "tooltip_info" },
            [
              _c("fluent-icon", {
                staticClass: "help_icon",
                attrs: { icon: _vm.infoIcon }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tooltip_card" },
                _vm._l(_vm.infoList, function(line, index) {
                  return _c("p", { key: index, staticClass: "mb_0 font_12" }, [
                    _vm._v(_vm._s(line))
                  ])
                }),
                0
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }