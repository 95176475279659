var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-box" },
    [
      _c(
        "StepBasedForm",
        {
          attrs: {
            title: _vm.$t("COUPON.ADD_FORM.HEADER.TITLE"),
            range_label: _vm.step_label,
            current_step: _vm.step,
            max_steps: _vm.max_steps
          }
        },
        [
          _vm.step === 1
            ? _c("CouponType", { on: { onNext: _vm.onNext } })
            : _vm._e(),
          _vm._v(" "),
          _vm.step === 2 ? _c("CouponDiscount") : _vm._e(),
          _vm._v(" "),
          _vm.showPreview ? _c("AddCouponPreview") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }