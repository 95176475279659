import { frontendURL } from '../../../helper/URLHelper';
import CatalogueWrapper from './CatalogueWrapper.vue';
import CatalogueHome from './catelogues/CatalogueHome.vue';
import OrderDetails from './orders/OrderDetails.vue';
import Orders from './orders/Orders.vue';
import CatalogReportHome from './reports/ReportsHome.vue';
import CouponList from './coupons/CouponList.vue';
import AddCoupon from './coupons/AddCoupon.vue';
import CatalogSetting from './settings/CatalogSetting.vue';
import store from '../../../store';
import { getCookiesUser } from "../../../store/utils/api";

/*
  1) go to "config/sidebaritems/fileNameRoutes.js" and add the route in "menuItems"
  2) now add the Component will be render for the added router, Add it is here with child
  3) add this catelogue.routes.js file to '/dashboard/dashboard.routes.js' file to active this route
*/

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/catalog'),
      name: 'catalogues_state',  // must match with 'toStateName', name is only for 1st child
      roles: ['administrator', 'agent'],
      component: CatalogueWrapper,
      redirect: () => {
        const user = getCookiesUser();
        // read user from cookies
        if (user?.role === 'agent') {
          return frontendURL('accounts/:accountId/catalog/orders');
        }
        return frontendURL('accounts/:accountId/catalog/list');
      },
      props: () => {
        return {
          headerTitle: 'Catalog',
          icon: 'video-call',
          showBackButton: false,
        };
      },
      children: [
        {
          path: 'list',
          name: 'catalogues_list', // must match this name in /sidebarItems/catalogues.js file in both "routes array" and "menuItems array"
          component: CatalogueHome,
          roles: ['administrator'],
        },
      ],
    },

    {
      path: frontendURL('accounts/:accountId/catalog'),
      roles: ['administrator', ],
      component: CatalogueWrapper,
      props: () => {
        return {
          headerTitle: 'Orders',
          icon: 'video-call',
          showBackButton: false,
        };
      },
      children: [
        {
          path: 'orders',
          name: 'catalogues_orders', // must match this name in /sidebarItems/catalogues.js file in both "routes array" and "menuItems array"
          component: Orders,
          roles: ['administrator','agent'],
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/catalog'),
      roles: ['administrator', ],
      component: CatalogueWrapper,
      props: () => {
        return {
          headerTitle: 'Order Details',
          icon: 'cart',
          showBackButton: false,
        };
      },
      children: [
        {
          path: 'orders/:order_id',
          name: 'catalogue_order_details',
          component: OrderDetails,
          roles: ['administrator','agent'],
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/catalog'),
      roles: ['administrator', ],
      component: CatalogueWrapper,
      props: () => {
        return {
          headerTitle: 'Catalog Setting',
          icon: 'settings',
          showBackButton: false,
        };
      },
      children: [
        {
          path: 'settings',
          name: 'catalogue_settings',
          component: CatalogSetting,
          roles: ['administrator'],
        },
      ],
    },

    {
      path: frontendURL('accounts/:accountId/catalog'),
      roles: ['administrator', ],
      component: CatalogueWrapper,
      props: () => {
        return {
          headerTitle: 'Coupons',
          icon: 'coupon',
          showBackButton: false,
        };
      },
      children: [
        {
          path: 'coupons',
          name: 'coupons_list',
          component: CouponList,
          roles: ['administrator'],
        },
        {
          path: 'coupons/add',
          name: 'add_coupon',
          component: AddCoupon,
          roles: ['administrator'],
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/catalog'),
      roles: ['administrator', ],
      component: CatalogueWrapper,
      props: () => {
        return {
          headerTitle: 'Reports',
          icon: 'document',
          showBackButton: false,
        };
      },
      children: [
        {
          path: 'reports',
          name: 'catalog_reports',
          component: CatalogReportHome,
          roles: ['administrator'],
        },
      ],
    },

    // setting here

  ],
};

