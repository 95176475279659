var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "search search_box" }, [
      _c(
        "div",
        { staticClass: "icon" },
        [
          _c("fluent-icon", {
            staticClass: "search--icon",
            attrs: { icon: "search", size: "20" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "search--input search_input font_regular text_14 font_500",
        attrs: { placeholder: _vm.placeholder },
        domProps: { value: _vm.value },
        on: {
          input: _vm.onInput,
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.onEnter($event)
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }