import { frontendURL } from '../../../helper/URLHelper';
import account from './account/account.routes';
import agent from './agents/agent.routes';
import canned from './canned/canned.routes';
import inbox from './inbox/inbox.routes';
import integrations from './integrations/integrations.routes';
import integrationapps from './integrationapps/integrations.routes';
import labels from './labels/labels.routes';
import profile from './profile/profile.routes';
import reports from './reports/reports.routes';
import campaigns from './campaigns/campaigns.routes';
import teams from './teams/teams.routes';
import attributes from './attributes/attributes.routes';
import automation from './automation/automation.routes';
import BillingUsage from './reports/billing_useages/Index';
import SubscriptionPlans from './plans/Index';
import SettingsContent from './Wrapper';
import store from '../../../store';
import calendar from './calendar/calendar.routes';
import message from './messages/message.routes';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings'),
      name: 'settings_home',
      roles: ['administrator', 'agent'],
      redirect: () => {
        if (store.getters.getCurrentRole === 'agent') {
          return frontendURL('accounts/:accountId/settings/agents');
        }
        return frontendURL('accounts/:accountId/settings/canned-response');
      },
    },
    {
      path: frontendURL('accounts/:accountId/settings'),
      component: SettingsContent,
      props: {
        headerTitle: 'Billing Usages',
        icon: 'people-team',
      },
      children: [
        {
          path: 'billing-usages',
          name: 'billing_usages',
          component: BillingUsage,
          roles: ['administrator'],
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/settings'),
      component: SettingsContent,
      props: {
        headerTitle: 'Subscription Plans',
        icon: 'people-team',
      },
      children: [
        {
          path: 'subscription-plans',
          name: 'subscription_plans',
          component: SubscriptionPlans,
          roles: ['administrator'],
        },
      ],
    },
    ...account.routes,
    ...calendar.routes,
    ...agent.routes,
    // ...canned.routes,
    ...inbox.routes,
    ...integrations.routes,
    ...labels.routes,
    ...profile.routes,
    ...reports.routes,
    ...teams.routes,
    ...campaigns.routes,
    ...integrationapps.routes,
    ...attributes.routes,
    ...automation.routes,
    ...message.routes,
  ],
};
