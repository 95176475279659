<template>
  <div>
    <MultiselectDropdown
        :label="$t('COUPON.ADD_FORM.TYPE_FORM.TYPE.LABEL')"
        :error="$t('COUPON.ADD_FORM.TYPE_FORM.TYPE.ERROR')"
    >
        <RadioList
            :classWrapper="'couponOptions'"
            :options="coupon_types"
            v-model="coupon_type"
        />
    </MultiselectDropdown>

    <ToggleField
        v-model="uniqueQR"
        :label="$t('COUPON.ADD_FORM.TYPE_FORM.QR_CODE.LABEL')"
        :hint="'sample hint'"
        @onToggle="onToggleUniqueQR"
    />

    <InputField
        v-model="coupon_name"
        :label="$t('COUPON.ADD_FORM.TYPE_FORM.NAME.LABEL')"
        :placeholder="$t('COUPON.ADD_FORM.TYPE_FORM.NAME.PLACEHOLDER')"
        :error="$v.coupon_name.$error ? $t('COUPON.ADD_FORM.TYPE_FORM.NAME.ERROR') : ''"
        :infoList="[]"
        :hint="$t('COUPON.ADD_FORM.TYPE_FORM.NAME.HINT')"
        @blur="$v.coupon_name.$touch"
    />
    <InputField
        v-model="promo_code"
        :label="$t('COUPON.ADD_FORM.TYPE_FORM.PROMO_CODE.LABEL')"
        :placeholder="$t('COUPON.ADD_FORM.TYPE_FORM.PROMO_CODE.PLACEHOLDER')"
        :error="$v.promo_code.$error ? $t('COUPON.ADD_FORM.TYPE_FORM.PROMO_CODE.ERROR') : ''"
        :infoList="[]"
        :hint="$t('COUPON.ADD_FORM.TYPE_FORM.PROMO_CODE.HINT')"
        @blur="$v.promo_code.$touch"
    />
    <InputField
        v-model="serial_code_prefix"
        :label="$t('COUPON.ADD_FORM.TYPE_FORM.SERIAL_PREFIX.LABEL')"
        :placeholder="$t('COUPON.ADD_FORM.TYPE_FORM.SERIAL_PREFIX.PLACEHOLDER')"
        :error="$v.serial_code_prefix.$error ? $t('COUPON.ADD_FORM.TYPE_FORM.SERIAL_PREFIX.ERROR') : ''"
        :infoList="[]"
        @blur="$v.serial_code_prefix.$touch"
    />

    <MultiselectDropdown
        :label="$t('COUPON.ADD_FORM.TYPE_FORM.PRODUCT.LABEL')"
        :infoList="[]"
        :error="$v.product.$error ? $t('COUPON.ADD_FORM.TYPE_FORM.PRODUCT.ERROR') : ''"
    >
        <multiselect
            class="column small-12"
            v-model="product"
            :options="product_list"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('COUPON.ADD_FORM.TYPE_FORM.PRODUCT.PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
        />
    </MultiselectDropdown>
    <InputField
        v-model="title"
        :label="$t('COUPON.ADD_FORM.TYPE_FORM.TITLE.LABEL')"
        :placeholder="$t('COUPON.ADD_FORM.TYPE_FORM.TITLE.PLACEHOLDER')"
        :error="$v.title.$error ? $t('COUPON.ADD_FORM.TYPE_FORM.TITLE.ERROR') : ''"
        :infoList="[]"
        @blur="$v.title.$touch"
    />
    <MultiselectDropdown
        :label="$t('COUPON.ADD_FORM.TYPE_FORM.FOOTER.LABEL')"
        :infoList="[]"
        :error="$v.footer.$error ? $t('COUPON.ADD_FORM.TYPE_FORM.FOOTER.ERROR') : ''"
    >
        <multiselect
            class="column small-12"
            v-model="footer"
            :options="footer_list"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('COUPON.ADD_FORM.TYPE_FORM.FOOTER.PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
        />
    </MultiselectDropdown>
    <MultiselectDropdown
        :label="$t('COUPON.ADD_FORM.TYPE_FORM.IMG.LABEL')"
        :infoList="[]"
        :error="$v.image.$error ? $t('COUPON.ADD_FORM.TYPE_FORM.IMG.ERROR') : ''"
    >
        <multiselect
            class="column small-12"
            v-model="image"
            :options="image_list"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('COUPON.ADD_FORM.TYPE_FORM.IMG.PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
        />
    </MultiselectDropdown>
    <div class="submit_btns">
        <woot-button
            color-scheme="primary" size="small" :style="{width:'100%'}"
            :is-loading="false"
            :is-disabled="false"
            @click="onNext"
        >
           btn
          </woot-button>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import Spinner from 'shared/components/Spinner.vue';
import RadioList from "../../../../dashboard/arrange/RadioList.vue"
import MultiselectDropdown from "../../../../../components/ui/inputs/MultiselectDropdown.vue"
import InputField from '../../../../../components/ui/inputs/InputField.vue';
import ToggleField from '../../../../../components/ui/inputs/ToggleField.vue';

export default {
    components: {
        RadioList,
        MultiselectDropdown,
        InputField,
        ToggleField,
    },
    mixins: [alertMixin],
    data(){
        return {
            coupon_type: 0,
            coupon_name: "",
            promo_code: "",
            product: null,
            product_list: [
                {id:1,title:"A"},
                {id:2,title:"B"},
                {id:3,title:"C"},
            ],
            title: "",
            footer: null,
            footer_list: [
                {id:1,title:"A"},
                {id:2,title:"B"},
                {id:3,title:"C"},
            ],
            image: null,
            image_list: [
                {id:1,title:"A"},
                {id:2,title:"B"},
                {id:3,title:"C"},
            ],
            uniqueQR: false,
            serial_code_prefix: '',
        }
    },
    computed:{
        coupon_types(){
            return [
               {
                   id:'type1',
                   label: this.$t('COUPON.ADD_FORM.TYPE_FORM.TYPE.LIST.UNIQUE'),
                   description: this.$t('COUPON.ADD_FORM.TYPE_FORM.TYPE.LIST.UNIQUE_DESC'),
               },
               {
                   id:'type2',
                   label: this.$t('COUPON.ADD_FORM.TYPE_FORM.TYPE.LIST.NON_UNIQUE'),
                   description: this.$t('COUPON.ADD_FORM.TYPE_FORM.TYPE.LIST.NON_UNIQUE_DESC'),
               },
           ]
        }
    },
    validations:{
        coupon_type:{
            required
        },
        coupon_name:{
            required
        },
        promo_code:{
            required
        },
        product:{
            required
        },
        title:{
            required
        },
        footer:{
            required
        },
        image:{
            required
        },
        serial_code_prefix:{
            required: function(){
                return true;
                // return this.coupon_type === 'unique';
            }
        },
    },
    methods:{
        onNext(){
            const nextStep = 2;
            this.$emit('onNext', nextStep)
        },
        onToggleUniqueQR(value){
            this.uniqueQR = value;
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.couponOptions{
    /* margin-top: 4px; */
    display: flex;
    gap: 24px;
}

.submit_btns{
    margin-top: 24px;
}
</style>

<style>
.couponOptions .radio-item{
    gap: 0;
}
.couponOptions .radio_label_wrap{
    margin-bottom: 2px;
}
.couponOptions .radio_label{
    font-weight: 500;
    color: #304352;
}
.couponOptions .radio_description{
    font-weight: 500;
    color: #858585;
    font-size: 12px;
}
</style>
