<template>
  <div>
    <div class="search search_box">
        <div class="icon">
          <fluent-icon icon="search" class="search--icon" size="20" />
        </div>
        <input
            class="search--input search_input font_regular text_14 font_500"
            :placeholder="placeholder"
            :value="value"
            @input="onInput"
            @keyup.enter="onEnter"
        />
      </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search...'
    }
  },
  methods:{
    onInput(event) {
      // Emit the new value whenever the range changes
      this.$emit('input', event.target.value);
    },
    onEnter(event){
        this.$emit('search', event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.search_box{
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    padding: 10px 12px;
}
.icon{
    color: #858585;
    font-size: 0px;
}
.search_input{

}
</style>