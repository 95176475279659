/* eslint no-console: 0 */
/* global axios */
/* eslint no-undef: "error" */

import Cookies from 'js-cookie';
import endPoints from './endPoints';
import { setAuthCredentials, clearCookiesOnLogout } from '../store/utils/api';

export default {
  login(creds) {
    return new Promise((resolve, reject) => {
      axios
        .post('auth/sign_in', creds)
        .then(response => {
          // const planResponse = await AccountAPI.fetchAccountInbox();
          // let plan = planResponse.data || {};
          try {
            let userData = response.data?.data || null;
            if (userData) {
              window.dataLayer.push({
                user_email: userData.email || '',
                user_name: userData.name || '',
                ps_plan: userData.plan_id || 0,
                ps_account_id: userData.account_id || '',
                ps_stripe_user_id: userData.stripe_customer_id || '',
                ps_currency: userData.default_currency || '',
                ps_balance: userData.account_top_amount || 0,
                ps_total_topup: userData.total_top_amount || 0,
                onboard_limit: userData.onboard_limit || 0,
              });
              console.log('dataLayer Updated', window.dataLayer);
            }
          } catch (er) {
            console.log('Tag Error ', er);
          }

          if (response.data && response.data.data) {
            response.data.data.onboard_limit = response.data.data.onboard_limit || 0;
          }

          setAuthCredentials(response);
          resolve();
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },

  register(creds) {
    const urlData = endPoints('register');
    const fetchPromise = new Promise((resolve, reject) => {
      const reqBodyData = {
        account_name: creds.accountName.trim(),
        user_full_name: creds.fullName.trim(),
        email: creds.email,
        password: creds.password,
        currency: creds.currency,
        h_captcha_client_response: creds.hCaptchaClientResponse,
        // phone_number: creds.phone_number,
        appsumo_id: creds.appsumo_id,
        country: creds.country,
        tax_number: creds.tax_number,
      }
      if (creds.phone_number) {
        reqBodyData.phone_number = creds.phone_number;
      }
      if (creds.appsumo_id) {
        reqBodyData.appsumo_id = creds.appsumo_id;
      }
      if (creds.source) {
        reqBodyData.source = creds.source;
      }
      if (creds.buy_now_plan_id) {
        reqBodyData.buy_now_plan_id = creds.buy_now_plan_id;
      }

      axios
        .post(urlData.url, reqBodyData)
        .then(response => {
          try {
            let userData = response.data?.data || null;
            window.dataLayer.push({
              event: 'ps_reg',
              user_email: userData.email || '',
              user_name: userData.name || '',
              ps_account_id: userData.account_id || '',
              user_phone_number: userData.phone_number || '',
              ps_plan: userData.plan_id || 0,
            });
            console.log('dataLayer updated', window.dataLayer);
          } catch (er) {
            console.log('Tag Error ', er);
          }

          setAuthCredentials(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  validityCheck() {
    const urlData = endPoints('validityCheck');
    return axios.get(urlData.url);
  },
  logout() {
    const urlData = endPoints('logout');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .delete(urlData.url)
        .then(response => {
          clearCookiesOnLogout();

          // clear specific loacalstorage traking
          localStorage.clear("stripeOpenByNowPlan")

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },

  isLoggedIn() {
    const hasAuthCookie = !!Cookies.getJSON('auth_data');
    const hasUserCookie = !!Cookies.getJSON('user');
    return hasAuthCookie && hasUserCookie;
  },

  isAdmin() {
    if (this.isLoggedIn()) {
      return Cookies.getJSON('user').role === 'administrator';
    }
    return false;
  },

  getAuthData() {
    if (this.isLoggedIn()) {
      return Cookies.getJSON('auth_data');
    }
    return false;
  },
  getPubSubToken() {
    if (this.isLoggedIn()) {
      const user = Cookies.getJSON('user') || {};
      const { pubsub_token: pubsubToken } = user;
      return pubsubToken;
    }
    return null;
  },
  getCurrentUser() {
    if (this.isLoggedIn()) {
      return Cookies.getJSON('user');
    }
    return null;
  },

  verifyPasswordToken({ confirmationToken }) {
    return new Promise((resolve, reject) => {
      axios
        .post('auth/confirmation', {
          confirmation_token: confirmationToken,
        })
        .then(response => {
          setAuthCredentials(response);
          resolve(response);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },

  setNewPassword({ resetPasswordToken, password, confirmPassword }) {
    return new Promise((resolve, reject) => {
      axios
        .put('auth/password', {
          reset_password_token: resetPasswordToken,
          password_confirmation: confirmPassword,
          password,
        })
        .then(response => {
          setAuthCredentials(response);
          resolve(response);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },

  resetPassword({ email }) {
    const urlData = endPoints('resetPassword');
    return axios.post(urlData.url, { email });
  },

  profileUpdate({
    password,
    password_confirmation,
    displayName,
    avatar,
    ...profileAttributes
  }) {
    const formData = new FormData();
    Object.keys(profileAttributes).forEach(key => {
      const hasValue = profileAttributes[key] === undefined;
      if (!hasValue) {
        formData.append(`profile[${key}]`, profileAttributes[key]);
      }
    });
    formData.append('profile[display_name]', displayName || '');
    if (password && password_confirmation) {
      formData.append('profile[password]', password);
      formData.append('profile[password_confirmation]', password_confirmation);
    }
    if (avatar) {
      formData.append('profile[avatar]', avatar);
    }
    return axios.put(endPoints('profileUpdate').url, formData);
  },

  updateUISettings({ uiSettings }) {
    return axios.put(endPoints('profileUpdate').url, {
      profile: { ui_settings: uiSettings },
    });
  },

  updateAvailability(availabilityData) {
    return axios.post(endPoints('availabilityUpdate').url, {
      profile: { ...availabilityData },
    });
  },

  deleteAvatar() {
    return axios.delete(endPoints('deleteAvatar').url);
  },
  getCurrentProfile(){
    return axios.get(endPoints("profileGet").url)
  },
  verifyOTP(payload){
    return axios.post(endPoints("verifyOTP").url,payload)
  },
  createGoogleCalendar(formDAta) {
    // Google Calendar Create
    return axios.post(endPoints('createGoogleCalendar').url, formDAta);
  },
  checkValidAppsumouser(activation_email,uuid) {
    return axios.get(endPoints('appsumoCheck').url(activation_email,uuid));
  },
  checkValidGrantUser(activation_email) {
    return axios.get(endPoints('grantCheck').url(activation_email));
  },
};
