<template>
  <base-icon :size="size" :icon="icon" :type="type" :icons="icons" :viewBox="viewBox" />
</template>
<script>
import BaseIcon from './Icon';
import icons1 from './dashboard-icons.json';
import icons2 from './dashboard-icons2.json';

export default {
  name: 'FluentIcon',
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '20',
    },
    type: {
      type: String,
      default: 'outline',
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
  },
  data() {
    return {
      // icons: icons1
      icons:{
        ...icons1,
        ...icons2,
      }
    };
  },
};
</script>
