var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c(
        "div",
        [
          _c(
            "modal",
            {
              attrs: {
                show: _vm.isOpen,
                "on-close": _vm.closeModal,
                modalMainContainerClass: "botExpressModal",
                closeBtnClass: "modal_close_btn_left",
                closeIcon: "chevron-left"
              },
              on: {
                "update:show": function($event) {
                  _vm.isOpen = $event
                }
              }
            },
            [
              _c("div", { staticClass: "botExpressModal_container" }, [
                _c("div", { staticClass: "header" }, [
                  _c("h2", { staticClass: "header_title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("FLOW.EXPRESS_BOT.CREATE_MODAL.TITLE"))
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "video_icon text_purple_primary",
                      on: { click: _vm.openTutorial }
                    },
                    [_c("fluent-icon", { attrs: { icon: "video" } })],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "step_range" }, [
                  _c("p", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "FLOW.EXPRESS_BOT.CREATE_MODAL.PROGRESS.PART_1"
                          )
                        ) +
                        "\n                    " +
                        _vm._s(_vm.labels[_vm.current_step].step) +
                        "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "FLOW.EXPRESS_BOT.CREATE_MODAL.PROGRESS.PART_2"
                          )
                        ) +
                        "\n                    " +
                        _vm._s(_vm.totalSteps + 1) +
                        "\n                    " +
                        _vm._s(
                          _vm.$t(
                            "FLOW.EXPRESS_BOT.CREATE_MODAL.PROGRESS.PART_3"
                          )
                        ) +
                        "\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      id: "expressBot_step_range",
                      type: "range",
                      name: "volume",
                      min: 0,
                      max: _vm.totalSteps,
                      disabled: ""
                    },
                    domProps: { value: _vm.currentStepIndex }
                  })
                ]),
                _vm._v(" "),
                !_vm.isFinalStepQrModalOpen
                  ? _c("div", [
                      _c("section", { staticClass: "expressBot" }, [
                        _c(
                          "aside",
                          [
                            _c("h2", { staticClass: "main_title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.labels[_vm.current_step].formTitle)
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "main_description" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    _vm.labels[_vm.current_step].formDescription
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.currentForm.contents, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  item.type === "card"
                                    ? [
                                        _c("CardExressEdit", {
                                          attrs: {
                                            item: item,
                                            constraint: _vm.constraints.card,
                                            label:
                                              _vm.labels[_vm.current_step]
                                                .contents[index],
                                            actionButtonlabel:
                                              _vm.labels[_vm.current_step]
                                                .contents[index].actionButtons
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.type === "category_list"
                                    ? [
                                        _c("ListExpressEdit", {
                                          attrs: {
                                            constraint:
                                              _vm.constraints.category_list,
                                            item: item,
                                            label:
                                              _vm.labels[_vm.current_step]
                                                .contents[index],
                                            categoryOptions: _vm.categoryOptions
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.type === "text"
                                    ? [
                                        _c("TextExpressEdit", {
                                          attrs: {
                                            constraint: _vm.constraints.text,
                                            item: item,
                                            label:
                                              _vm.labels[_vm.current_step]
                                                .contents[index],
                                            form_nodes: _vm.form_nodes
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.type === "image"
                                    ? [
                                        _c("ImageExpressEdit", {
                                          attrs: {
                                            constraint: _vm.constraints.image,
                                            item: item,
                                            label:
                                              _vm.labels[_vm.current_step]
                                                .contents[index]
                                          }
                                        })
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "aside",
                          [
                            _c("ExpressPreview", {
                              attrs: {
                                currentForm: _vm.formattedCurrentForm,
                                constraints: _vm.constraints
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("footer", { staticClass: "row submit_btns" }, [
                        _c(
                          "aside",
                          { staticClass: "medium-6 columns" },
                          [
                            _vm.isLastLabel
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "form_label font_regular text_12",
                                        staticStyle: { "margin-bottom": "4px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$t(
                                                "FLOW.EXPRESS_BOT.CREATE_MODAL.LABELS.BOT_INBOX"
                                              )
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("multiselect", {
                                      staticClass:
                                        "column small-12 express_bot_multiselect",
                                      attrs: {
                                        options: _vm.whatsAppInboxList,
                                        "track-by": "id",
                                        label: "name",
                                        multiple: false,
                                        "close-on-select": true,
                                        "clear-on-select": false,
                                        "hide-selected": true,
                                        placeholder: _vm.$t(
                                          "MESSAGE_MGMT.ADD.FORM.INBOX_PLACEHOLDER"
                                        ),
                                        "selected-label": "",
                                        "select-label": _vm.$t(
                                          "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                        ),
                                        "deselect-label": _vm.$t(
                                          "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                                        )
                                      },
                                      on: {
                                        select: function($event) {
                                          return _vm.onSelectInbox($event)
                                        }
                                      },
                                      model: {
                                        value: _vm.selectedInbox,
                                        callback: function($$v) {
                                          _vm.selectedInbox = $$v
                                        },
                                        expression: "selectedInbox"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isLastLabel
                              ? _c(
                                  "woot-button",
                                  {
                                    attrs: {
                                      "class-names": "tmp_submit",
                                      "is-loading": _vm.isSubmitting
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onNextHandler(false)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$t(
                                            "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.NEXT"
                                          )
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isLastLabel
                              ? _c(
                                  "woot-button",
                                  {
                                    attrs: {
                                      "class-names": "tmp_submit",
                                      "is-loading": _vm.isSubmitting
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onNextHandler(true)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$t(
                                            "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.SUBMIT"
                                          )
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isPreviousAvailable
                              ? _c(
                                  "woot-button",
                                  {
                                    attrs: {
                                      "class-names": "tmp_submit",
                                      variant: "clear"
                                    },
                                    on: { click: _vm.onPreviousHandler }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.$t(
                                            "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.PREVIOUS"
                                          )
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              : _c(
                                  "woot-button",
                                  {
                                    attrs: {
                                      "class-names": "tmp_submit",
                                      variant: "clear"
                                    },
                                    on: { click: _vm.closeModal }
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.CANCEL"
                                          )
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isFinalStepQrModalOpen
                  ? _c("div", [
                      _c(
                        "div",
                        [
                          _c("QRCode", {
                            attrs: { shop_url: _vm.whatsappUrl, isOnlyQR: true }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "complete_step" }, [
                        _c("div", { staticClass: " header info" }, [
                          _c("h2", { staticClass: "header_title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "FLOW.EXPRESS_BOT.CREATE_MODAL.TEST_TITLE"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "description" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "FLOW.EXPRESS_BOT.CREATE_MODAL.TEST_DESCRIPTION"
                                )
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "btns" },
                          [
                            _c(
                              "woot-button",
                              {
                                attrs: { "class-names": "tmp_submit" },
                                on: { click: _vm.onTryChatbotClick }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.TRY"
                                      )
                                    ) +
                                    "\n                        "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "woot-button",
                              {
                                attrs: {
                                  "class-names": "tmp_submit",
                                  variant: "clear"
                                },
                                on: { click: _vm.closeModal }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.CLOSE"
                                      )
                                    ) +
                                    "\n                        "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }