var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box page_container" },
    [
      _c("section", [
        _c(
          "div",
          { staticClass: "search-wrap filter_item" },
          [
            _c("fluent-icon", {
              staticClass: "search-icon",
              attrs: { icon: "search" }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchText,
                  expression: "searchText"
                }
              ],
              staticClass: "search_field font_normal",
              attrs: {
                type: "text",
                placeholder: _vm.$t("ORDER.LIST_TABLE.SEARCH_PLACEHOLDER")
              },
              domProps: { value: _vm.searchText },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.searchOrders($event)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchText = $event.target.value
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "filter_items header_filter" }, [
        _c(
          "div",
          { staticClass: "filter_item" },
          [
            _c("label", { staticClass: "font_regular text_12" }, [
              _vm._v(_vm._s(_vm.$t("ORDER.LIST_TABLE.DATE.LABEL")))
            ]),
            _vm._v(" "),
            _c("date-range-picker", {
              staticClass: "font_normal order_start_date",
              attrs: {
                "confirm-text": _vm.$t(
                  "ORDER.LIST_TABLE.DATE_SELECT_CONFIRM_BTN"
                ),
                placeholder: _vm.$t("ORDER.LIST_TABLE.DATE.PLACEHOLDER"),
                value: _vm.dateRange,
                disabledDate: _vm.disableFilterDate
              },
              on: { change: _vm.handleDateChange }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter_item" },
          [
            _c("label", { staticClass: "font_regular text_12" }, [
              _vm._v(_vm._s(_vm.$t("ORDER.LIST_TABLE.STATUS.LABEL")))
            ]),
            _vm._v(" "),
            _c("multiselect", {
              staticClass: "small-12 font_normal order_multi_select",
              attrs: {
                options: _vm.OrderStatusList,
                "track-by": "id",
                label: "title",
                multiple: true,
                "close-on-select": false,
                "clear-on-select": false,
                "hide-selected": true,
                placeholder: _vm.$t("ORDER.LIST_TABLE.STATUS.PLACEHOLDER"),
                "selected-label": "",
                "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                "deselect-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_REMOVE")
              },
              on: {
                select: function($event) {
                  return _vm.applyFilterByStatuses($event)
                },
                remove: function($event) {
                  return _vm.applyFilterRemoveStatus($event)
                }
              },
              model: {
                value: _vm.selectedOrderStatuses,
                callback: function($$v) {
                  _vm.selectedOrderStatuses = $$v
                },
                expression: "selectedOrderStatuses"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter_item", staticStyle: { "max-width": "100%" } },
          [
            _c("label", { staticClass: "font_regular text_12" }, [
              _vm._v(_vm._s(_vm.$t("ORDER.LIST_TABLE.CUSTOM_COLUMN.LABEL")))
            ]),
            _vm._v(" "),
            _c("multiselect", {
              staticClass: "small-12 font_normal order_multi_select",
              staticStyle: { width: "auto" },
              attrs: {
                options: _vm.cols,
                "track-by": "key",
                label: "name",
                multiple: true,
                "close-on-select": false,
                "clear-on-select": false,
                "hide-selected": true,
                placeholder: _vm.$t(
                  "ORDER.LIST_TABLE.CUSTOM_COLUMN.PLACEHOLDER"
                ),
                "selected-label": "",
                "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                "deselect-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_REMOVE")
              },
              on: {
                select: function($event) {
                  return _vm.applyFilterSelectedCol($event)
                },
                remove: function($event) {
                  return _vm.applyFilterSelectedCol($event)
                }
              },
              model: {
                value: _vm.selectedCols,
                callback: function($$v) {
                  _vm.selectedCols = $$v
                },
                expression: "selectedCols"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "order_container" },
        [
          _c("order-table", {
            attrs: {
              orders: _vm.orders,
              cols: _vm.cols,
              isLoading: _vm.uiFlags.isFetching,
              catalog: _vm.catalog,
              onClickOrder: _vm.onClickOrder,
              onSelectOrderStatus: _vm.onOrderStatusChange
            },
            on: { "on-sort-change": _vm.onSortChange }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "loadMore_btn" },
            [
              _c(
                "woot-button",
                {
                  attrs: {
                    variant: "clear",
                    size: "small",
                    "color-scheme": "primary",
                    "is-loading": _vm.uiFlags.isFetching,
                    "is-disabled": _vm.paginationCompute.isLastPage
                  },
                  on: {
                    click: function($event) {
                      _vm.onPageChange(Number(_vm.meta.currentPage) + 1)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.paginationCompute.btnText) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showSelectColumnModal,
            "on-close": _vm.onCloseSelectColumn
          },
          on: {
            "update:show": function($event) {
              _vm.showSelectColumnModal = $event
            }
          }
        },
        [
          _c("SelectColumnModal", {
            attrs: {
              "on-close": _vm.onCloseSelectColumn,
              columns: _vm.cols,
              handleColumnVisiblity: _vm.toggleColSelect
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("SchedulePickUpModal", {
        attrs: {
          onClose: _vm.onPichupModalClose,
          show: _vm.isPickupModalShow,
          order: _vm.selectedOrderForSchedule
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }