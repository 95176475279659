<template>
  <div class="stepBaseForm">
    <div class="campaign_heading">
        <!-- <h2>{{ $t('COUPON.ADD_FORM.HEADER.TITLE') }}</h2> -->
        <h2>{{ title }}</h2>
        <woot-button class="" icon='dismiss' variant="clear" colorScheme="secondary" @click="onClose" />
    </div>
    <div class="step_range_continer">
        <p>
            {{range_label}}
            <!-- {{step}}
            {{ $t('COUPON.ADD_FORM.HEADER.STEP_OF') }}
            {{max_steps}}
            {{ $t('COUPON.ADD_FORM.HEADER.STEP_REMAINING') }} -->
        </p>
        <RangeInput :disabled="true" :min="0" :max="max_steps" :value="current_step" :id="'range_step'" />
    </div>
    <div>
        <slot></slot>
    </div>
  </div>
</template>

<script>
import RangeInput from "../../../components/ui/RangeInput.vue"

export default {
     components:{
        RangeInput,
    },
    props:{
        title:{
            default:""
        },
        range_label:{
            default:""
        },
        current_step:{
            type: Number,
            default: 0
        },
        max_steps:{
            type: Number,
            default: 0
        },
    },
    data(){
        return {
            step: 2,
            // max_steps: 5,
        }
    },
    methods:{
        onClose(){
            this.$emit('onClose')
        },
        // end method
    }
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.stepBaseForm{
    max-width: 640px;
    margin: auto;
    // margin-top: 32px;
}
.campaign_heading{
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2{
        font-weight: 600;
        font-size: 20px;
        line-height: 1.4;
        font-family: 'Figtree';
        color: #1F1F1F;
    }
}

.step_range_continer{
    margin-top: 16px;
    margin-bottom: 24px;
    p{
        font-weight: 500;
        font-size: 14px;
        line-height: 1.4;
        font-family: 'Figtree';
        color: #1F1F1F;
    }
}
</style>
