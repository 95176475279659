<template>
    <ModalTemplate
        :isOpen="isOpen"
        :on-close="onClose"
        modalMainContainerClass="addCategoryModal"
        closeBtnClass="modal_close_btn_left"
        closeIcon="chevron-left"
        :title="$t('CATALOGUE.CATEGORY.MODAL.HEADER')"
    >
    <div class="mt_20" @click.prevent="preventClickPropagation">
        <div>
            <InputField
                v-model="display_name"
                :label="$t('CATALOGUE.CATEGORY.MODAL.DISPLAY_NAME.LABEL')"
                :placeholder="$t('CATALOGUE.CATEGORY.MODAL.DISPLAY_NAME.PLACEHOLDER')"
                :error="$v.display_name.$error ? $t('CATALOGUE.CATEGORY.MODAL.DISPLAY_NAME.ERROR') : ''"
                :infoList="[]"
                @blur="$v.display_name.$touch"
            />

            <MultiselectDropdown
                :label="$t('CATALOGUE.CATEGORY.MODAL.MAIN_CATAGORY.LABEL')"
                :infoList="[]"
                :error="$v.category_list.$error ? $t('CATALOGUE.CATEGORY.MODAL.MAIN_CATAGORY.ERROR') : ''"
            >
                <multiselect
                  class="column small-12"
                  v-model="category_list"
                  :options="category_list_options"
                  track-by="id"
                  label="title"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :hide-selected="true"
                  :placeholder="$t('CATALOGUE.TOOLBAR.CATAGORY_PLACEHOLDER')"
                  selected-label
                  :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                  :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                  @select="onCategoryListSelect"
                />
            </MultiselectDropdown>

            <MultiselectDropdown
                :label="$t('CATALOGUE.CATEGORY.MODAL.SUB_CATAGORY.LABEL')"
                :infoList="[]"
                :error="$v.sub_category.$error ? $t('CATALOGUE.CATEGORY.MODAL.SUB_CATAGORY.ERROR') : ''"
            >
                <multiselect
                  class="column small-12"
                  v-model="sub_category"
                  :options="sub_category_options"
                  track-by="id"
                  label="title"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :hide-selected="true"
                  :placeholder="$t('CATALOGUE.TOOLBAR.CATAGORY_PLACEHOLDER')"
                  selected-label
                  :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                  :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                />
            </MultiselectDropdown>
        </div>
        <div class="">
            <woot-button
                :disabled="uiFlags.isCreating_catagories"
                :isLoading="uiFlags.isCreating_catagories"
                @click="createCategory"
            >
                {{$t('CATALOGUE.CATEGORY.MODAL.BTN')}}
            </woot-button>
        </div>
    </div>
    </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import Spinner from 'shared/components/Spinner.vue';
import ModalTemplate from '../../../../../dashboard/components/ModalTemplate.vue';
import MultiselectDropdown from "../../../../components/ui/inputs/MultiselectDropdown.vue"
import InputField from "../../../../components/ui/inputs/InputField.vue"

export default {
    components: {
        ModalTemplate,
        Spinner,
        MultiselectDropdown,
        InputField,
    },
    mixins: [alertMixin],
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
    },
    data(){
        return {
            display_name: "",
            category_list: null,
            sub_category: null,
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'getCurrentUser',
            uiFlags: "catalog/getUIFlags",
            categoryGroups: "catalog/getCategoryGroups",
        }),
        category_list_options(){
            const list = this.categoryGroups.map((el)=>{
                return {
                    id: el.main_category,
                    title: el.main_category,
                }
            })
            return list;
        },
        sub_category_options(){
            const categoryItem = this.categoryGroups.find(el=>el.main_category === this.category_list?.id);
            if (categoryItem) {
                const list = categoryItem.sub_categories.map((el)=>{
                return {
                    id: el,
                    title: el,
                }
            })
            return list;
            }else{
                return []
            }
        },
    },
    validations:{
        display_name:{
            required
        },
        category_list:{
            required
        },
        sub_category:{
            required
        },
    },
    mounted() {
        this.$store.dispatch("catalog/getCatagoryGroups");
    },
    methods:{
        async createCategory() {
            try {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return
                }
                const newCategoryPayload = {
                    display_name: this.display_name,
                    category_list: this.category_list.id,
                    title: this.sub_category.id,
                };
                await this.$store.dispatch("catalog/createCategory",newCategoryPayload)
                this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.CREATE_SUCCESS'));
                this.onClose();
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.CREATE_FAIL'));
            }
        },
        preventClickPropagation(){},
        onCategoryListSelect(){
            this.sub_category = null;
        },
    },
}
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/widgets/_fonts.scss';

</style>

<style>
.addCategoryModal{
    width: 500px;
}
.addCategoryModal,
.addCategoryModal .modal_content_wrap,
.addCategoryModal .template_modal_body {
    overflow: visible;
}
</style>
