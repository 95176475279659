<template>
<div>
  <article v-if="campaign_type === 'one_off' && eligible_audience.isFetched" class="audience_container">
        <div>
            <p class="font_regular font_500  text_14">
                {{ $t('CAMPAIGN.ADD_STEP.ONE_OFF.ELIGIBLE_AUDIENCE') }}
            </p>
            <div style="margin-top:4px;">
                <span class="font_500 text_16 text_black_primary">
                    {{eligible_audience.audience}}
                </span>
                <span class="font_500 text_12 text_black_secondary">
                    {{eligible_audience.costs}}
                    {{$t('CAMPAIGN.ADD_STEP.ONE_OFF.CREDIT')}}
                </span>
            </div>
        </div>
        <div>
            <p class="font_regular font_500  text_14">{{ $t('CAMPAIGN.ADD_STEP.ONE_OFF.MAX_AUDIENCE') }}</p>
            <div class="topup_box">

                <div v-if="isBroadcasetLimitOpen" class="topup">
                    <input
                        v-model="broadcast_limit"
                        class="font_regular font_500 text_14 "
                        type="number"
                        :min="0"
                        :max="eligible_audience.max_audience"
                        @input="validateBroadcastLimitInput"
                    />
                        <span class="font_regular font_500 text_14 text_black_secondary">
                            /{{eligible_audience.max_audience}}
                        </span>
                </div>
                <span v-else class="font_regular font_500  text_16">
                    {{eligible_audience.max_audience}}
                </span>
                <div class="available_credit_box">
                    <span class="available_credit font_regular font_500  text_12">{{$t('CAMPAIGN.ADD_STEP.ONE_OFF.AVAILABLE_CREDIT')}}
                    {{eligible_audience.available_credit}}
                    </span>
                </div>
            </div>
            <p class="audience_note font_regular font_500  text_14">{{ $t('CAMPAIGN.ADD_STEP.ONE_OFF.AUDIENCE_NOTE') }}</p>
            <div
            v-if="eligible_audience.isAllowModify"
            class="campaign_btns" style="margin:0;margin-top:16px;">
                <woot-button
                class="campaign_btn"
                icon="add"
                @click="openTopup"
                >
                {{ $t('CAMPAIGN.ADD_STEP.BTNS.TOPUP') }}
                </woot-button>
                <woot-button
                class="campaign_btn"
                variant="clear"
                @click="onReduceAudience"
                >
                {{ $t('CAMPAIGN.ADD_STEP.BTNS.REDUCE_AUDIENCE') }}
                </woot-button>
            </div>
        </div>
    </article>
    <article v-else-if="campaign_type === 'running' && eligible_audience.isFetched" class="audience_container">
        <div>
            <p class="font_regular font_500  text_14">
                {{ $t('CAMPAIGN.ADD_STEP.ONE_OFF.ELIGIBLE_AUDIENCE') }}
            </p>
            <div class="credit_purple_box">
                <div>
                    <span class="font_500 text_16 text_black_primary">
                        {{eligible_audience.audience}}
                    </span>
                    <span class="font_500 text_12 text_black_secondary">
                        {{eligible_audience.costs}}
                        {{$t('CAMPAIGN.ADD_STEP.ONE_OFF.CREDIT')}}
                    </span>
                </div>
                <div class="available_credit_box">
                    <span class="available_credit available_credit_purple font_regular font_500  text_12">
                        {{$t('CAMPAIGN.ADD_STEP.ONE_OFF.AVAILABLE_CREDIT')}}
                        {{eligible_audience.available_credit}}
                    </span>
                </div>
            </div>
        </div>
    </article>
    <StripeComponent
        :modal-value="isTopupModal"
        @close-modal="closeTopup"
    />
</div>
</template>

<script>
import StripeComponent from 'dashboard/components/stripe/StripeComponent';
import alertMixin from 'shared/mixins/alertMixin';
import CampaignAPI from "../../../../api/campaigns"

export default {
    components:{
        StripeComponent,
    },
    mixins: [alertMixin],
    props:{
        campaign_type:{
            default: ""
        },
        broadcast_limit:{
            default: 0
        },
        eligible_audience:{
            default: {
                audience: 0,
                costs: 0,
                max_audience: 0,
                available_credit: 0,
                isFetched: false,
            }
        },
        getCampaignDetails:{
            default: ()=>{}
        },
        onChangeBroadcastLimit:{
            default: ()=>{}
        },
    },
    data(){
        return {
            isBroadcasetLimitOpen: false,
            isTopupModal: false,
        }
    },
    methods:{
        validateBroadcastLimitInput(){
            const new_broadcast_limit = Math.max(0,
                Math.min(
                    Math.floor(this.broadcast_limit), // Ensure it's an integer
                    this.eligible_audience.max_audience // Ensure it's within range
                )
            );
            this.onChangeBroadcastLimit(new_broadcast_limit);
        },
        toggleBroadCaesLimitField(){
            this.isBroadcasetLimitOpen = true;
        },
        openTopup(){
            this.isTopupModal = true;
        },
        closeTopup(){
            this.isTopupModal = false;
        },
        async onReduceAudience(){
            console.log(this.broadcast_limit);
            if (!this.isBroadcasetLimitOpen) {
                this.isBroadcasetLimitOpen = true;
                return
            }
            try {
                // only if data change
                // if (this.broadcast_limit === this.eligible_audience.max_audience) {
                //     this.showAlert(this.$t('CAMPAIGN.ADD_STEP.BROADCASE_LIMIT_MSG.NOT_CHANGE'));
                //     return
                // }
                // const payload = this.getCampaignDetails();
                // payload.broadcast_limit = this.broadcast_limit;

                // const {data} = await CampaignAPI.check_eligible_audience(payload);
                this.isBroadcasetLimitOpen = false;
                // this.showAlert(this.$t('CAMPAIGN.ADD_STEP.BROADCASE_LIMIT_MSG.SUCCESS'));
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('CAMPAIGN.ADD_STEP.BROADCASE_LIMIT_MSG.FAIL'));
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.audience_container{
    display: flex;
    gap: 20px;
    border-radius: 10px;
    padding: 16px;
    width: fit-content;
    background-color: #F2E7FE;
    div{
        &:nth-child(1){
            max-width: 250px;
        }
        &:nth-child(2){
            max-width: 320px;
        }
    }
}


.credit_purple_box{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
}
.available_credit_box{
    display: flex;
    align-items: center;
}
.available_credit{
    display: inline-block;
    border-radius: 20px;
    padding: 4px 12px;
    background-color: #FFECB2;
    color: #996600;
}
.available_credit_purple{
    background-color: #E5CEFD;
    color: #8D25F6;
}


.topup_box{
    display: flex;
    gap: 8px;
}
.topup{
    display: flex;
    align-items: center;
    gap: 4px;
    background: #fff;
    width: fit-content;
    padding: 10px 8px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    input{
        width: 100px;
        margin: 0;
        padding: 0;
        height: auto;
        border: none;
        text-align: right;
    }
}

</style>