var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "multiselect",
        {
          staticClass: "category_list",
          attrs: {
            value: _vm.value,
            options: _vm.formattedOptions,
            "track-by": "id",
            label: "title",
            multiple: true,
            "close-on-select": false,
            "clear-on-select": false,
            "hide-selected": true,
            placeholder: _vm.$t("CATALOGUE.TOOLBAR.CATAGORY_PLACEHOLDER"),
            "selected-label": "",
            "select-label": "",
            "deselect-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_REMOVE")
          },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event)
            },
            select: _vm.onSelect,
            remove: function($event) {
              return _vm.$emit("remove", $event)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function(ref) {
                var option = ref.option
                return [
                  !option.$isLabel
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "ps_multiselect_option font_regular font_500 text_14"
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(option.title) +
                                "\n                  "
                            ),
                            _c(
                              "small",
                              {
                                staticClass: "text_black_secondary",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteCategory(option)
                                  }
                                }
                              },
                              [
                                _c("fluent-icon", {
                                  staticClass: "order_hint_mute",
                                  attrs: { icon: "delete" }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  option.$isLabel
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "ps_multiselect_group font_regular font_500 text_12"
                        },
                        [_c("span", [_vm._v(_vm._s(option.$groupLabel))])]
                      )
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "beforeList" }, [
            _c(
              "div",
              {
                staticClass:
                  "multiselect__option multiselect__option--group font_500 text_14 text_white create_option",
                on: { click: _vm.onAddModalOpen }
              },
              [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("CATALOGUE.CATEGORY.CREATE_OPTION")) +
                    "\n          "
                )
              ]
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("AddCategoryModal", {
        attrs: { onClose: _vm.onAddModalClose, isOpen: _vm.isAddModalOpen }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }