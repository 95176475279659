var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order_topup_warn_msg
    ? _c(
        "section",
        {
          staticClass: "top_notification_container",
          class: _vm.responsiveClass
        },
        [
          _c("div", { staticClass: "side_bar" }),
          _vm._v(" "),
          _c("div", { staticClass: "outlet" }, [
            _c("p", { staticClass: "order_credit_msg" }, [
              _vm._v(_vm._s(_vm.order_topup_warn_msg))
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }