/* eslint arrow-body-style: ["error", "always"] */

const endPoints = {
  resetPassword: {
    url: 'auth/password',
  },
  register: {
    url: 'api/v1/accounts.json',
  },
  validityCheck: {
    url: '/auth/validate_token',
  },
  profileUpdate: {
    url: '/api/v1/profile',
  },
  profileGet: {
    url: '/api/v1/profile',
  },
  verifyOTP: {
    url: '/api/v1/profile/verify_otp',
  },
  availabilityUpdate: {
    url: '/api/v1/profile/availability',
  },
  logout: {
    url: 'auth/sign_out',
  },

  me: {
    url: 'api/v1/conversations.json',
    params: { type: 0, page: 1 },
  },

  getInbox: {
    url: 'api/v1/conversations.json',
    params: { inbox_id: null },
  },

  fetchFacebookPages: {
    url(accountId) {
      return `api/v1/accounts/${accountId}/callbacks/facebook_pages.json`;
    },
    params: { omniauth_token: '' },
  },

  deleteAvatar: {
    url: '/api/v1/profile/avatar',
  },
  createGoogleCalendar: {
    url: 'api/v1/calendar/create',
  },
  subscriptionPlans: {
    url(accountId) {
      return `accounts/${accountId}/settings/subscription-plans`;
    },
  },
  appsumoCheck: {
    url(activation_email,uuid) {
      return `/api/v1/appsumo_check?activation_email=${decodeURIComponent(activation_email)}&uuid=${uuid}`;
    },
  },
  grantCheck: {
    url(email) {
      return `/api/v1/appsumo_is_grant?email=${email}`;
    },
  },
  appsumoPlanChange:{
    url(invoice_item_uuid){
      return ` https://appsumo.com/account/redemption/${invoice_item_uuid}#change-plan`;
    },
  },
  whatsappAccountSetup:{
    url(account_id){
      return `/app/accounts/${account_id}/settings/inboxes/new/whatsapp`;
    },
  },
  onboardingSession:{
    url(agent_id){
      return `https://schedule.peasy.ai/book/user/${agent_id}/onboarding`;
    },
  },
  contestEvent:{
    url(){
      return `/api/v1/contests`;
    },
    joinContest: '/api/v1/contests/joins_contest'
  },

};

export default page => {
  return endPoints[page];
};
