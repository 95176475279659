var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns profile--settings" }, [
    !_vm.uiFlags.isFetchingItem
      ? _c("section", [
          _c("div", { staticClass: "small-12 row profile--settings--row" }, [
            _c("div", { staticClass: "setting_header" }, [
              _c("div", { staticClass: "columns small-12 medium-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "logo_container",
                    on: { click: _vm.triggerShopLogoInput }
                  },
                  [
                    _c("img", {
                      staticClass: "logo",
                      attrs: {
                        src:
                          _vm.newUploadedLogoPreview || _vm.merchant.logo_url,
                        alt: ""
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "logo_hint font_regular font_500 text_12"
                      },
                      [_vm._v("Click to upload your logo")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      ref: "shopLogoInput",
                      staticStyle: { display: "none" },
                      attrs: { type: "file", accept: "image/*" },
                      on: { change: _vm.handleShopLogoChange }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.merchant.shop_url
                ? _c(
                    "div",
                    {
                      staticClass: "columns small-12 medium-12 shop_qr_continer"
                    },
                    [
                      _c("QRCode", {
                        attrs: { shop_url: _vm.merchant.shop_url }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "columns small-12 medium-12 shop_setting" },
              [
                _c("div", { staticClass: "row label_wrapper" }, [
                  _c(
                    "label",
                    {
                      staticClass: "columns small-12 medium-6 label_left",
                      class: { error: _vm.$v.name.$error }
                    },
                    [
                      _vm._v(
                        "\n                      " +
                          _vm._s(
                            _vm.$t("CATALOGUE.SETTING.FIELDS.COMPANY_LABLE")
                          ) +
                          "\n                      "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "CATALOGUE.SETTING.FIELDS.COMPANY_LABLE"
                          )
                        },
                        domProps: { value: _vm.name },
                        on: {
                          blur: _vm.$v.name.$touch,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.name = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.name.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n                          " +
                                _vm._s(
                                  _vm.$t("CATALOGUE.SETTING.FIELDS.COMPANY_ERR")
                                ) +
                                "\n                      "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "columns small-12 medium-6 label_right" },
                    [
                      _vm._v(
                        "\n                      " +
                          _vm._s(
                            _vm.$t("CATALOGUE.SETTING.FIELDS.DESCRIPTION_LABLE")
                          ) +
                          "\n                      "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.custom_attributes.description,
                            expression: "custom_attributes.description"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "CATALOGUE.SETTING.FIELDS.DESCRIPTION_PLACEHOLDER"
                          )
                        },
                        domProps: { value: _vm.custom_attributes.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.custom_attributes,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "columns small-12 medium-6 label_left",
                      class: {
                        error: _vm.$v.custom_attributes.address.line1.$error
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex_between",
                          staticStyle: { height: "17px" }
                        },
                        [
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                _vm.$t(
                                  "CATALOGUE.SETTING.FIELDS.ADDRESS_1_LABLE"
                                )
                              ) +
                              "\n                          "
                          ),
                          _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: { click: _vm.openGoogleMapModal }
                              },
                              [
                                _c("fluent-icon", {
                                  attrs: { icon: "location" }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.custom_attributes.address.line1,
                            expression: "custom_attributes.address.line1"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "CATALOGUE.SETTING.FIELDS.ADDRESS_1_PLACEHOLDER"
                          )
                        },
                        domProps: {
                          value: _vm.custom_attributes.address.line1
                        },
                        on: {
                          blur: _vm.$v.custom_attributes.address.line1.$touch,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.custom_attributes.address,
                              "line1",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.custom_attributes.address.line1.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n                          " +
                                _vm._s(
                                  _vm.$t(
                                    "CATALOGUE.SETTING.FIELDS.ADDRESS_1_ERR"
                                  )
                                ) +
                                "\n                      "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "columns small-12 medium-6 label_right" },
                    [
                      _vm._v(
                        "\n                      " +
                          _vm._s(
                            _vm.$t("CATALOGUE.SETTING.FIELDS.ADDRESS_2_LABLE")
                          ) +
                          "\n                      "
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.custom_attributes.address.line2,
                            expression: "custom_attributes.address.line2"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "CATALOGUE.SETTING.FIELDS.ADDRESS_2_PLACEHOLDER"
                          )
                        },
                        domProps: {
                          value: _vm.custom_attributes.address.line2
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.custom_attributes.address,
                              "line2",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "columns small-12 medium-6 label_left",
                      class: {
                        error: _vm.$v.custom_attributes.address.city.$error
                      }
                    },
                    [
                      _vm._v(
                        "\n                      " +
                          _vm._s(
                            _vm.$t("CATALOGUE.SETTING.FIELDS.CITY_LABLE")
                          ) +
                          "\n                      "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.custom_attributes.address.city,
                            expression: "custom_attributes.address.city"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "CATALOGUE.SETTING.FIELDS.CITY_PLACEHOLDER"
                          )
                        },
                        domProps: { value: _vm.custom_attributes.address.city },
                        on: {
                          blur: _vm.$v.custom_attributes.address.city.$touch,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.custom_attributes.address,
                              "city",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.custom_attributes.address.city.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n                          " +
                                _vm._s(
                                  _vm.$t("CATALOGUE.SETTING.FIELDS.CITY_ERR")
                                ) +
                                "\n                      "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "columns small-12 medium-6 label_right",
                      class: {
                        error: _vm.$v.custom_attributes.address.state.$error
                      }
                    },
                    [
                      _vm._v(
                        "\n                      " +
                          _vm._s(
                            _vm.$t("CATALOGUE.SETTING.FIELDS.STATE_LABLE")
                          ) +
                          "\n                      "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.custom_attributes.address.state,
                            expression: "custom_attributes.address.state"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "CATALOGUE.SETTING.FIELDS.STATE_PLACEHOLDER"
                          )
                        },
                        domProps: {
                          value: _vm.custom_attributes.address.state
                        },
                        on: {
                          blur: _vm.$v.custom_attributes.address.state.$touch,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.custom_attributes.address,
                              "state",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.custom_attributes.address.state.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n                          " +
                                _vm._s(
                                  _vm.$t("CATALOGUE.SETTING.FIELDS.STATE_ERR")
                                ) +
                                "\n                      "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "columns small-12 medium-6 label_left" },
                    [
                      _vm._v(
                        "\n                      " +
                          _vm._s(
                            _vm.$t("CATALOGUE.SETTING.FIELDS.POSTCODE_LABLE")
                          ) +
                          "\n                      "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.custom_attributes.address.postcode,
                            expression: "custom_attributes.address.postcode"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "CATALOGUE.SETTING.FIELDS.POSTCODE_PLACEHOLDER"
                          )
                        },
                        domProps: {
                          value: _vm.custom_attributes.address.postcode
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.custom_attributes.address,
                              "postcode",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "columns small-12 medium-6 label_right",
                      class: { error: _vm.$v.phone_number.$error }
                    },
                    [
                      _vm._v(
                        "\n                      " +
                          _vm._s(
                            _vm.$t(
                              "CATALOGUE.SETTING.FIELDS.PHONE_NUMBER_LABLE"
                            )
                          ) +
                          "\n                      "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.phone_number,
                            expression: "phone_number"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "CATALOGUE.SETTING.FIELDS.PHONE_NUMBER_PLACEHOLDER"
                          )
                        },
                        domProps: { value: _vm.phone_number },
                        on: {
                          blur: _vm.$v.phone_number.$touch,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.phone_number = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.phone_number.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n                          " +
                                _vm._s(
                                  _vm.$t(
                                    "CATALOGUE.SETTING.FIELDS.PHONE_NUMBER_ERR"
                                  )
                                ) +
                                "\n                      "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "group_section toggle_field columns small-12 medium-6 label_left"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "toggle-button",
                          class: { active: _vm.custom_attributes.sst },
                          attrs: {
                            type: "button",
                            role: "switch",
                            "aria-checked": _vm.custom_attributes.sst.toString()
                          },
                          on: {
                            click: function($event) {
                              _vm.custom_attributes.sst = !_vm.custom_attributes
                                .sst
                            }
                          }
                        },
                        [
                          _c("span", {
                            class: { active: _vm.custom_attributes.sst },
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "text_14" }, [
                        _vm._v(
                          _vm._s(_vm.$t("CATALOGUE.SETTING.FIELDS.SST_LABLE"))
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "group_section toggle_field columns small-12 medium-6 label_right"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "toggle-button",
                          class: {
                            active: _vm.custom_attributes.payment_before_order
                          },
                          attrs: {
                            type: "button",
                            disabled: !_vm.tekkis_hook_configured,
                            role: "switch",
                            "aria-checked": _vm.custom_attributes.payment_before_order.toString()
                          },
                          on: {
                            click: function($event) {
                              _vm.custom_attributes.payment_before_order = !_vm
                                .custom_attributes.payment_before_order
                            }
                          }
                        },
                        [
                          _c("span", {
                            class: {
                              active: _vm.custom_attributes.payment_before_order
                            },
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "text_14" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "CATALOGUE.SETTING.FIELDS.PAYMENT_BEFORE_ORDER_LABLE"
                            )
                          )
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "group_section toggle_field columns small-12 medium-6 label_left"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "toggle-button",
                          class: { active: _vm.custom_attributes.delivery },
                          attrs: {
                            type: "button",
                            role: "switch",
                            "aria-checked": _vm.custom_attributes.delivery.toString()
                          },
                          on: { click: _vm.deliveryAllowToggle }
                        },
                        [
                          _c("span", {
                            class: { active: _vm.custom_attributes.delivery },
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "text_14" }, [
                        _vm._v(
                          "\n                          " +
                            _vm._s(
                              _vm.$t(
                                "CATALOGUE.SETTING.FIELDS.DELIVERY_ADDRESS_LABLE"
                              )
                            ) +
                            "\n                          "
                        ),
                        _c(
                          "span",
                          { staticClass: "tooltip_info" },
                          [
                            _c("fluent-icon", {
                              staticClass: "help_icon",
                              attrs: { icon: "question-circle" }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "tooltip_card",
                                staticStyle: { bottom: "18px" }
                              },
                              [
                                _c("p", { staticClass: "mb_0 font_12" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "CATALOGUE.SETTING.FIELDS.ALLOW_DELIVERY_HINT"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.custom_attributes.delivery
                  ? _c("div", { staticClass: "row label_wrapper" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "columns small-12 medium-6 label_left subGroupLabel"
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("label", [
                                _vm._v(
                                  "\n                              " +
                                    _vm._s(
                                      _vm.$t(
                                        "CATALOGUE.SETTING.FIELDS.ITEM_TYPES.LABEL"
                                      )
                                    ) +
                                    "\n                              "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "tooltip_info" },
                                  [
                                    _c("fluent-icon", {
                                      staticClass: "help_icon",
                                      attrs: { icon: "question-circle" }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tooltip_card",
                                        staticStyle: { bottom: "18px" }
                                      },
                                      _vm._l(
                                        _vm.$t(
                                          "CATALOGUE.SETTING.FIELDS.ITEM_TYPES.HINT"
                                        ),
                                        function(text, idx) {
                                          return _c(
                                            "p",
                                            {
                                              key: idx,
                                              staticClass: "mb_0 font_12"
                                            },
                                            [_vm._v(_vm._s(text))]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("multiselect", {
                                key: "item_type",
                                staticClass:
                                  "small-12 font_normal order_multi_select",
                                attrs: {
                                  options: _vm.allItemTypes,
                                  "track-by": "id",
                                  label: "title",
                                  multiple: false,
                                  "close-on-select": true,
                                  "clear-on-select": false,
                                  "hide-selected": true,
                                  placeholder: _vm.$t(
                                    "CATALOGUE.SETTING.FIELDS.ITEM_TYPES.PLACEHOLDER"
                                  ),
                                  "selected-label": "",
                                  "select-label": _vm.$t(
                                    "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                  ),
                                  "deselect-label": _vm.$t(
                                    "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                                  )
                                },
                                model: {
                                  value: _vm.item_type,
                                  callback: function($$v) {
                                    _vm.item_type = $$v
                                  },
                                  expression: "item_type"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "columns small-12 medium-12 label_left mb_16"
                              },
                              [
                                _vm._v(
                                  "\n                              " +
                                    _vm._s(
                                      _vm.$t(
                                        "CATALOGUE.SETTING.FIELDS.PACKING_TIME.LABEL"
                                      )
                                    ) +
                                    "\n                              "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "tooltip_info" },
                                  [
                                    _c("fluent-icon", {
                                      staticClass: "help_icon",
                                      attrs: { icon: "question-circle" }
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "tooltip_card" }, [
                                      _c("p", { staticClass: "mb_0 font_12" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "CATALOGUE.SETTING.FIELDS.PACKING_TIME.HINT"
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "field_unit" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.estimated_packed_time,
                                        expression: "estimated_packed_time"
                                      }
                                    ],
                                    attrs: { type: "number", placeholder: "" },
                                    domProps: {
                                      value: _vm.estimated_packed_time
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.estimated_packed_time =
                                          $event.target.value
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "unit" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "CATALOGUE.SETTING.FIELDS.PACKING_TIME.UNIT"
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass:
                            "columns small-12 medium-6 label_right mb_16"
                        },
                        [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                _vm.$t(
                                  "CATALOGUE.SETTING.FIELDS.PERMISSIBLE_COUNTRY.LABEL"
                                )
                              ) +
                              "\n                      "
                          ),
                          _c(
                            "span",
                            { staticClass: "tooltip_info" },
                            [
                              _c("fluent-icon", {
                                staticClass: "help_icon",
                                attrs: { icon: "question-circle" }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "tooltip_card" }, [
                                _c("p", { staticClass: "mb_0 font_12" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "CATALOGUE.SETTING.FIELDS.PERMISSIBLE_COUNTRY.HINT"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("multiselect", {
                            staticClass:
                              "small-12 font_normal order_multi_select",
                            attrs: {
                              options: _vm.all_countries,
                              "track-by": "code",
                              label: "name",
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "hide-selected": true,
                              placeholder: _vm.$t(
                                "CATALOGUE.SETTING.FIELDS.PERMISSIBLE_COUNTRY.PLACEHOLDER"
                              ),
                              "selected-label": "",
                              "select-label": _vm.$t(
                                "FORMS.MULTISELECT.ENTER_TO_SELECT"
                              ),
                              "deselect-label": _vm.$t(
                                "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                              )
                            },
                            model: {
                              value: _vm.permissible_countries,
                              callback: function($$v) {
                                _vm.permissible_countries = $$v
                              },
                              expression: "permissible_countries"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        { staticClass: "columns small-12 medium-6 label_left" },
                        [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                _vm.$t(
                                  "CATALOGUE.SETTING.FIELDS.DELIVERY_FEE.LABEL"
                                )
                              ) +
                              "\n                      "
                          ),
                          _c(
                            "span",
                            { staticClass: "tooltip_info" },
                            [
                              _c("fluent-icon", {
                                staticClass: "help_icon",
                                attrs: { icon: "question-circle" }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "tooltip_card" }, [
                                _c("p", { staticClass: "mb_0 font_12" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "CATALOGUE.SETTING.FIELDS.DELIVERY_FEE.HINT"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("RadioList", {
                            attrs: {
                              classWrapper: "delivery_fee_types",
                              options: _vm.deliveryFeeTypes
                            },
                            model: {
                              value: _vm.paid_by_customer,
                              callback: function($$v) {
                                _vm.paid_by_customer = $$v
                              },
                              expression: "paid_by_customer"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "columns small-12 medium-6 label_right"
                        },
                        [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                _vm.$t(
                                  "CATALOGUE.SETTING.FIELDS.LOGISTICS.LABEL"
                                )
                              ) +
                              "\n                      "
                          ),
                          _c(
                            "span",
                            { staticClass: "tooltip_info" },
                            [
                              _c("fluent-icon", {
                                staticClass: "help_icon",
                                attrs: { icon: "question-circle" }
                              }),
                              _vm._v(" "),
                              _c(
                                "ul",
                                { staticClass: "tooltip_card" },
                                _vm._l(_vm.logisticTypesToltipList, function(
                                  item
                                ) {
                                  return _c(
                                    "li",
                                    { key: item, staticClass: "text_12" },
                                    [_vm._v(_vm._s(item))]
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("multiselect", {
                            staticClass:
                              "small-12 font_normal order_multi_select",
                            attrs: {
                              options: _vm.all_logistic_types,
                              "track-by": "id",
                              label: "name",
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "hide-selected": true,
                              placeholder: _vm.$t(
                                "CATALOGUE.SETTING.FIELDS.LOGISTICS.PLACEHOLDER"
                              ),
                              "selected-label": "",
                              "select-label": _vm.$t(
                                "FORMS.MULTISELECT.ENTER_TO_SELECT"
                              ),
                              "deselect-label": _vm.$t(
                                "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                              )
                            },
                            model: {
                              value: _vm.logistic_types,
                              callback: function($$v) {
                                _vm.logistic_types = $$v
                              },
                              expression: "logistic_types"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row label_wrapper" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "group_section toggle_field columns small-12 medium-6 label_left mb_16"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "toggle-button",
                          class: { active: _vm.pickup_enabled },
                          attrs: {
                            type: "button",
                            role: "switch",
                            "aria-checked": _vm.pickup_enabled.toString()
                          },
                          on: { click: _vm.onCustomerPickupToggle }
                        },
                        [
                          _c("span", {
                            class: { active: _vm.pickup_enabled },
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "text_14" }, [
                        _vm._v(
                          "\n                          " +
                            _vm._s(
                              _vm.$t("CATALOGUE.SETTING.FIELDS.PICKUP.LABEL")
                            ) +
                            "\n                          "
                        ),
                        _c(
                          "span",
                          { staticClass: "tooltip_info" },
                          [
                            _c("fluent-icon", {
                              staticClass: "help_icon",
                              attrs: { icon: "question-circle" }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "tooltip_card",
                                staticStyle: { bottom: "18px" }
                              },
                              [
                                _c("p", { staticClass: "mb_0 font_12" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "CATALOGUE.SETTING.FIELDS.PICKUP.HINT"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "columns small-12 medium-6 label_right mb_16"
                    },
                    [
                      _vm._v(
                        "\n                      " +
                          _vm._s(
                            _vm.$t(
                              "CATALOGUE.SETTING.FIELDS.MAX_SERVICE_RANGE.LABEL"
                            )
                          ) +
                          "\n                      "
                      ),
                      _c(
                        "span",
                        { staticClass: "tooltip_info" },
                        [
                          _c("fluent-icon", {
                            staticClass: "help_icon",
                            attrs: { icon: "question-circle" }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "tooltip_card" }, [
                            _c("p", { staticClass: "mb_0 font_12" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "CATALOGUE.SETTING.FIELDS.MAX_SERVICE_RANGE.HINT"
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "field_unit" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.max_delivery_range,
                              expression: "max_delivery_range"
                            }
                          ],
                          attrs: { type: "number", placeholder: "" },
                          domProps: { value: _vm.max_delivery_range },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.max_delivery_range = $event.target.value
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "unit" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "CATALOGUE.SETTING.FIELDS.MAX_SERVICE_RANGE.UNIT"
                              )
                            )
                          )
                        ])
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.showGoogleMap
                  ? _c(
                      "div",
                      [
                        _c("GoogleMap", {
                          attrs: {
                            address: _vm.custom_attributes.address,
                            onClose: _vm.closeGoogleMapModal,
                            show: _vm.showGoogleMap
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "row catalog_B_hours",
                    staticStyle: { "margin-top": "24px" }
                  },
                  [
                    _c("div", { staticClass: "columns small-12 medium-12" }, [
                      _c(
                        "label",
                        { staticClass: "font_regular text_16 font_600" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "CATALOGUE.SETTING.FIELDS.BUSINESS_HOUR_LABLE"
                              )
                            )
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "12px" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "business_hour",
                              staticStyle: { "margin-bottom": "8px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "font_regular font_400 text_12"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "CATALOGUE.SETTING.FIELDS.BUSINESS_HOUR.DAY"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "font_regular font_400 text_12"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "CATALOGUE.SETTING.FIELDS.BUSINESS_HOUR.HOURS"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.business_hours, function(
                            business_hour,
                            index
                          ) {
                            return _c("BusinessHour", {
                              key: index,
                              staticClass: "business_hour",
                              attrs: {
                                business_hour: business_hour,
                                business_hours: _vm.business_hours,
                                DAY_NAMES: _vm.DAY_NAMES,
                                index: index,
                                TIME_INTERVAL: _vm.TIME_INTERVAL,
                                removeBusinessHour: _vm.removeBusinessHour
                              }
                            })
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "woot-button",
                            {
                              staticClass: "add_b_hour_btn",
                              attrs: {
                                variant: "clear",
                                size: "small",
                                "color-scheme": "primary",
                                icon: "add",
                                "is-disabled": _vm.isAllBusinessHoursTaken
                              },
                              on: { click: _vm.addBusinessHour }
                            },
                            [
                              _vm._v(
                                "\n                          " +
                                  _vm._s(
                                    _vm.$t(
                                      "CATALOGUE.SETTING.FIELDS.ADD_BUSINESS_HOUR_BTN"
                                    )
                                  ) +
                                  "\n                          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("section", [
                  _c("div", { staticClass: "notification_header" }, [
                    _c("h3", { staticClass: "font_regular font_600 text_16" }, [
                      _vm._v(
                        "\n                          " +
                          _vm._s(
                            _vm.$t(
                              "CATALOGUE.SETTING.NOTIFICATION_MSG.HEADER.TITLE"
                            )
                          ) +
                          "\n                      "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "font_regular font_500 text_14" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "CATALOGUE.SETTING.NOTIFICATION_MSG.HEADER.CONTENT"
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "notification_inbox_wrap" }, [
                    _c("p", { staticClass: "font_regular font_500 text_12" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "CATALOGUE.SETTING.NOTIFICATION_MSG.INBOX_SELECT_LABEL"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "inbox_select_wrapper" },
                      [
                        _c("multiselect", {
                          key: "close_",
                          staticClass: "small-12",
                          attrs: {
                            options: _vm.inboxes,
                            disabled: !!_vm.catalog.inbox_id,
                            "track-by": "id",
                            label: "name",
                            multiple: false,
                            "close-on-select": true,
                            "clear-on-select": false,
                            "hide-selected": true,
                            placeholder: "Pick Inbox",
                            "selected-label": "",
                            "select-label": _vm.$t(
                              "FORMS.MULTISELECT.ENTER_TO_SELECT"
                            ),
                            "deselect-label": _vm.$t(
                              "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                            )
                          },
                          model: {
                            value: _vm.selectedInbox,
                            callback: function($$v) {
                              _vm.selectedInbox = $$v
                            },
                            expression: "selectedInbox"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.catalog.communal_inbox
                    ? _c("div", [
                        _c("p", { staticClass: "communal_notify" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("CATALOGUE.SETTING.COMUNNAL_INBOX_NOTIFY")
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.selectedInbox && _vm.selectedInbox.id) ||
                  _vm.catalog.inbox_id
                    ? _c(
                        "div",
                        [
                          _c("OrderActionMessagesTable", {
                            attrs: {
                              actionMessages: _vm.messages,
                              cols: _vm.cols,
                              toggleMessage: _vm.toggleMessageHandler,
                              toggleMessageEditor: _vm.toggleMessageEditor,
                              onMessageChange: _vm.onMessageChangeHandler
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "woot-button",
                      {
                        attrs: {
                          "color-scheme": "primary",
                          "is-loading": _vm.isUpdatingSetting,
                          "is-disabled":
                            _vm.isUpdatingSetting || _vm.isDisableSubmitBtn
                        },
                        on: { click: _vm.updateCatalogSetting }
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("GENERAL_SETTINGS.SUBMIT")) +
                            "\n                  "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }