var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-box" }, [
    _c(
      "div",
      [
        _c(
          "woot-button",
          {
            attrs: {
              "color-scheme": "success",
              "class-names": "button--fixed-right-top",
              icon: "add-circle"
            }
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("COUPON.CREATE_MODAL.BTNS.OPEN_MODAL")) +
                "\n      "
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("section", [_vm._v("\n      table list\n      table footer\n    ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }