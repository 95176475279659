<template>
<div>
    <label class="form_label" :class="{ error:  $v.item.value.$error }">
         {{$t(label.value)}}
        <!-- v-if="item.inputType === 'textarea'" -->
        <textarea
            v-model="item.value"
            name="" id="" cols="20" rows="6"
            @input="validateInput({ field: 'value', value: item.value, })"
        />
        <!-- <input v-else
            v-model="item.value" type="text"
            @input="validateInput({ field: 'value', value: item.value, })"
        /> -->
        <span v-if="$v.item.value.$error" class="message">
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}}
            {{constraint.value_limit}}
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}}
        </span>
    </label>

    <div  v-if="hasExtraConfig(item,'foreignContents')">
        <template v-for="(extraItem,exIdx) in item.extraConfig.foreignContents" >
            <label v-if="extraItem.contentType === 'card'" class="form_label" :key="'ex'+exIdx">
                {{$t(label.foreignContents[exIdx].title)}}
                <input
                    v-model="form_nodes[extraItem.step].contents[extraItem.contentIdx].actionButtons[extraItem.btnIndex].title"
                    type="text" name="" id=""
                >
            </label>
        </template>
    </div>


</div>
</template>

<script>
import { required, minValue, maxValue,maxLength  } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { countCharacters} from "./expressBot";

export default {
    props:{
        item:{
            default:{}
        },
        label:{
            default:{}
        },
        constraint:{
            default:{}
        },
        form_nodes:{
            default:{}
        },
    },
    data:{},
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            merchant: 'merchant/getMerchant',
        }),
    },
    validations() {
        return {
            item: {
                value: {
                    required,
                    maxLength: maxLength(this.constraint.value_limit || 20),
                    checkLength(value){
                        const totalLength = countCharacters(this.currentUser,this.merchant,value).length;
                        return totalLength < this.constraint.value_limit
                    },
                },
            },
        };
    },
    methods: {
        validateInput({ field,value }) {
            console.log(field,this.$v.item[field]);
            this.$v.item[field]?.$touch();
        },
        hasExtraConfig(item,key){
            if (item.extraConfig && item.extraConfig[key]) {
                return item.extraConfig[key]
            }
            return false;
        },
    }
}
</script>




<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.error{
    position: relative;
    margin-bottom: 25px;
    .message{
        position: absolute;
        bottom: -32x;
        color: var(--r-500);
    }
}

.form_label{
    font-weight: 500;
    font-size: 12px;
    color: #1F1F1F;
    margin-top: 12px;

    input{
        font-size: 12px;
    }
    textarea{
        font-size: 12px;
    }

    .textField{
        margin-top: 8px;
        padding: 12px;
        font-weight: 500;
        font-size: 14px;
        color: #1F1F1F;
        border-color: #EEEEEE;
    }
    .textField::placeholder{
        color: #B8B8B8;
    }
}
.check_label{
    display: flex;
    align-items: center;
    gap: 4px;
    input{
        margin: 0;
    }
}
</style>


