<template>
  <section class="campaigns-table-wrap">
      <!-- v-else -->
    <ve-table
      :columns="columns"
      scroll-width="190rem"
      :table-data="tableData"
      :border-around="true"
    />
    <empty-state v-if="showEmptyResult" :title="emptyMessage" />
    <div v-if="isLoading" class="campaign--loader">
      <spinner />
      <span>{{ $t('CAMPAIGN.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import Label from 'dashboard/components/ui/Label';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import WootButton from 'dashboard/components/ui/WootButton.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName';
import campaignMixin from 'shared/mixins/campaignMixin';
import timeMixin from 'dashboard/mixins/time';
import InboxName from 'dashboard/components/widgets/InboxName';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },

  mixins: [clickaway, timeMixin, campaignMixin, messageFormatterMixin],

  props: {
    campaigns: {
      type: Array,
      default: () => [],
    },
    showEmptyResult: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCampaignType:{
      type: String,
      default:""
    },
  },
  data(){
    return {
      expand_id: null,
    }
  },
  computed: {
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    inboxes() {
      if (this.isOngoingType) {
        return this.$store.getters['inboxes/getWebsiteInboxes'];
      }
      return this.$store.getters['inboxes/getTwilioInboxes'];
    },
    emptyMessage() {
      if (this.isOngoingType) {
        return this.inboxes.length
          ? this.$t('CAMPAIGN.ONGOING.404')
          : this.$t('CAMPAIGN.ONGOING.INBOXES_NOT_FOUND');
      }

      return this.inboxes.length
        ? this.$t('CAMPAIGN.ONE_OFF.404')
        : this.$t('CAMPAIGN.ONE_OFF.INBOXES_NOT_FOUND');
    },
    tableData() {
      if (this.isLoading) {
        return [];
      }

      // check which are expanded in child then push the child into that index to next
      let result = [];
      this.campaigns.forEach(parent => {
          let parentCopy = { ...parent, isParent: true };
          result.push(parentCopy);

          // Check if the parent should be expanded
          if (this.expand_id === parent.id) {
              if (parent.childrens && Array.isArray(parent.childrens)) {
                  parent.childrens.forEach((child,idx) => {
                    result.push({
                      ...child,
                      parent_id: parent.id,
                      child_index:idx,
                      total_sibling: parent.childrens.length,
                    });
                  });
              }
          }
      });

      return result;
      // return this.campaigns;
    },
    columns() {
      const visibleToAllTable = [
        {
          field: 'title',
          key: 'title',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.TITLE'),
          fixed: 'left',
          align: 'left',
          renderBodyCell: ({ row,rowIndex }) =>{
            const hasChild = row.childrens?.length > 0;
            const parentElement = (
              <div class="text_black_secondary text_10 font_500">
                  {
                    hasChild
                    ? <span>
                        {row.childrens?.length}
                        {this.$t('CAMPAIGN.LIST.TABLE_BODY.TITLE.TOTAL')}
                      </span>
                    : null
                  }
                  <span style={`margin-left:${hasChild?8:0}px;`}>
                  {row.total_recipient}
                  {this.$t('CAMPAIGN.LIST.TABLE_BODY.TITLE.PEOPLE')}
                  </span>
                </div>
            )

            const childElement = (
              <div class="text_black_secondary text_10 font_500">
                  <span>
                    {row.child_index + 1}
                    {this.$t('CAMPAIGN.LIST.TABLE_BODY.TITLE.OF')}
                    {row.total_sibling}
                  </span>
                  <span style={`margin-left:8px;`}>
                  {row.total_recipient}
                  {this.$t('CAMPAIGN.LIST.TABLE_BODY.TITLE.PEOPLE')}
                  </span>
                </div>
            )

            return (
              <div class="row--title-block" style="display:block;">
              <div style="position:relative;">
                <h6 class="font_regular text_14 font_500">{row.title}</h6>
                {
                  row.isParent
                  ? parentElement
                  : null
                }
                {
                  !row.isParent && row.parent_id
                  ? childElement
                  : null
                }
                {
                  row.isParent && row.childrens?.length
                  ? <div class="people_icon" onClick={()=>this.onExpand(rowIndex,row)}>
                    {
                      this.expand_id === row.id
                      ? <fluent-icon  icon="chevron-up" />
                      : <fluent-icon  icon="chevron-down" />
                    }
                  </div>
                : <span></span>
                }

              </div>
            </div>
            )
          },
           renderHeaderCell: ({ column }) => (
            <div class="custom-header-title">
              <span>{column.title}</span>
              <p class="font_regular font_500 text_10" style="color:#fff;">{this.$t('CAMPAIGN.LIST.TABLE_HEADER.SUB_TITLE')}</p>
            </div>
          ),
        },

        {
          field: 'message',
          key: 'message',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.MESSAGE'),
          align: 'left',
          width: 350,
          renderBodyCell: ({ row }) => {
            if (row.inbox.hasOwnProperty('email')) {
              return this.$t('CAMPAIGN.LIST.EMAIL_MESSAGE');
            }
            if (!row.inbox.hasOwnProperty('email')) {
              return (
                <div class="text-truncate">
                  <span
                    domPropsInnerHTML={this.formatMessage(row.message)}
                  ></span>
                </div>
              );
            }
            return '';
          },
        },
        {
          field: 'inbox',
          key: 'inbox',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.INBOX'),
          align: 'left',
          renderBodyCell: ({ row }) => {
            return <InboxName inbox={row.inbox} />;
          },
        },
      ];
      if (this.isOngoingType) {
        return [
          ...visibleToAllTable,
          {
            field: 'enabled',
            key: 'enabled',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.STATUS'),
            align: 'left',
            renderBodyCell: ({ row }) => {
              // let labelText = row.enabled
              //   ? this.$t('CAMPAIGN.LIST.STATUS.ENABLED')
              //   : this.$t('CAMPAIGN.LIST.STATUS.DISABLED');
                let colorSchema = 'secondary';
                let labelText = '';
                switch (row.campaign_status) {
                  case 'enabled':
                    colorSchema = 'success'
                    labelText = this.$t('CAMPAIGN.LIST.STATUS.ENABLED');
                    break;
                  case 'disabled':
                    colorSchema = 'secondary'
                    labelText = this.$t('CAMPAIGN.LIST.STATUS.DISABLED');
                    break;
                  case 'completed':
                    colorSchema = 'success'
                    labelText = this.$t('CAMPAIGN.LIST.STATUS.COMPLETED');
                    break;
                  case 'pending':
                    colorSchema = 'warning'
                    labelText = this.$t('CAMPAIGN.LIST.STATUS.PENDING');
                    break;
                  case 'active':
                    colorSchema = 'success'
                    labelText = this.$t('CAMPAIGN.LIST.STATUS.ACTIVE');
                    break;

                  default:
                    break;
                }
              // const colorScheme = row.enabled ? 'success' : 'secondary';
              return <Label title={labelText} colorScheme={colorSchema} />;
            },
          },
          {
            field: 'sender',
            key: 'sender',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SENDER'),
            align: 'left',
            renderBodyCell: ({ row }) => {
              if (row.sender) return <UserAvatarWithName user={row.sender} />;
              return this.$t('CAMPAIGN.LIST.SENDER.BOT');
            },
          },
          {
            field: 'url',
            key: 'url',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.URL'),
            align: 'left',
            renderBodyCell: ({ row }) => (
              <div class="text-truncate">
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={row.url}
                  title={row.url}
                >
                  {row.url}
                </a>
              </div>
            ),
          },
          {
            field: 'timeOnPage',
            key: 'timeOnPage',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.TIME_ON_PAGE'),
            align: 'left',
          },

          {
            field: 'buttons',
            key: 'buttons',
            title: '',
            align: 'left',
            renderBodyCell: row => (
              <div class="button-wrapper">
                <WootButton
                  variant="clear"
                  icon="edit"
                  color-scheme="secondary"
                  classNames="grey-btn"
                  onClick={() => this.$emit('on-edit-click', row)}
                >
                  {this.$t('CAMPAIGN.LIST.BUTTONS.EDIT')}
                </WootButton>
                <WootButton
                  variant="link"
                  icon="dismiss-circle"
                  color-scheme="secondary"
                  onClick={() => this.$emit('on-delete-click', row)}
                >
                  {this.$t('CAMPAIGN.LIST.BUTTONS.DELETE')}
                </WootButton>
              </div>
            ),
          },
        ];
      }
      return [
        {
          field: 'scheduledAt',
          key: 'scheduledAt',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SCHEDULED_AT'),
          align: 'left',
        },
        ...visibleToAllTable,
        {
          field: 'sent',
          key: 'sent',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SENT'),
          align: 'left',
          width: 80,
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">
                {row.recieved}
              </h6>
            </div>
          ),
        },
        {
          field: 'failed',
          key: 'failed',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.FAILED'),
          align: 'left',
          width: 80,
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">{row.failed}</h6>
            </div>
          ),
        },
        {
          field: 'read',
          key: 'read',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.READ'),
          align: 'left',
          width: 80,
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">{row.read}</h6>
            </div>
          ),
        },

        {
          field: 'cost',
          key: 'cost',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.COST'),
          align: 'left',
          width: 80,
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">
                {row.broadcast_amount}
              </h6>
            </div>
          ),
        },
        {
          field: 'refunded',
          key: 'refunded',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.REFUNDED'),
          align: 'left',
          width: 90,
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">
                {row.refunded}
              </h6>
            </div>
          ),
        },
        {
          field: 'campaign_status',
          key: 'campaign_status',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.STATUS'),
          align: 'left',
          width: 80,
          renderBodyCell: ({ row }) => {
            const labelText =
              // eslint-disable-next-line no-nested-ternary
              row.campaign_status === 'pending'
                ? this.$t('CAMPAIGN.LIST.STATUS.PENDING')
                : row.campaign_status === 'completed'
                ? this.$t('CAMPAIGN.LIST.STATUS.COMPLETED')
                : row.campaign_status === 'cancelled'
                ? this.$t('CAMPAIGN.LIST.STATUS.CANCELLED')
                : this.$t('CAMPAIGN.LIST.STATUS.ACTIVE');
            const colorScheme =
              row.campaign_status === 'completed' ||
              row.campaign_status === 'cancelled' ||
              row.campaign_status === 'pending'
                ? 'secondary'
                : 'success';
            return <Label title={labelText} colorScheme={colorScheme} />;
          },
        },
        {
          field: 'buttons',
          key: 'buttons',
          title: '',
          align: 'left',
          width: 85,
          renderBodyCell: row => {
            if (row.row.campaign_status === 'completed') {
              return <div class="no-btn"></div>
            }
            return (
              <div class="button-wrapper">
                <WootButton
                  variant="link"
                  icon="dismiss-circle"
                  color-scheme="secondary"
                  onClick={() => this.$emit('on-delete-click', row)}
                >
                  {this.$t('CAMPAIGN.LIST.BUTTONS.DELETE')}
                </WootButton>
              </div>
            )
          },
        },
      ];
    },
  },
  methods:{
    onExpand(index,parentItem){
      if (this.expand_id === parentItem.id) {
        this.expand_id = null;
      }else{
        this.expand_id = parentItem.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.campaigns-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
    .ve-table-container{
      overflow-y: hidden;
    }

    thead.ve-table-header .ve-table-header-tr .ve-table-header-th {
      font-size: var(--font-size-mini);
      padding: var(--space-small) var(--space-two);
    }
    tbody.ve-table-body .ve-table-body-tr .ve-table-body-td {
      @extend .font_regular;
      @extend .text_14;
      @extend .font_500;
      padding: var(--space-slab) var(--space-two);

      .inbox--name{
        @extend .font_regular;
        @extend .text_14;
        @extend .font_500;
        align-items: center;
      }
    }

  }

  .ve-table-header-th {
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }
  .ve-table-header-th:first-child {
    border-top-left-radius: 4px;
  }
  .ve-table-header-th:last-child {
    border-top-left-radius: 4px;
  }

  .row--title-block {
    align-items: center;
    display: flex;
    text-align: left;

    .title {
      font-size: var(--font-size-small);
      margin: 0;
      text-transform: capitalize;
    }
  }
  .label {
    padding: var(--space-smaller) var(--space-small);
  }
}

.campaign--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.button-wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  // min-width: 20rem;
}

.people_icon{
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  right: -20px;
  cursor: pointer;
  svg{
    @extend .text_black_secondary;
  }
}
</style>
