
export const services = [
    {
        id:1,
        industry:"Arts & Entertainment Service",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:2,
        industry:"Finance",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:3,
        industry:"Travel Agencies",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:4,
        industry:"Automotive Services",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:5,
        industry:"Spa & Other Beauty Services",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:6,
        industry:"Home Improvement Service",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:7,
        industry:"Legal Service",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:8,
        industry:"Clinick & Dentist",
        b2b: "professional services",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:9,
        industry:"Other Services",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:10,
        industry:"Fashion Products",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:11,
        industry:"Techincal Repair Services",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:12,
        industry:"Beauty Products",
        b2b: "abs",
        b2c: "retail",
        b2d: "retail",
    },
    {
        id:13,
        industry:"Health related products",
        b2b: "abs",
        b2c: "retail",
        b2d: "retail",
    },
    {
        id:14,
        industry:"Food products",
        b2b: "abs",
        b2c: "retail",
        b2d: "retail",
    },
    {
        id:15,
        industry:"Travel products",
        b2b: "abs",
        b2c: "retail",
        b2d: "retail",
    },
    {
        id:16,
        industry:"Books",
        b2b: "abs",
        b2c: "retail",
        b2d: "retail",
    },
    {
        id:17,
        industry:"Games and Hobbies Products",
        b2b: "abs",
        b2c: "retail",
        b2d: "retail",
    },
    {
        id:18,
        industry:"Home Products",
        b2b: "abs",
        b2c: "retail",
        b2d: "retail",
    },
    {
        id:19,
        industry:"Other Products",
        b2b: "abs",
        b2c: "retail",
        b2d: "retail",
    },
    {
        id:20,
        industry:"Restaurants & Cafe",
        b2b: "abs",
        b2c: "fnb",
        b2d: "fnb",
    },
    {
        id:21,
        industry:"Jobs",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:22,
        industry:"Pet Services",
        b2b: "abs",
        b2c: "professional services",
        b2d: "abs",
    },
    {
        id:23,
        industry:"Pet Products",
        b2b: "abs",
        b2c: "retail",
        b2d: "retail",
    },
    {
        id:24,
        industry:"Business & Industrial",
        b2b: "abs",
        b2c: "abs",
        b2d: "abs",
    },
]

export const businessTypes = [
    {
        id:'b2b',
        title:"Business to Business",
        info:"You want to sell to other businesses",
    },
    {
        id: 'b2c',
        title:"Business to Consumer",
        info:"You want to acquire new consumers",
    },
    {
        id: 'b2d',
        title:"Business to Distributor",
        info:"You want to recruit new distributors and agents",
    },
]

export const businessServices = [
    {
        id: 1,
        sector:"fnb",
        icon:"bicycle",
        viewBox:"0 0 20 20",
        name:"ARRANGE.BUSINESS_SERVICES.FNB_1_NAME",
        description:"ARRANGE.BUSINESS_SERVICES.FNB_1_DESC",
    },
    {
        id:2,
        sector:"fnb",
        icon:"headphone",
        viewBox:"0 0 20 20",
        name:"ARRANGE.BUSINESS_SERVICES.FNB_2_NAME",
        description:"ARRANGE.BUSINESS_SERVICES.FNB_2_DESC",
    },
    {
        id:3,
        sector:"retail",
        icon:"brand-whatsapp",
        viewBox:"0 0 24 24",
        name:"ARRANGE.BUSINESS_SERVICES.RETAIL_NAME",
        description:"ARRANGE.BUSINESS_SERVICES.RETAIL_DESC",
    },
    {
        id:4,
        sector:"professional services",
        icon:"calendar-check",
        viewBox:"0 0 20 20",
        name:"ARRANGE.BUSINESS_SERVICES.PROFESSIONAL_SERVICE_NAME",
        description:"ARRANGE.BUSINESS_SERVICES.PROFESSIONAL_SERVICE_DESC",
    },
    // hide now 
    // {
    //     id:5,
    //     sector:"abs",
    //     icon:"asterisk",
    //     viewBox:"0 0 20 20",
    //     name:"ARRANGE.BUSINESS_SERVICES.ABS_NAME",
    //     description:"ARRANGE.BUSINESS_SERVICES.ABS_DESC",
    // },
]
