var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", [
    _vm.label ? _c("span", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
    _vm._v(" "),
    _c("input", {
      attrs: {
        type: _vm.type,
        placeholder: _vm.placeholder,
        readonly: _vm.readonly,
        step: _vm.type === "number" ? _vm.step : undefined
      },
      domProps: { value: _vm.value },
      on: { input: _vm.onChange, blur: _vm.onBlur }
    }),
    _vm._v(" "),
    _vm.helpText ? _c("p", { staticClass: "help-text" }) : _vm._e(),
    _vm._v(" "),
    _vm.error
      ? _c("span", { staticClass: "message" }, [
          _vm._v("\n    " + _vm._s(_vm.error) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }