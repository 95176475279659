<template>
  <section class="coupons-table-wrap">
      <!-- v-else -->
    <ve-table
      :columns="columns"
      scroll-width="190rem"
      :table-data="tableData"
      :border-around="true"
    />
    <empty-state v-if="showEmptyResult" :title="emptyMessage" />
    <div v-if="isLoading" class="campaign--loader">
      <spinner />
      <span>{{ $t('CAMPAIGN.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import Label from 'dashboard/components/ui/Label';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import WootButton from 'dashboard/components/ui/WootButton.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName';
import timeMixin from 'dashboard/mixins/time';
import InboxName from 'dashboard/components/widgets/InboxName';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },

  mixins: [clickaway, timeMixin, messageFormatterMixin],

  props: {
    coupons: {
      type: Array,
      default: () => [],
    },
    showEmptyResult: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCampaignType:{
      type: String,
      default:""
    },
  },
  data(){
    return {
      expand_id: null,
    }
  },
  computed: {

    emptyMessage() {
      return this.$t('COUPON.COUPON_LIST.EMPTY_MSG');
    },
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.coupons;
    },
    columns() {

      return [
        {
          field: 'name',
          key: 'name',
          title: this.$t('COUPON.COUPON_LIST.TABLE_HEADER.NAME'),
          align: 'left',
        },
        {
          field: 'type',
          key: 'type',
          title: this.$t('COUPON.COUPON_LIST.TABLE_HEADER.TYPE'),
          align: 'left',
        },
        {
          field: 'start_date',
          key: 'start_date',
          title: this.$t('COUPON.COUPON_LIST.TABLE_HEADER.START_DATE'),
          align: 'left',
        },
        {
          field: 'duration',
          key: 'duration',
          title: this.$t('COUPON.COUPON_LIST.TABLE_HEADER.DURATION'),
          align: 'left',
        },
        {
          field: 'discount',
          key: 'discount',
          title: this.$t('COUPON.COUPON_LIST.TABLE_HEADER.DISCOUNT'),
          align: 'left',
        },
        {
          field: 'issue',
          key: 'issue',
          title: this.$t('COUPON.COUPON_LIST.TABLE_HEADER.ISSUE'),
          align: 'left',
        },
        {
          field: 'issue',
          key: 'issue',
          title: this.$t('COUPON.COUPON_LIST.TABLE_HEADER.REDEEM'),
          align: 'left',
        },
        {
          field: 'issue',
          key: 'issue',
          title: this.$t('COUPON.COUPON_LIST.TABLE_HEADER.SALES'),
          align: 'left',
        },
        {
          field: 'issue',
          key: 'issue',
          title: this.$t('COUPON.COUPON_LIST.TABLE_HEADER.STATUS'),
          align: 'left',
        },
        {
          field: 'issue',
          key: 'issue',
          title: this.$t('COUPON.COUPON_LIST.TABLE_HEADER.ACTION'),
          align: 'left',
        },

        /*
        {
          field: 'sent',
          key: 'sent',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SENT'),
          align: 'left',
          width: 80,
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">
                {row.recieved}
              </h6>
            </div>
          ),
        },
        {
          field: 'failed',
          key: 'failed',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.FAILED'),
          align: 'left',
          width: 80,
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">{row.failed}</h6>
            </div>
          ),
        },
        {
          field: 'read',
          key: 'read',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.READ'),
          align: 'left',
          width: 80,
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">{row.read}</h6>
            </div>
          ),
        },
        {
          field: 'cost',
          key: 'cost',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.COST'),
          align: 'left',
          width: 80,
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">
                {row.broadcast_amount}
              </h6>
            </div>
          ),
        },
        {
          field: 'refunded',
          key: 'refunded',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.REFUNDED'),
          align: 'left',
          width: 90,
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">
                {row.refunded}
              </h6>
            </div>
          ),
        },
        {
          field: 'campaign_status',
          key: 'campaign_status',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.STATUS'),
          align: 'left',
          width: 80,
          renderBodyCell: ({ row }) => {
            const labelText =
              // eslint-disable-next-line no-nested-ternary
              row.campaign_status === 'pending'
                ? this.$t('CAMPAIGN.LIST.STATUS.PENDING')
                : row.campaign_status === 'completed'
                ? this.$t('CAMPAIGN.LIST.STATUS.COMPLETED')
                : row.campaign_status === 'cancelled'
                ? this.$t('CAMPAIGN.LIST.STATUS.CANCELLED')
                : this.$t('CAMPAIGN.LIST.STATUS.ACTIVE');
            const colorScheme =
              row.campaign_status === 'completed' ||
              row.campaign_status === 'cancelled' ||
              row.campaign_status === 'pending'
                ? 'secondary'
                : 'success';
            return <Label title={labelText} colorScheme={colorScheme} />;
          },
        },
        {
          field: 'buttons',
          key: 'buttons',
          title: '',
          align: 'left',
          width: 85,
          renderBodyCell: row => {
            if (row.row.campaign_status === 'completed') {
              return <div class="no-btn"></div>
            }
            return (
              <div class="button-wrapper">
                <WootButton
                  variant="link"
                  icon="dismiss-circle"
                  color-scheme="secondary"
                  onClick={() => this.$emit('on-delete-click', row)}
                >
                  {this.$t('CAMPAIGN.LIST.BUTTONS.DELETE')}
                </WootButton>
              </div>
            )
          },
        },
        */
      ];
    },
  },
  methods:{},
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.coupons-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
    .ve-table-container{
      overflow-y: hidden;
    }

    thead.ve-table-header .ve-table-header-tr .ve-table-header-th {
      font-size: var(--font-size-mini);
      padding: var(--space-small) var(--space-two);
    }
    tbody.ve-table-body .ve-table-body-tr .ve-table-body-td {
      @extend .font_regular;
      @extend .text_14;
      @extend .font_500;
      padding: var(--space-slab) var(--space-two);

      .inbox--name{
        @extend .font_regular;
        @extend .text_14;
        @extend .font_500;
        align-items: center;
      }
    }

  }

  .ve-table-header-th {
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }
  .ve-table-header-th:first-child {
    border-top-left-radius: 4px;
  }
  .ve-table-header-th:last-child {
    border-top-left-radius: 4px;
  }

  .row--title-block {
    align-items: center;
    display: flex;
    text-align: left;

    .title {
      font-size: var(--font-size-small);
      margin: 0;
      text-transform: capitalize;
    }
  }
  .label {
    padding: var(--space-smaller) var(--space-small);
  }
}

.campaign--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.button-wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  // min-width: 20rem;
}

.people_icon{
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  right: -20px;
  cursor: pointer;
  svg{
    @extend .text_black_secondary;
  }
}
</style>

