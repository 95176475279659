<template>
  <div class="content-box">
    <div>
      <woot-button
        color-scheme="success"
        class-names="button--fixed-right-top"
        icon="add-circle"
      >
        {{$t('COUPON.CREATE_MODAL.BTNS.OPEN_MODAL')}}
      </woot-button>
    </div>

<!-- 
    <section>
      <div class="search_container">
        <SearchInput
          :placeholder="$t('CAMPAIGN.FILTERS.SEARCH_PLACEHOLDER')"
          v-model="searchValue"
          @search="onSearch"
        />
      </div>
      <div class="campaing_filters">
        <div class="campaing_filter">
          <p class="font_regular font_500 text_12">{{$t('CAMPAIGN.FILTERS.DATE')}}</p>
          <date-range-picker
            :confirm-text="$t('CAMPAIGN.FILTERS.DATE_CONFIRM_BTN')"
            :placeholder="$t('CAMPAIGN.FILTERS.DATE_PLACEHOLDER')"
            class="font_normal"
            :value="dateRange"
            @change="handleDateChange"
            :disabledDate="disableFilterDate"
          />
        </div>
        <div class="campaing_filter">
          <p class="font_regular font_500 text_12">{{$t('CAMPAIGN.FILTERS.STATUS')}}</p>
          <multiselect
            v-model="selectedStatus"
            :options="statusList"
            class="small-12 font_normal"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CAMPAIGN.FILTERS.STATUS_PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @select="applyFilterByStatuses($event)"
          />
        </div>
        <div class="campaing_filter">
          <p class="font_regular font_500 text_12">{{$t('CAMPAIGN.FILTERS.SORT_BY')}}</p>
          <multiselect
            v-model="sortBy"
            :options="sortByList"
            class="small-12 font_normal"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CAMPAIGN.FILTERS.SORTBY_PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @select="applyFilterBySortBy($event)"
          />
        </div>

      </div>
    </section> -->




    <section>
      <!-- <CouponTable /> -->
      table list
      table footer
    </section>


    <!-- <hr class="mt_50 ">
    Coupons page
    <InputField
      :error="'sample error'"
      :label="'sample label'"
      :placeholder="'sample placeholder'"
      v-model="user_name"
      :infoList="['line 1', 'line 2']"
    />
    <pre>{{user_name}}</pre>
    <hr>
    <MultiselectDropdown>
      <multiselect
          v-model="country"
          :options="clist"
          class="small-12 font_normal order_multi_select"
          track-by="id"
          label="title"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :hide-selected="true"
          :placeholder="$t('CATALOGUE.SETTING.FIELDS.PERMISSIBLE_COUNTRY.PLACEHOLDER')"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
      />
    </MultiselectDropdown>
    <pre>{{country}}</pre> -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import SearchInput from 'dashboard/components/ui/SearchInput.vue'
import DateRangePicker from "dashboard/components/ui/DateRangePicker.vue"

import InputField from '../../../../components/ui/inputs/InputField.vue'
import MultiselectDropdown from '../../../../components/ui/inputs/MultiselectDropdown.vue'
import CouponTable from "./CouponTable.vue"

export default {
  components:{
    TableFooter,
    SearchInput,
    DateRangePicker,
    CouponTable,

    InputField,
    MultiselectDropdown,
  },
  data(){
    return {
      searchValue:'',
      dateRange: [
        // startDate,endDate
      ],
      selectedStatus:'',
      statusList:[
        {id:0,title:'statis 1'},
        {id:1,title:'statis 2'},
        // {id:1,title:this.$t('CAMPAIGN.FILTERS.STATUS_LIST.COMPLETE')},
        // {id:2,title:this.$t('CAMPAIGN.FILTERS.STATUS_LIST.PENDING')},
        // {id:3,title:this.$t('CAMPAIGN.FILTERS.STATUS_LIST.REJECT')},
      ],
      sortBy:'',
      // it will be sort list
      sortByList:[
        {id:0,order:"scheduled_at",direction:"asc",
        title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.SCHEDULED_ASC')
        },
        {id:1,order:"scheduled_at",direction:"desc",
        title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.SCHEDULED_DESC')
        },
        // {id:2,order:"broadcast_amount",direction:"asc",
        // title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.BROADCAST_COST_ASC')
        // },
        // {id:3,order:"broadcast_amount",direction:"desc",
        // title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.BROADCAST_COST_DESC')
        // },
        // {id:4,order:"recipient_count",direction:"asc",
        // title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.TOTAL_RECIPIENT_ASC')
        // },
        // {id:5,order:"recipient_count",direction:"desc",
        // title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.TOTAL_RECIPIENT_DESC')
        // },
      ],


      // user_name: "",
      // country: [],
      // clist: [
      //   {id:1,title:"item 1"},
      //   {id:2,title:"item 2"},
      //   {id:3,title:"item 3"},
      // ]
    }
  },
  methods:{
    onSearch(value){
      // this.fetchCampaignByFilter({q:value});
    },
    handleDateChange(dateRange){
      this.dateRange = dateRange;
      // this.fetchCampaignByFilter({
      //   start_at: dateRange[0],
      //   end_at: dateRange[1],
      // });
    },
    disableFilterDate(date,currentValues){
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 12*10); // 12*10 months ago// 10 year

      if (date > today) {
        return false;
      }

      if (date < threeMonthsAgo) {
        return true;
      }
      return false;
    },
    applyFilterByStatuses(selectedOption){
      this.selectedStatus = selectedOption;
      // this.fetchCampaignByFilter({status:selectedOption.id});
    },
    applyFilterBySortBy(selectedOption){
      this.sortBy = selectedOption;
      // this.fetchCampaignByFilter({
      //   order: selectedOption.order,
      //   direction: selectedOption.direction,
      // });
    },
    // end methods
  },
}
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/widgets/_fonts.scss';


.search_container{
  margin-top: 16px;
  max-width: 280px;
}

.campaing_filters{
  display: flex;
  gap: 12px;
  margin-top: 16px;
  .campaing_filter{
    // max-width: 350px;
    p{
      margin-bottom: 8px;
    }
  }
}

</style>
