<template>
    <div>
        <div v-if="isExpressModalOpen">
            <BotExpressModal
            :isOpen="isExpressModalOpen"
            :isFinalStepQrModalOpen="isFinalStepQrModalOpen"
            :current_step="current_step"
            :form_nodes="botform.form_data.form_nodes"
            :botform="botform"
            :labels="form_labels"
            :isSubmitting="isFormLoading"
            :onNext="onNextClick"
            :onPrevious="onPreviousClick"
            @onClose="onExpressModalClose"
            />
        </div>
        <!-- <div>
            <woot-button @click="onClickOPenBotExpress">Bot Express</woot-button>
        </div> -->
    </div>
</template>

<script>
import BotExpressModal from "./BotExpressModal.vue";
import { mapGetters } from 'vuex';
import {formAndAccountMerger,ExpressBotService} from "./expressBot"
import WootButton from '../../../../components/ui/WootButton.vue';
import { required, minLength } from 'vuelidate/lib/validators';

export default {
    components:{
        BotExpressModal,
        WootButton,
    },
    data(){
        return{
            isExpressModalOpen: false,
            isFormLoading: false,
            isFormConfiguring:false,
            isFinalStepQrModalOpen:false,
            expressBotService: null,
            label_template_name:"",
            flowBotAccessToken: "",
            botform: {
                name: "",
                template_name:"",
                form_data:{
                    form_nodes:{}
                }
            },
        }
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            merchant: 'merchant/getMerchant',
        }),
        current_step(){
            return this.botform?.form_data?.current_step;
        },
        form_labels(){
            // get from form json
            const labels = this.botform?.form_data?.labels || {}
            // Rearrange steps based on the `step` property
            const rearrangedLabels = Object.keys(labels)
                .sort((key1, key2) => labels[key1].step - labels[key2].step) // Sort by `step`
                .reduce((sortedObj, key) => {
                sortedObj[key] = labels[key];
                return sortedObj;
                }, {});

            return rearrangedLabels;
        },
    },
    watch:{
        currentUser:{
            handler(){
                if (this.currentUser?.account_id) {
                this.$store.dispatch('merchant/fetchMerchant',{account_id:this.currentUser.account_id});
                }
                this.initializeExpressBotService()
                // if param has
                // this.getCurrentForm();
                this.openModalForm();
            },
            immediate: true,
        },
        '$route.query.open_express_bot':{
            handler(){
                this.openModalForm();
            },
            immediate: true,
        }
    },
    async mounted(){
        if (this.currentUser?.account_id) {
            await this.$store.dispatch('merchant/fetchMerchant',{account_id:this.currentUser.account_id});
            // if params has open modal link
            // await this.getCurrentForm();
        }

        // retrive the existing bot and update that istead recreate

        // open modal on "CTL+O"
        window.addEventListener("keydown", this.handleKeyDown);

    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeyDown);
    },
    validations(){},
    methods:{
        openModalForm(){
            if (this.$route.query?.open_express_bot === 'true') {
                this.getCurrentForm();
            }
        },
        removeQueryParams(){
            const { open_express_bot, ...restQuery } = this.$route.query;
            this.$router.replace({
                query: restQuery
            });
        },
          initializeExpressBotService() {
            if (!this.currentUser) return;

            this.expressBotService = new ExpressBotService({
                isDev: process.env.NODE_ENV === 'development',
                userObj: {
                    userId: this.currentUser.id,
                    accountId: this.currentUser.account_id,
                    apiAccessToken: this.currentUser.access_token,
                },
            });
        },
        async getCurrentForm(){
            // call api, if exist there, use that form, else use default one
            // const newBotName="";
            try {
                if (this.currentUser?.account_id) {
                    if (this.isFormConfiguring) {
                        return
                    }
                    this.isFormConfiguring= true;

                    // call to get meta sector,
                    // using meta.template_name decide which default form to use
                    const {data:botListRes} = await this.expressBotService.getAllExpressBots();

                    if (botListRes?.meta?.template_name) {
                        // use this name to find default template
                        const template_name = botListRes?.meta?.template_name;

                        // get bot id
                        const {data:botTemplate} = await this.expressBotService.getExpressBotTempByTempName(template_name);


                        const curentDefaultForm = {
                            name: 'express-bot-' + this.currentUser.account_id,
                            bot_id:  botTemplate.bot_id,
                            form_data: botTemplate.form_data,
                            bot_description: botTemplate.bot_description,
                        };
                        this.label_template_name = template_name;

                        let express_bot_DB_id = botListRes.data.find(el=>el.name === curentDefaultForm.name)?.id;

                        let existForm = curentDefaultForm;

                        // if existForm.bot_id = null, create a bot in DB
                        if (express_bot_DB_id) {
                            const {data} = await this.expressBotService.getExpressBotById(express_bot_DB_id);
                            existForm = data

                        }
                        let bot_id = existForm?.bot_id;


                        if (!bot_id) {
                            // before create a new in DB, test if it is created in FlowBot nodejs
                                const newBot = await this.expressBotService.createFlow(curentDefaultForm.name,botTemplate.template_name);



                            const newExpressEmptyPayload= {
                                bot_express: {
                                    name: curentDefaultForm.name,
                                    template_name: botTemplate.template_name,
                                    account_id: this.currentUser.account_id,
                                    // template:true,
                                    form_data: curentDefaultForm.form_data
                                }
                            }
                            // insert the empty form into this account for this bot
                            const botCreateRes = await this.expressBotService.createExpressBot(newExpressEmptyPayload);

                            const {data:newExpressBotRes} = await this.expressBotService.getExpressBotById(curentDefaultForm.name,); // pass id here

                            bot_id = newExpressBotRes.bot_id;
                            existForm.id=newExpressBotRes.id;
                        }
                        // if it is uploaded true, reset the current_step

                            const steps = Object.keys(this.form_labels)
                        if (existForm.uploaded && existForm.form_data?.form_nodes && existForm.form_data.current_step === steps[steps.length-1]) {
                            existForm.form_data.current_step = steps[0]

                        }

                        this.botform = {
                            ...existForm,
                            bot_id,
                        };

                        this.onExpressModalOpen();

                    }
                    return
                }

            } catch (error) {
                console.log("ERR",error);
            }

        },
        onExpressModalClose(){
            this.isExpressModalOpen = false;
            this.isFinalStepQrModalOpen = false;
            this.isFormConfiguring = false;
            this.removeQueryParams();
        },
        onExpressModalOpen(){
            this.isExpressModalOpen = true
        },
        onPreviousClick(previous_step){
            // go to next step
            this.botform.form_data.current_step = previous_step;
        },
        async onNextClick(next_step,isSubmit=false){
            this.isFormLoading = true;
            // format the dynamic values and update to DB
                let formJson =  this.botform;
                if (isSubmit) {
                    formJson.form_data.submitted = true;
                }
            // if (isSubmit) {
               const newFlowBotData =  formAndAccountMerger(this.merchant,this.botform,this.currentUser);
            // }

            const userObj = { userId:this.currentUser.id, accountId:this.currentUser.account_id, apiAccessToken:this.currentUser.access_token}

            const payloadFormData = JSON.parse(JSON.stringify(formJson));
            payloadFormData.form_data.current_step = next_step;
            if(isSubmit){
                payloadFormData.uploaded = true;
            }else{
                payloadFormData.uploaded = false;
            }

            const res = await this.expressBotService.createExpressBotByTpl(payloadFormData,newFlowBotData,isSubmit)
            if (res.isSuccess) {
                this.botform = res.data;
                await this.$nextTick();

                // go to next step
                this.botform.form_data.current_step = next_step;

                if(isSubmit){
                    this.isFinalStepQrModalOpen = true;
                    // dont close, open the Final QR modal
                    // console.log("called onExpressModalClose()");
                    // this.onExpressModalClose();
                }
            }
            this.isFormLoading = false;
        },
        formAndAccountMergerPreview(merchant, botform, currentUser){
            return formAndAccountMerger(merchant, botform, currentUser)
        },
        onClickOPenBotExpress(){
            // set current step 0
            const steps = Object.keys(this.form_labels);
            this.botform.form_data.current_step = steps[0]

            this.onExpressModalOpen();
        },
         handleKeyDown(event) {
            if (event.ctrlKey && event.key?.toLowerCase() === "o") {
                event.preventDefault();
                this.onExpressModalOpen();
            }
        },

        // end methods
    },
}
</script>

<style>

</style>