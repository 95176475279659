<template>
<div>
   <label class="form_label" :class="{ error:  $v.item.title.$error }">
        {{$t(label.title)}}
            <!-- v-if="item.inputType === 'textarea'" -->
        <textarea
            v-model="item.title"
            name="" id="" cols="20" rows="6"
            @input="validateInput({ field: 'title', value: item.title, })"
        />
        <!-- <input v-else
            v-model="item.title" type="text"
            @input="validateInput({
            index,
            field: 'title',
            parent: 'image',
            limit: constraints.image.title_limit,
            value: item.title
        })"
        /> -->
        <span v-if="$v.item.title.$error" class="message">
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}}
            {{constraint.title_limit}}
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}}
        </span>
    </label>
</div>
</template>

<script>
import { required, minValue, maxValue,maxLength  } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { countCharacters} from "./expressBot";

export default {
    props:{
        item:{
            default:{}
        },
        label:{
            default:{}
        },
        constraint:{
            default:{}
        },
    },
    data:{},
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            merchant: 'merchant/getMerchant',
        }),
    },
    validations() {
        return {
            item: {
                title: {
                    required,
                    maxLength: maxLength(this.constraint.title_limit || 1024),
                    checkLength(value){
                        const totalLength = countCharacters(this.currentUser,this.merchant,value).length;
                        return totalLength < this.constraint.title_limit
                    },
                },
            },
        };
    },
    methods: {
        validateInput({ field,value }) {
            console.log(field,this.$v.item[field]);
            this.$v.item[field]?.$touch();
        },
    }
}
</script>




<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.error{
    position: relative;
    margin-bottom: 25px;
    .message{
        position: absolute;
        bottom: -32x;
        color: var(--r-500);
    }
}

.form_label{
    font-weight: 500;
    font-size: 12px;
    color: #1F1F1F;
    margin-top: 12px;

    input{
        font-size: 12px;
    }
    textarea{
        font-size: 12px;
    }

    .textField{
        margin-top: 8px;
        padding: 12px;
        font-weight: 500;
        font-size: 14px;
        color: #1F1F1F;
        border-color: #EEEEEE;
    }
    .textField::placeholder{
        color: #B8B8B8;
    }
}
.check_label{
    display: flex;
    align-items: center;
    gap: 4px;
    input{
        margin: 0;
    }
}
</style>


