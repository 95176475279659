var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "label",
        {
          staticClass: "form_label",
          class: { error: _vm.$v.item.title.$error }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.label.title)) + "\n      "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.title,
                expression: "item.title"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.item.title },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "title", $event.target.value)
                },
                function($event) {
                  return _vm.validateInput({
                    field: "title",
                    value: _vm.item.title
                  })
                }
              ]
            }
          }),
          _vm._v(" "),
          _vm.$v.item.title.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1"
                      )
                    ) +
                    "\n          " +
                    _vm._s(_vm.constraint.title_limit) +
                    "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2"
                      )
                    ) +
                    "\n      "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "form_label",
          class: { error: _vm.$v.item.subtitle.$error }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.$t(_vm.label.subtitle)) + "\n      "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.item.subtitle,
                expression: "item.subtitle"
              }
            ],
            attrs: { name: "", id: "", cols: "20", rows: "6" },
            domProps: { value: _vm.item.subtitle },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.item, "subtitle", $event.target.value)
                },
                function($event) {
                  return _vm.validateInput({
                    field: "subtitle",
                    value: _vm.item.subtitle
                  })
                }
              ]
            }
          }),
          _vm._v(" "),
          _vm.$v.item.subtitle.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1"
                      )
                    ) +
                    "\n          " +
                    _vm._s(_vm.constraint.subtitle_limit) +
                    "\n          " +
                    _vm._s(
                      _vm.$t(
                        "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2"
                      )
                    ) +
                    "\n      "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.actionButtonlabel, function(actionBtn, btnIndex) {
        return [
          actionBtn
            ? _c("div", { key: "actionBtn" + btnIndex }, [
                actionBtn.checkbox && !actionBtn.hideCheckbox
                  ? _c("label", { staticClass: "form_label check_label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.actionButtons[btnIndex].visibility,
                            expression:
                              "item.actionButtons[btnIndex].visibility"
                          }
                        ],
                        attrs: { type: "checkbox", name: "", id: "" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.item.actionButtons[btnIndex].visibility
                          )
                            ? _vm._i(
                                _vm.item.actionButtons[btnIndex].visibility,
                                null
                              ) > -1
                            : _vm.item.actionButtons[btnIndex].visibility
                        },
                        on: {
                          change: function($event) {
                            var $$a =
                                _vm.item.actionButtons[btnIndex].visibility,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.item.actionButtons[btnIndex],
                                    "visibility",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.item.actionButtons[btnIndex],
                                    "visibility",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.item.actionButtons[btnIndex],
                                "visibility",
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t(actionBtn.checkbox)) +
                          "\n          "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                actionBtn.button && _vm.item.actionButtons[btnIndex].visibility
                  ? _c(
                      "label",
                      {
                        staticClass: "form_label",
                        class: {
                          error:
                            _vm.$v.item.actionButtons.$each[btnIndex].title
                              .$error
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t(actionBtn.button)) +
                            "\n              "
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.actionButtons[btnIndex].title,
                              expression: "item.actionButtons[btnIndex].title"
                            }
                          ],
                          attrs: { type: "text", name: "", id: "" },
                          domProps: {
                            value: _vm.item.actionButtons[btnIndex].title
                          },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item.actionButtons[btnIndex],
                                  "title",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.validateInput({
                                  field: "actionButtons",
                                  value: _vm.item.actionButtons[btnIndex].title
                                })
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _vm.$v.item.actionButtons.$each[btnIndex].title.$error
                          ? _c("span", { staticClass: "message" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t(
                                      "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1"
                                    )
                                  ) +
                                  "\n                  " +
                                  _vm._s(_vm.constraint.button_title_limit) +
                                  "\n                  " +
                                  _vm._s(
                                    _vm.$t(
                                      "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2"
                                    )
                                  ) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }