<template>
  <div class="multiselectDropdown font_regular font_500 text_12" :class="{ error: error, [classNameLabel]: true }">
        {{ label }}
        <span v-if="infoList.length" class="tooltip_info">
            <fluent-icon class="help_icon" icon="question-circle" />
            <div class="tooltip_card">
                <p v-for="(line,index) in infoList" class="mb_0 font_12" :key="index">{{line}}</p>
            </div>
        </span>
        <div class="dropdown_slot">
            <slot></slot>
        </div>
        <!-- <multiselect /> -->
        <span v-if="error" class="message text_12 font_500">
            {{error}}
        </span>
        <span v-else-if="hint" class="hint font_regular text_12 font_500 text_black_secondary">
            {{hint}}
        </span>
    </div>
</template>

<script>
export default {
    props:{
        label: {
            type: String,
            default: '',
        },
        infoList:{
            type: Array,
            default: ()=>[],
        },
        classNameLabel:{
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
        hint:{
            type: String,
            default: '',
        },
    },
    methods:{},
}
</script>



<style scoped lang="scss">
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.dropdown_slot{
    padding-top: 8px;
}

.help_tooltip{
    position: relative;
}
.help_icon{
    position: absolute;
    top: 0;
    left: 8px;
    width: 15px;
    height: 15px;
}

.tooltip_info{
    position: relative;
    // max-width: 500px;
    &:hover {
        .tooltip_card {
            display: block;
        }
    }
    .tooltip_card{
        display: none;
        position: absolute;
        bottom: 12px;
        left: -17px;
        width: 100%;
        z-index: 1;
        min-width: 300px;
        padding: 5px 5px 5px 22px;
        background: #000;
        color: #fff;
        border-radius: 4px;
    }

}
.error{

}
.hint{
    display: block;
    margin-top: -1.6rem;
}
</style>

<style>
.dropdown_slot .multiselect  .multiselect__single{
    font-size: 14px;
}
</style>
