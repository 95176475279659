var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "coupons-table-wrap" },
    [
      _c("ve-table", {
        attrs: {
          columns: _vm.columns,
          "scroll-width": "190rem",
          "table-data": _vm.tableData,
          "border-around": true
        }
      }),
      _vm._v(" "),
      _vm.showEmptyResult
        ? _c("empty-state", { attrs: { title: _vm.emptyMessage } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "campaign--loader" },
            [
              _c("spinner"),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("CAMPAIGN.LIST.LOADING_MESSAGE")))
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }