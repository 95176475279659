<template>
  <div class="view-box columns">
      <!-- :header-title="$t(headerTitle)" -->
    <catalogue-header
      button-route="new"
      :icon="icon"
      :header-title="headerTitle"
      :show-back-button="showBackButton"
      :back-url="backUrl"
    />
    <keep-alive v-if="keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import CatalogueHeader from './CatalogueHeader.vue';
/* eslint no-console: 0 */




export default {
  components: {
    CatalogueHeader

  },
  props: {
    headerTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    keepAlive: {
      type: Boolean,
      default: true,
    },

    showBackButton: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
  },

  mounted() {
    // if user not allowed to order, then never visit this page
    const user = Cookies.getJSON('user') || {};
    if (user.order?.toString() !== "true") {
      console.log("User not allowed to create order",user);
      this.$router.push(`/app/accounts/${user.account_id}/dashboard`);
    }
  },


};
</script>
