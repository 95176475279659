<template>
  <label class="input_field font_regular font_500 text_12 mb_16" :class="{ error: error, [classNameLabel]: true }">
        {{ label }}
        <span v-if="infoList.length" class="tooltip_info">
            <fluent-icon class="help_icon" :icon="infoIcon" />
            <div class="tooltip_card">
                <p v-for="(line,index) in infoList" class="mb_0 font_12" :key="index">{{line}}</p>
            </div>
        </span>
        <input
            ref="inputField"
            :value="value"
            :type="type"
            class="text_14 font_500 pt_10 Pb_10 pl_12 pr_12 mt_8"
            :class="[className].join(' ')"
            :placeholder="placeholder"
            @input="onInput"
            @change="onChange"
            @blur="onBlur"
        />
        <span v-if="error" class="message text_12 font_500">
            {{error}}
        </span>
        <span v-else-if="hint" class="hint font_regular text_12 font_500 text_black_secondary">
            {{hint}}
        </span>
        <span v-if="postFix" class="postfix text_14" :style="{ left: postFixLeft  + 'px' }">{{postFix}}</span>
    </label>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            required: true,
        },
        error: {
            type: String,
            default: '',
        },
        className:{
            type: String,
            default: '',
        },
        classNameLabel:{
            type: String,
            default: '',
        },
        infoIcon:{
            type: String,
            default: 'question-circle',
        },
        infoList:{
            type: Array,
            default: [],
        },
        hint:{
            type: String,
            default: '',
        },
        textLength:{
            type: String,
            default: '',
        },
        postFix:{
            type: [String,Number],
            default: '',
        },
    },
    data() {
        return {
        };
    },
    computed:{
        postFixLeft(){
            if (!this.textLength || !this.postFix) {
                return 80;
            }
            const base = 20;
            // return base + (this.textLength || 0)*7;

            let inputWidth = this.$refs.inputField ? this.$refs.inputField.offsetWidth : 0;
            if (inputWidth > 0) {
                inputWidth -= 35
            }
            let textWidth = this.textLength?.length > 0 ? this.getTextWidth(`${this.textLength}`): 50;
            console.log({inputWidth,textWidth});
            if (textWidth > inputWidth) {
                textWidth = inputWidth;
            }

            return base + textWidth;
        },
    },
    methods: {
        onChange(event) {
            this.$emit('input', event.target.value);
        },
        onInput(event) {
            this.$emit('input', event.target.value);
        },
        onBlur(event) {
            this.$emit('blur', event.target.value);
        },
        getTextWidth(text,) {
            // Create an offscreen canvas element
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            // Set the font for the context
            context.font = "14px Figtree";

            // Measure the text width
            const textWidth = context.measureText(text).width;

            return textWidth;
        },
    },
}
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.input_field{
    position: relative;
    input{
        min-height: 4.2rem;
        border-color: #EEEEEE;
    }
}


.help_tooltip{
    position: relative;
}
.help_icon{
    position: absolute;
    top: 0;
    left: 8px;
    width: 15px;
    height: 15px;
}

.tooltip_info{
    position: relative;
    // max-width: 500px;
    &:hover {
        .tooltip_card {
            display: block;
        }
    }
    .tooltip_card{
        display: none;
        position: absolute;
        bottom: 12px;
        left: -17px;
        width: 100%;
        z-index: 1;
        min-width: 300px;
        padding: 5px 5px 5px 22px;
        background: #000;
        color: #fff;
        border-radius: 4px;
    }

}
.hint{
    display: block;
    margin-top: -1.6rem;
}
.postfix{
    position: absolute;
    top: 37px;
    // left: 26px;
}
</style>
