import { frontendURL } from '../../../../helper/URLHelper';

const catalogues = accountId => ({
  parentNav: 'catalogues-nav',
  routes: [
    // 'catalogues_home',
    'catalogues_list',
    'catalogues_orders',
    'catalogue_order_details',
    'catalogue_settings',
    'coupons_list',
    'add_coupon',
    'catalog_reports',
  ],
  menuItems: [
    {
      // add the icon with name as outline in "app\javascript\shared\components\FluentIcon\dashboard-icons.json"
      // real icon name with outline = "contact-card-group-outline"
      icon: 'box_3d',
      viewBox: '0 0 18 18',
      label: 'CATALOGUE_LIST',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/catalog/list`),
      toStateName: 'catalogues_list',
    },
    {
      icon: 'list_plain',
      viewBox: '0 0 16 12',
      label: 'CATELOGUE_ORDERS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/catalog/orders`),
      toStateName: 'catalogues_orders',
    },
    {
      icon: 'coupon',
      viewBox: '0 0 16 12',
      label: 'CATELOGUE_COUPONS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/catalog/coupons`),
      toStateName: 'coupons_list',
    },
    {
      icon: 'settings',
      label: 'CATELOGUE_SETTINGS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/catalog/settings`),
      toStateName: 'catalogue_settings',
    },
    {
      icon: 'graph_bar',
      viewBox: '0 0 16 16',
      label: 'CATELOG_REPORTS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/catalog/reports`),
      toStateName: 'catalog_reports',
    },

  ],
});

export default catalogues;

