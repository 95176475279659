<template>
  <div>
    <multiselect
        class="category_list"
        :value="value"
        :options="formattedOptions"
        track-by="id"
        label="title"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :hide-selected="true"
        :placeholder="$t('CATALOGUE.TOOLBAR.CATAGORY_PLACEHOLDER')"
        selected-label
        :select-label="''"
        :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
        @input="$emit('input', $event)"
        @select="onSelect"
        @remove="$emit('remove', $event)"
    >
        <!-- group-label="group_title"
        group-values="sub_group"
        :group-select="false"
        id="option-groups" -->
        <template slot="beforeList">
            <div class="multiselect__option multiselect__option--group font_500 text_14 text_white create_option" @click="onAddModalOpen">
                {{$t('CATALOGUE.CATEGORY.CREATE_OPTION')}}
            </div>
        </template>
        <template slot="option" slot-scope="{ option }">
            <div  v-if="!option.$isLabel" class="ps_multiselect_option font_regular font_500 text_14">
                <span>
                    {{ option.title }}
                    <small class="text_black_secondary" @click.stop="deleteCategory(option)">
                        <fluent-icon icon="delete" class="order_hint_mute" />
                    </small>
                </span>
            </div>
            <div v-if="option.$isLabel" class="ps_multiselect_group font_regular font_500 text_12">
                <span>{{option.$groupLabel}}</span>
            </div>
        </template>

    </multiselect>
    <AddCategoryModal
        :onClose="onAddModalClose"
        :isOpen="isAddModalOpen"
    />
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import AddCategoryModal from "./AddCategoryModal.vue";



export default {
    components:{
        AddCategoryModal,
    },
    mixins: [alertMixin],
    props:{
        value:{
            type: [String, Object, Array],
            default: ""
        },
        options:{
            type: Array,
            default: []
        }
    },
    computed:{
        formattedOptions(){
            return this.options;
            // return [
            //     {
            //         group_title: 'CustomAction',
            //         sub_group: [
            //             {id: 'open_modal', title:"Create Item",},
            //         ]
            //     },
            //     {
            //         group_title: 'Javascript',
            //         sub_group: [
            //             {id: 'Vue.js', title: 'Front-end'},
            //             {id: 'Adonis', title: 'Backend'}
            //         ]
            //     },
            // ]
        },
    },
    data(){
        return {
            isAddModalOpen: false,
        }
    },
    methods:{
        async onSelect(item){
            this.$emit('select', item);
        },
        async deleteCategory(selectedTag) {
            const deleteCategoryPayload = {
                id: selectedTag.id,
            };
            try {
                await this.$store.dispatch("catalog/deleteCategory",deleteCategoryPayload)
                this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.DELETE_SUCCESS'));
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('CATALOGUE.CRUD.CATAGORY.DELETE_FAIL'));
            }
        },
        onAddModalOpen(){
            this.isAddModalOpen = true;
        },
        onAddModalClose(){
            this.isAddModalOpen = false;
        },
    }
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';


</style>

<style scoped>
.category_list .ps_multiselect_option span{
    position: relative;
}
.category_list .ps_multiselect_option span small{
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
}
</style>

<style>
.category_list .multiselect__select,
.category_list .multiselect__tags{
    min-height: 4rem;
}
.category_list .multiselect__tags .multiselect__tag{
    margin-top: 2px;
    margin-bottom: 0;
}
.category_list .multiselect__placeholder{
    padding-top: 1rem;
    margin-bottom: 0;
}
.category_list .create_option {
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: #8D25F6 !important;
    color: #fff !important;
}
</style>
