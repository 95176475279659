<template>
  <div :class="classWrapper">
    <div v-for="(option, index) in options" :key="index" class="radio-item">
        <div>
            <label class="radio_wrap" for="">
                <input
                type="radio"
                :value="index"
                v-model="selectedIndex"
                :disabled="option.disable || false"
                @change="emitSelection"
                />
            </label>
        </div>
        <div>
            <p class="radio_label_wrap"><b class="radio_label">{{ option.label }}</b></p>
            <p v-if="option.description" class="radio_description">{{ option.description }}</p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioList',
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      default: null,
    },
    classWrapper: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      selectedIndex: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.selectedIndex = newValue;
    },
    selectedIndex(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    emitSelection() {
      this.$emit('change', this.selectedIndex);
    },
  },
};
</script>

<style scoped lang="scss">
.radio-item {
    display: flex;
    /* align-items: center; */
    margin-bottom: 10px;
    gap: 10px;

    .radio_wrap{
        margin-top: 2px;
    }

    label {
        display: flex;
        align-items: center;
    }

    input {
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid var(--w-500);
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        position: relative;
    }

}




.radio-item input:checked {
  background-color: white;
}


.radio-item input:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  // background: var(--w-500);
  background: #8D25F6;
  transform: translate(-50%, -50%);
}


.main-label {
  font-weight: bold;
  margin-right: 5px;
}

.description {
  color: #aaa; /* Faded color */
  font-size: 0.9em; /* Slightly smaller font size */
}
</style>

