import { frontendURL } from '../../../../helper/URLHelper';

const primaryMenuItems = accountId => [
  {
    icon: { name: 'menu.svg', isIcon: false },
    key: 'goal-dashboard',
    label: 'DASHBOARD',
    toState: frontendURL(`accounts/${accountId}/target-dashboard/goal`),
    toStateName: 'goal_dashboard',
    roles: ['administrator'],
  },
  {
    icon: { name: 'chats-circle.svg', isIcon: false },
    key: 'conversations',
    label: 'Chat',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: { name: 'agents.svg', isIcon: false },
    key: 'contacts',
    label: 'CONTACT',
    toState: frontendURL(`accounts/${accountId}/contacts`),
    toStateName: 'contacts_dashboard',
    roles: ['administrator','agent'],
  },
  {
    icon: { name: 'rule.svg', isIcon: false },
    key: 'campaigns',
    label: 'CAMPAIGN',
    toState: frontendURL(`accounts/${accountId}/campaigns/one_off`),
    toStateName: 'settings_account_campaigns',
    roles: ['administrator'],
  },
  {
    icon: { name: 'task-list-square-right.svg', isIcon: false },
    key: 'catalogues',
    label: 'CATALOG',
    toState: frontendURL(`accounts/${accountId}/catalog`),
    toStateName: 'catalogues_state',
    roles: ['administrator','agent'],
  },
  {
    icon: { name: 'configuration.svg', isIcon: false },
    key: 'settings',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings`),
    toStateName: 'settings_home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: { name: 'dashboard.svg', isIcon: false },
    key: 'reports',
    label: 'REPORT',
    toState: frontendURL(`accounts/${accountId}/reports`),
    toStateName: 'settings_account_reports',
    roles: ['administrator'],
  },
  {
    icon: { name: 'question-mark.svg', isIcon: false },
    key: 'docs',
    label: 'DOCUMENT',
    toState: `https://peasy.ai/docs/peasysales`,
    toStateName: 'documentation',
    roles: ['administrator', 'agent'],
  },


  {
    icon: { name: 'creditcard-toolbox.svg', isIcon: false },
    key: 'credit_balance',
    label: 'CREDIT BALANCE',
    toState: frontendURL(`accounts/${accountId}/credit/credit_balance`),
    toStateName: 'credit_home',
    roles: ['administrator',],
  },


  {
    icon: { name: 'pm-icon.png', isIcon: false },
    key: 'external-link',
    label: '',
    toState: 'pm-external',
    toStateName: 'external_link',
    roles: ['administrator'],
  },
];

export default primaryMenuItems;
