var api = require("!../../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--3-1!../../../../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../../../node_modules/postcss-loader/src/index.js??ref--3-2!../../../../../../../node_modules/sass-loader/dist/cjs.js??ref--3-3!../../../../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./TextExpressEdit.vue?vue&type=style&index=0&id=e3f0cfc8&lang=scss&scoped=true&");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};