var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column Xcontent-box" },
    [
      _c("nav", [
        _c("ul", { staticClass: "campaignNav" }, [
          _c(
            "li",
            {
              staticClass: "campaignNav_item font_regular text_14",
              class:
                _vm.campaignActiveNav == "one_off"
                  ? "campaignNav_item campaignNav_itemActive"
                  : "",
              on: {
                click: function($event) {
                  return _vm.campaignNavToggle("one_off")
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CAMPAIGN.TABS.ONE_OFF")) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "campaignNav_item font_regular text_14",
              class:
                _vm.campaignActiveNav == "running"
                  ? "campaignNav_item campaignNav_itemActive"
                  : "",
              on: {
                click: function($event) {
                  return _vm.campaignNavToggle("running")
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CAMPAIGN.TABS.REPEAT")) +
                  "\n      "
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "search_container" },
          [
            _c("SearchInput", {
              attrs: {
                placeholder: _vm.$t("CAMPAIGN.FILTERS.SEARCH_PLACEHOLDER")
              },
              on: { search: _vm.onSearch },
              model: {
                value: _vm.searchValue,
                callback: function($$v) {
                  _vm.searchValue = $$v
                },
                expression: "searchValue"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "campaing_filters" }, [
          _c(
            "div",
            { staticClass: "campaing_filter" },
            [
              _c("p", { staticClass: "font_regular font_500 text_12" }, [
                _vm._v(_vm._s(_vm.$t("CAMPAIGN.FILTERS.DATE")))
              ]),
              _vm._v(" "),
              _c("date-range-picker", {
                staticClass: "font_normal",
                attrs: {
                  "confirm-text": _vm.$t("CAMPAIGN.FILTERS.DATE_CONFIRM_BTN"),
                  placeholder: _vm.$t("CAMPAIGN.FILTERS.DATE_PLACEHOLDER"),
                  value: _vm.dateRange,
                  disabledDate: _vm.disableFilterDate
                },
                on: { change: _vm.handleDateChange }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "campaing_filter" },
            [
              _c("p", { staticClass: "font_regular font_500 text_12" }, [
                _vm._v(_vm._s(_vm.$t("CAMPAIGN.FILTERS.STATUS")))
              ]),
              _vm._v(" "),
              _c("multiselect", {
                staticClass: "small-12 font_normal",
                attrs: {
                  options: _vm.statusList,
                  "track-by": "id",
                  label: "title",
                  multiple: false,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "hide-selected": true,
                  placeholder: _vm.$t("CAMPAIGN.FILTERS.STATUS_PLACEHOLDER"),
                  "selected-label": "",
                  "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                  "deselect-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_REMOVE")
                },
                on: {
                  select: function($event) {
                    return _vm.applyFilterByStatuses($event)
                  }
                },
                model: {
                  value: _vm.selectedStatus,
                  callback: function($$v) {
                    _vm.selectedStatus = $$v
                  },
                  expression: "selectedStatus"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "campaing_filter" },
            [
              _c("p", { staticClass: "font_regular font_500 text_12" }, [
                _vm._v(_vm._s(_vm.$t("CAMPAIGN.FILTERS.SORT_BY")))
              ]),
              _vm._v(" "),
              _c("multiselect", {
                staticClass: "small-12 font_normal",
                attrs: {
                  options: _vm.sortByList,
                  "track-by": "id",
                  label: "title",
                  multiple: false,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "hide-selected": true,
                  placeholder: _vm.$t("CAMPAIGN.FILTERS.SORTBY_PLACEHOLDER"),
                  "selected-label": "",
                  "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                  "deselect-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_REMOVE")
                },
                on: {
                  select: function($event) {
                    return _vm.applyFilterBySortBy($event)
                  }
                },
                model: {
                  value: _vm.sortBy,
                  callback: function($$v) {
                    _vm.sortBy = $$v
                  },
                  expression: "sortBy"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("campaigns-table", {
            attrs: {
              campaigns: _vm.campaigns,
              "show-empty-result": _vm.showEmptyResult,
              "is-loading": _vm.uiFlags.isFetching,
              "campaign-type": _vm.type,
              isCampaignType: _vm.campaignActiveNav
            },
            on: {
              "on-edit-click": _vm.openEditPopup,
              "on-delete-click": _vm.openDeletePopup
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("table-footer", {
            attrs: {
              "on-page-change": _vm.onPageChange,
              "current-page": Number(_vm.meta.currentPage),
              "total-count": _vm.meta.count
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showEditPopup, "on-close": _vm.hideEditPopup },
          on: {
            "update:show": function($event) {
              _vm.showEditPopup = $event
            }
          }
        },
        [
          _c("edit-campaign", {
            attrs: { "selected-campaign": _vm.selectedCampaign },
            on: { "on-close": _vm.hideEditPopup }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("CAMPAIGN.DELETE.CONFIRM.TITLE"),
          message: _vm.$t("CAMPAIGN.DELETE.CONFIRM.MESSAGE"),
          "confirm-text": _vm.$t("CAMPAIGN.DELETE.CONFIRM.YES"),
          "reject-text": _vm.$t("CAMPAIGN.DELETE.CONFIRM.NO")
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }