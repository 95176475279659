<template>
  <div>
    <div class="add_campaign_container">
        <div class="campaign_heading">
            <h2>{{ $t('CAMPAIGN.ADD_STEP.TITLE') }}</h2>
            <woot-button class="" icon='dismiss' variant="clear" colorScheme="secondary" @click="discardBroadcast" />
        </div>
        <div class="step_range_continer">
            <p>
                {{step}}
                {{ $t('CAMPAIGN.ADD_STEP.RANGE_BAR.OF') }}
                {{max_steps}}
                {{ $t('CAMPAIGN.ADD_STEP.RANGE_BAR.SELECT_TEXT') }}
            </p>
            <RangeInput :disabled="true" :min="0" :max="max_steps" :value="step" :id="'range_step'" />
        </div>
        <div v-if="step == 1">
            <ul class="campaign_types">
                <li v-for="item in types" class="campaign_type" :class="campaign_type===item.value ? 'active_type':''" :key="item.title" @click="selectCampaignType(item)">
                    <div class="type_icon">
                        <div v-html="item.icon"></div>
                    </div>
                    <div class="type_body">
                        <h3 class="name">{{item.title}}</h3>
                        <p class="description">{{item.description}}</p>
                    </div>
                </li>
            </ul>
        </div>
        <div v-if="step === 2" class="step_container">
            <section>
                <!-- Audience filter logic -->
                <article>
                    <section>
                        <label
                            class="field_label"
                            :class="{ error: $v.appliedFilters.$error }"
                        >{{ $t('CAMPAIGN.ADD_STEP.ONE_OFF.AUDIENCE_LABEL') }}</label>
                        <div class="medium-12 columns audience_filter" v-if="appliedFilters.length">
                            <filter-input-box
                            v-for="(filter, i) in appliedFilters"
                            :key="i"
                            v-model="appliedFilters[i]"
                            :filter-groups="filterGroups"
                            :grouped-filters="true"
                            :input-type="getInputType(appliedFilters[i].attribute_key)"
                            :operators="getOperators(appliedFilters[i].attribute_key)"
                            :dropdown-values="getDropdownValues(appliedFilters[i].attribute_key)"
                            :show-query-operator="i !== appliedFilters.length - 1"
                            :show-user-input="showUserInput(appliedFilters[i].filter_operator)"
                            :v="$v.appliedFilters.$each[i]"
                            reOrderLabels = "false"
                            @resetFilter="resetFilter(i, appliedFilters[i])"
                            @removeFilter="removeFilter(i)"
                            />
                            <div class="filter-actions mt-4">
                            <woot-button
                                icon="add"
                                class="add_campaign_filter"
                                color-scheme="primary"
                                variant="clear"
                                size="small"
                                @click="appendNewFilter"
                            >{{ $t('CONTACTS_FILTER.ADD_NEW_FILTER') }}</woot-button>
                            </div>
                        </div>
                    </section>
                </article>

                <!-- days type filter group type=running -->
                <section v-if="campaign_type === 'running'">
                    <label :class="{ error: $v.appliedFilters.$error }">
                        {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.CUSTOM_LABEL') }}
                    </label>
                    <div class="medium-12 columns audience_filter">
                        <filter-input-box
                            v-for="(filter, i) in [appliedFilters[0]]"
                            :key="i"
                            v-model="campaign_repeat_at"
                            :filter-groups="onGoingDateFilterGroups"
                            :grouped-filters="true"
                            input-type=""
                            :operators="onGoingOperators"
                            :dropdown-values="getDropdownValues(appliedFilters[i].attribute_key)"
                            :show-query-operator="false"
                            :show-user-input="showUserInput(appliedFilters[i].filter_operator) ? true:true"
                            :v="$v.appliedFilters.$each[i]"
                            reOrderLabels = "true"
                        />
                    </div>
                </section>


                <!-- Schedule time date selector -->
                <article v-if="campaign_type === 'one_off'" class="add_camp_calendar">
                    <label class="field_label">
                        {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
                    </label>
                    <woot-date-time-picker
                        :value="scheduledAt"
                        style="margin-top:'8px'"
                        :disable-dates="0"
                        :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
                        :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
                        displayFormat="D MMM YYYY HH:mm:ss"
                        @change="onChange"
                    />
                </article>

                <!-- schedule time Day selector when type=running  -->
                <label v-if="campaign_type === 'running'" class="add_camp_calendar">
                    {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
                    <woot-date-time-picker
                        :value="scheduledAt"
                        :disable-dates="0"
                        :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
                        :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
                        isTimeOnly="true"
                        @change="onChange"
                    />
                </label>

            </section>
        </div>
        <div v-if="step === 3">
            <article :class="{ error: $v.selectedInbox.$error }">
                <div class="font_regular text_12 font_500">
                    {{ $t('CAMPAIGN.ADD_STEP.ONE_OFF.INBOX.LABEL') }}
                </div>
                <select
                    v-model="selectedInbox"
                    class="font_regular text_14 font_500"
                    style="margin-top:8px;"
                    @change="onChangeInbox($event)"
                >
                      <option disabled :value="null">{{ $t('CAMPAIGN.ADD_STEP.ONE_OFF.INBOX.PLACEHOLDER') }}</option>
                    <option v-for="item in inboxes" :key="item.name" :value="item">{{ item.name }}</option>
                </select>
                <span
                    v-if="$v.selectedInbox.$error"
                    class="message"
                >{{ $t('CAMPAIGN.ADD_STEP.ONE_OFF.INBOX.ERROR') }}</span>
            </article>

            <div class="campaint_title_wrap" :class="{cann_margin: !$v.title.$error}">
                <woot-input
                    v-model="title"
                    :label="isEmailTemplateAllow ? $t('CAMPAIGN.ADD.FORM.EMAIL_SUBJECT.LABEL') :$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
                    type="text"
                    class="campaign_title  font_500 text_12"
                    :class="{ error: $v.title.$error,}"
                    :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
                    :placeholder="isEmailTemplateAllow ? $t('CAMPAIGN.ADD.FORM.EMAIL_SUBJECT.PLACEHOLDER') : $t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
                    @blur="$v.title.$touch"
                />
                <!-- if email, sow email subject note & place holder-->
                <p v-if="!$v.title.$error" class="campaign_title_note">
                    {{
                        isEmailTemplateAllow ? $t('CAMPAIGN.ADD.FORM.EMAIL_SUBJECT.NOTE') : $t('CAMPAIGN.ADD.FORM.TITLE.NOTE')
                    }}

                </p>
            </div>

            <article class="canned_msg_wrap">
                <div class="canned_msg font_regular text_12 font_500" :class="{ error: $v.message.$error }">
                    {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
                    <div
                        v-if="selectedTemplateHtml"
                        v-html="selectedTemplateHtml"
                        style="height: 300px; overflow-y: scroll; margin-bottom: 10px; margin-top: 10px;"
                    ></div>
                    <canned-response
                        v-if="showMentions && hasSlashCommand && !selectedTemplateHtml"
                        v-on-clickaway="hideMentions"
                        top="37"
                        :search-key="mentionSearchKey"
                        @click="replaceText"
                    />
                    <textarea
                        v-if="!selectedTemplateHtml"
                        v-model="message"
                        rows="5"
                        type="text"
                        :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
                        @blur="$v.message.$touch"
                    />
                    <span v-if="$v.message.$error" class="message">{{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}</span>
                </div>
                <!-- btns: Email template, Choose coupone -->
                <div class="modal_btns">
                    <button v-if="isEmailTemplateAllow" class="font_regular text_purple_primary font_500 text_12" @click="openEmailTemplateListModal">
                        {{ $t('CAMPAIGN.ADD_STEP.BTNS.EMAIL_TPL') }}
                    </button>
                    <!-- <button class="font_regular text_purple_primary font_500 text_12">{{ $t('CAMPAIGN.ADD_STEP.BTNS.COUPON') }}</button> -->
                </div>
            </article>

            <AudienceAndTopup
                :broadcast_limit="broadcast_limit"
                :campaign_type="campaign_type"
                :eligible_audience="eligible_audience"
                :getCampaignDetails="getCampaignDetails"
                :onChangeBroadcastLimit="onChangeBroadcastLimit"
            />
        </div>

        <!-- <div v-else-if="campaign_type === 'running'">
            <section>Running steps</section>
        </div> -->
        <div class="campaign_btns">
            <woot-button
            class="campaign_btn"
            :isDisabled="isDisableBackBtn"
            variant="clear"
            @click="handleBack"
            >
            {{ $t('CAMPAIGN.ADD_STEP.BTNS.BACK') }}
            </woot-button>
            <woot-button
            class="campaign_btn campaign_btn_solid"
            :isDisabled="isDisableNextBtn"
            @click="handleNext"
            >
            {{ $t('CAMPAIGN.ADD_STEP.BTNS.NEXT') }}
            </woot-button>
        </div>
    </div>
    <email-template-list
      v-if="isOpenEmailTemplateListModal"
      @close="closeEmailTemplateListModal"
      @selectTemplate="selectTemplate"
    />
    <woot-modal :show.sync="isPreviewPopupOpen" :on-close="hidePreviewPopup">
      <add-campaign
        :initial-filter-types="filterTypes"
        :initialForm="initialForm"
        :onDone="onDiscardBroadcast"
        @on-close="hidePreviewPopup"
      />
    </woot-modal>
    <StripeComponent
        :modal-value="isTopupModal"
        @close-modal="closeTopup"
    />
    <woot-modal :show.sync="isDiscardOpen" :on-close="closeDiscardBroadcast" modalMainContainerClass="add_campaign_discard">
      <div>
        <div class="discard_icon">
            <div v-html="discardIcon"></div>
        </div>
        <h2 class="font_regular font_700 text_20 d_title">{{ $t('CAMPAIGN.ADD_STEP.DISCARD_MODAL.TITLE') }}</h2>
        <p class="d_description font_regular font_500 text_14">{{ $t('CAMPAIGN.ADD_STEP.DISCARD_MODAL.DESCRIPTION') }}</p>
        <div class="discard_btns">
            <woot-button
            color-scheme="alert"
            variant="clear"
            @click="onDiscardBroadcast"
            >
            {{ $t('CAMPAIGN.ADD_STEP.DISCARD_MODAL.YES_BTN') }}
            </woot-button>
            <woot-button
            variant="clear"
            color-scheme="secondary"
            @click="closeDiscardBroadcast"
            >
            {{ $t('CAMPAIGN.ADD_STEP.DISCARD_MODAL.NO_BTN') }}
            </woot-button>
        </div>
      </div>
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, url, minLength, requiredIf } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import filterMixin from 'shared/mixins/filterMixin';
import { mixin as clickaway } from 'vue-clickaway';
import RangeInput from "../../../../components/ui/RangeInput.vue"
import {svgIcons} from "../../../../../shared/components/FluentIcon/svg_icons"
import FilterInputBox from '../../../../components/widgets/FilterInput/Index.vue';
import contactFilterItems from '../../contacts/contactFilterItems';
import countries from '../../../../../shared/constants/countries';
import { filterAttributeGroups } from '../../contacts/contactFilterItems';
import * as OPERATORS from '../../../../components/widgets/FilterInput/FilterOperatorTypes';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import CannedResponse from '../../../../components/widgets/conversation/CannedResponse';
import CampaignAPI from "../../../../api/campaigns"
import EmailTemplateList from './EmailTemplates/EmailTemplateList.vue';
import AddCampaign from './AddCampaign';
import filterQueryGenerator from '../../../../helper/filterQueryGenerator';
import AudienceAndTopup from "./AudienceAndTopup.vue"
import StripeComponent from 'dashboard/components/stripe/StripeComponent';

/*
    type=ONE_OFF & step 1: select type
    type=ONE_OFF & step 2: select audience & timing
    type=ONE_OFF & step 3: set message
    ----------------------------------
    type=REPEATING & step 1: select type
    type=REPEATING & step 2: select audience & timing
    type=REPEATING & step 3: set message
*/
const CAMP_TYPES = {
    ONE_OFF: "one_off",
    REPEATING: "running",
    max_step:{
        one_off: 3,
        repeating: 3,
    }
}
// const initialFilterTypes = ;
export default {
    components:{
        FilterInputBox,
        RangeInput,
        WootDateTimePicker,
        CannedResponse,
        EmailTemplateList,
        AddCampaign,
        AudienceAndTopup,
        StripeComponent,
    },
    mixins: [alertMixin, campaignMixin, filterMixin, clickaway],
    data(){
        return {
            isPreviewPopupOpen: false,
            isDiscardOpen: false,
            title: '',
            message: '',
            broadcast_limit: 0,
            mentionSearchKey: '',
            campaign_type: "",
            step: 1,
            types: [
                // make a separate file for custom svgs and render as v-html
                {
                    value: CAMP_TYPES.ONE_OFF,
                    title: this.$t('CAMPAIGN.ADD_STEP.ONE_OFF.TYPE_NAME'),
                    description: this.$t('CAMPAIGN.ADD_STEP.ONE_OFF.DESCRIPTION'),
                    icon: svgIcons.oneOff({height:60,width:60}),
                },
                {
                    value: CAMP_TYPES.REPEATING,
                    title: this.$t('CAMPAIGN.ADD_STEP.REPEAT.TYPE_NAME'),
                    description: this.$t('CAMPAIGN.ADD_STEP.ONE_OFF.DESCRIPTION'),
                    icon: svgIcons.repeat({height:60,width:60}),
                },
            ],
            appliedFilters: [],
            filterGroups: [],
            filterTypes: contactFilterItems.map(filter => ({
                ...filter,
                attributeName: this.$t(`CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`),
            })),
            filterAttributeGroups,
            attributeModel: 'contact_attribute',
            filtersFori18n: 'CONTACTS_FILTER',
            scheduledAt: null,
            selectedInbox: null,
            showMentions: false,
            hasSlashCommand: false,
            channel_type: '',
            templateName: '',
            isTopupModal: false,
            isBroadcasetLimitOpen: false,
            campaign_repeat_at:{},
            onGoingOperators: [
                { label: "", value: "equal_to"},
                { label: "", value: "is_greater_than"},
                { label: "", value: "is_lesser_than"},
            ],
            onGoingDateFilterGroups: [],
            eligible_audience:{
                audience: 0,
                costs: 0,
                max_audience: 0,
                available_credit: 0,
                isFetched: false,
                isAllowModify: false,
            },
            isOpenEmailTemplateListModal: false,
            selectedTemplateHtml:"",
            discardIcon: svgIcons.alert({height:60,width:60})
        }
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            // uiFlags: 'campaigns/getUIFlags',
            // audienceList: 'labels/getLabels',
            getAppliedContactFilters: 'contacts/getAppliedContactFilters',
            contactStatus: 'accounts/getContactStatus',
        }),
        max_steps(){
            if (this.campaign_type === CAMP_TYPES.ONE_OFF) {
                return CAMP_TYPES.max_step.one_off
            }
            if (this.campaign_type === CAMP_TYPES.REPEATING) {
                return CAMP_TYPES.max_step.repeating
            }
            return CAMP_TYPES.max_step.one_off;
        },
        isDisableNextBtn(){
            // extra: email template & audience count input
            const commonPayload = {
                appliedFilters: this.appliedFilters,
                selectedInbox: this.selectedInbox,
                title: this.title,
                message: this.message,
                scheduledAt: this.scheduledAt,
            }
            // step 1: is campaign seleted
            if (!this.campaign_type) {
                return true;
            }

            // step 2: is satisfied
            if (this.step === 2 && this.campaign_type ===CAMP_TYPES.ONE_OFF) {
                // atleast one audience rule & scheduled time
                if (!this.appliedFilters.length) {
                    return true;
                }
                if (!this.scheduledAt) {
                    return true;
                }

            }else if(this.step === 2 && this.campaign_type ===CAMP_TYPES.REPEATING){
                // atleast one audience rule & scheduled time & days(when) select
                if (!this.appliedFilters.length) {
                    return true;
                }
                if (!this.scheduledAt) {
                    return true;
                }

            }
            // step 3: is satisfied
            if (this.step === 3) {
                if (!this.selectedInbox || !this.message) {
                    return true
                }
                if (this.campaign_type ===CAMP_TYPES.ONE_OFF) {
                    if (!this.title) {
                        return
                    }
                }
                if (this.campaign_type ===CAMP_TYPES.REPEATING) {

                }
            }


            return false;
        },
        isDisableBackBtn(){
            if (!this.campaign_type || this.step === 1) {
                return true;
            }
            return false;
        },
        inboxes() {
            if (this.isOngoingType) {
                return this.$store.getters['inboxes/getWebsiteInboxes'];
            }
            return this.$store.getters['inboxes/getOneOffInboxes'];
        },
        isEmailTemplateAllow(){
            return !!this.selectedInbox?.hasOwnProperty('email');
        },
        initialForm(){
            return {
                selectedInbox: this.selectedInbox,
                isEmailSelectedInbox: this.isEmailTemplateAllow,
                title: this.title,
                message: this.message,
                templateName: this.templateName,
                campaign_type: this.campaign_type,
                selectedTemplateHtml: this.selectedTemplateHtml,
                appliedFilters: this.appliedFilters,
                campaign_repeat_at: this.campaign_repeat_at,
                scheduledAt: this.scheduledAt,
                broadcast_limit: this.broadcast_limit,
                eligible_audience: this.eligible_audience,

                // filterGroups: this.filterGroups,

            }
        },
    },
    async mounted(){
        // set all the intitial values
        this.setInitialStates();
        // this.$store.dispatch('attributes/get');
        this.setFilterAttributes();
        // sent contact filters
        if (this.getAppliedContactFilters.length) {
            this.appliedFilters = [...this.getAppliedContactFilters];
        } else {
            this.appliedFilters.push({
                attribute_key: 'name',
                filter_operator: 'equal_to',
                values: '',
                query_operator: 'and',
                attribute_model: 'standard',
            });
        }
    },
    watch:{
        message(updatedMessage) {
            this.hasSlashCommand = updatedMessage[0] === '/';
            const hasNextWord = updatedMessage.includes(' ');
            const isShortCodeActive = this.hasSlashCommand && !hasNextWord;
            if (this.hasSlashCommand && this.selectedInbox == null) {
                this.showAlert('Please select inbox first.');
            } else if (
                this.hasSlashCommand &&
                this.selectedInbox != null &&
                this.channel_type !== 'Channel::Whatsapp'
            ) {
                this.showAlert(
                'Please select 360 whatsapp inbox to use canned response.'
                );
            }
            if (
                isShortCodeActive &&
                this.selectedInbox != null &&
                this.channel_type === 'Channel::Whatsapp'
            ) {
                this.mentionSearchKey = updatedMessage.substr(1, updatedMessage.length);
                this.showMentions = true;
            } else {
                this.mentionSearchKey = '';
                this.showMentions = false;
            }
        },
        campaign_repeat_at(newRepeatAt,oldRepeatAt){
            if (newRepeatAt.filter_operator == 'equal_to') {
                this.campaign_repeat_at.values = 0;
            }
        },
        selectedInbox(newInbox){
            if (newInbox?.id) {
                this.checkEligibleAudience();
            }
        }
    },
    validations(){
        const commonValidations = {
            title: {
                required,
            },
            message: {
                required,
            },
            selectedInbox: {
                required,
            },
            appliedFilters: {
                required,
                $each: {
                values: {
                    required: requiredIf(prop=>{
                    return !(prop.filter_operator === 'is_present' || prop.filter_operator === 'is_not_present');
                    }),
                },
                },
            },
        };

        return {
            ...commonValidations
        }
    },
    methods:{
        async setInitialStates (){
            let allCustomAttributes = this.$store.getters['attributes/getAttributesByModel'](this.attributeModel);
            if (allCustomAttributes.length) {
                await this.$store.dispatch('attributes/get');
            }
            await this.$nextTick()
            this.setFilterAttributes();

            // call to get 'date' type attribute filter group
            try {
                this.getTimeFilterGroup();
                // set the intial campaign repeat
                if (this.onGoingDateFilterGroups.length) {
                    // this.campaign_repeat_at = this.onGoingDateFilterGroups[0];
                    if (this.onGoingDateFilterGroups[0].attributes?.length) {
                        this.campaign_repeat_at = {
                            filter_operator: "equal_to",
                            values: 0,
                            attribute_key: this.onGoingDateFilterGroups[0].attributes[0].key,
                        };

                    }
                }

            } catch (error) {
                console.log(error);
            }
        },
        selectCampaignType(typeEl){
            this.campaign_type = typeEl.value
        },
        handleNext(){
            // if not type, go set type and show step 0
            if (this.campaign_type === CAMP_TYPES.ONE_OFF) {
                switch (this.step) {
                    case 1:
                        this.setInitialStates()
                        this.step += 1
                        break;
                    case 2:
                        this.$v.appliedFilters.$touch();
                        if (this.$v.appliedFilters.$error) {
                            console.log("Applied filters are invalid");
                            return false;
                        }
                        this.step += 1
                        break;
                    case 3:
                        // open preview modal as last step
                        this.showPreviewPopup();
                        break;

                    default:
                        break;
                }
            }else  if (this.campaign_type === CAMP_TYPES.REPEATING) {
                // handle form running or repeating
                switch (this.step) {
                    case 1:
                        this.setInitialStates()
                        this.step += 1
                        break;
                    case 2:
                        this.$v.appliedFilters.$touch();
                        if (this.$v.appliedFilters.$error) {
                            console.log("Applied filters are invalid");
                            return false;
                        }

                        this.step += 1
                        break;
                    case 3:
                        // open preview modal as last step
                        this.showPreviewPopup();
                        break;

                    default:
                        break;
                }
            }
        },
        handleBack(){
            // if step 3, clear select inbox
            if (this.step === 3) {
                // clear inbox if user go back
                this.selectedInbox = null;
                this.eligible_audience.isFetched = false;
                this.eligible_audience.isAllowModify = false;
            }

            this.step -= 1;
        },
        getInputType(key) {
            const type = this.filterTypes.find(filter => filter.attributeKey === key);
            return type.inputType;
        },
        getOperators(key) {
            const type = this.filterTypes.find(filter => filter.attributeKey === key);
            return type.filterOperators;
        },
        getDropdownValues(type) {
            switch (type) {
                case 'country_code':
                return countries;
                case 'status_id':
                return this.contactStatus;
                default:
                return undefined;
            }
        },
        showUserInput(operatorType) {
            return !(operatorType === 'is_present' || operatorType === 'is_not_present');
        },
        resetFilter(index, currentFilter) {
            this.appliedFilters[index].filter_operator = this.filterTypes.find(
                filter => filter.attributeKey === currentFilter.attribute_key
            ).filterOperators[0].value;
            this.appliedFilters[index].values = '';
        },
        removeFilter(index) {
            if (this.appliedFilters.length <= 1) {
                this.showAlert(this.$t('CONTACTS_FILTER.FILTER_DELETE_ERROR'));
            } else {
                this.appliedFilters.splice(index, 1);
            }
        },
        appendNewFilter() {
            this.appliedFilters.push({
                attribute_key: 'name',
                filter_operator: 'equal_to',
                values: '',
                query_operator: 'and',
            });
        },
        customAttributeInputType(key) {
            switch (key) {
                case 'date':
                return 'date';
                default:
                return 'plain_text';
            }
        },
        getOperatorTypes(key) {
            switch (key) {
                case 'list':
                    return OPERATORS.OPERATOR_TYPES_1;
                case 'text':
                    return OPERATORS.OPERATOR_TYPES_3;
                case 'number':
                    return OPERATORS.OPERATOR_TYPES_1;
                case 'link':
                    return OPERATORS.OPERATOR_TYPES_1;
                case 'date':
                    return OPERATORS.OPERATOR_TYPES_4;
                case 'checkbox':
                    return OPERATORS.OPERATOR_TYPES_1;
                default:
                    return OPERATORS.OPERATOR_TYPES_1;
            }
        },
        onChange(value) {
            this.scheduledAt = value;
        },
        async onChangeInbox(e){
            try {
                // if (this.selectedInbox.hasOwnProperty('email')) {
                //     this.isEmailSelectedInbox = true;
                // } else {
                // this.isEmailSelectedInbox = false;
                this.selectedTemplateHtml = '';
                this.message = '';
                const response = await this.$store.dispatch('inboxMembers/get', {
                    inboxId: this.selectedInbox.id,
                });
                const inbox = await this.$store.getters['inboxes/getInbox'](
                    this.selectedInbox.id
                );
                this.channel_type = inbox.channel_type;
                const {data: { payload: inboxMembers },} = response;
                this.senderList = inboxMembers;
                // }
            } catch (error) {
                const errorMessage =
                error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
                this.showAlert(errorMessage);
            }
        },
        hideMentions() {
            this.showMentions = false;
        },
        replaceText(message, templateName) {
            setTimeout(() => {
                this.message = message;
                this.templateName = templateName;
            }, 100);
        },
        openTopup(){
            this.isTopupModal = true;
        },
        closeTopup(){
            this.isTopupModal = false;
        },
        getTimeFilterGroup(){
            let allCustomAttributes = this.$store.getters[
                'attributes/getAttributesByModel'
            ](this.attributeModel);

            const customAttributesFormattedForDate = {
                name: this.$t(`${this.filtersFori18n}.GROUPS.CUSTOM_ATTRIBUTES`),
                attributes: allCustomAttributes.map(attr => {
                return {
                    key: attr.attribute_key,
                    name: attr.attribute_display_name,
                    attribute_display_type: attr.attribute_display_type,
                };
                }).filter(attrDate =>attrDate.attribute_display_type === "date"),
            };

            const filterGroupsDate = [customAttributesFormattedForDate];

            this.onGoingDateFilterGroups = filterGroupsDate;
        },
        getCampaignDetails() {
            // prepare payload
            let campaignDetails = null;
            if (this.isOngoingType) {
                campaignDetails = {
                title: this.title,
                message: this.message,
                inbox_id: this.selectedInbox.id,
                sender_id: this.selectedSender || null,
                enabled: this.enabled,
                trigger_only_during_business_hours:
                    // eslint-disable-next-line prettier/prettier
                    this.triggerOnlyDuringBusinessHours,
                trigger_rules: {
                    url: this.endPoint,
                    time_on_page: this.timeOnPage,
                },
                };
            } else {
                campaignDetails = {
                    title: this.title,
                    message: this.message,
                    template_name: this.templateName,
                    inbox_id: this.selectedInbox.id,
                    scheduled_at: this.scheduledAt,
                    audience: filterQueryGenerator(this.appliedFilters).payload,
                };
            }
            // add the campaign type ongoing data to send to database
            if (this.campaign_type == 'running') {
                // campaignDetails.repeat_at = this.campaign_repeat_at;
                campaignDetails.repeat_at = {
                    filter_operator: this.campaign_repeat_at.filter_operator,
                    values: this.campaign_repeat_at.values,
                    attribute_key: this.campaign_repeat_at.attribute_key,
                };
            }
            campaignDetails.campaign_type = this.campaign_type;
            return campaignDetails;
        },
        async checkEligibleAudience(){
            try {
                const payload = this.getCampaignDetails();
                const {data} = await CampaignAPI.check_eligible_audience(payload);
                this.eligible_audience = data;
                this.broadcast_limit = data.max_audience;
                // only allow is fetched if audience > max_audience
                if (data.audience > data.max_audience) {
                    this.eligible_audience.isAllowModify = true;
                }
                this.eligible_audience.isFetched = true;
            } catch (error) {
                console.log(error);
            }
        },
        onChangeBroadcastLimit(value){
            this.broadcast_limit = value;
        },
        openEmailTemplateListModal() {
            this.isOpenEmailTemplateListModal = true;
        },
        closeEmailTemplateListModal() {
            this.isOpenEmailTemplateListModal = false;
        },
        selectTemplate(selectedTemplateObj) {
            this.selectedTemplateHtml = selectedTemplateObj.templateHtml;
            this.message = selectedTemplateObj.templateHtml;
            this.closeEmailTemplateListModal();
        },
        hidePreviewPopup(){
            this.isPreviewPopupOpen = false;
        },
        showPreviewPopup(){
            this.isPreviewPopupOpen = true;
        },
        discardBroadcast(){
            this.isDiscardOpen = true;
        },
        closeDiscardBroadcast(){
            this.isDiscardOpen = false;
        },
        onDiscardBroadcast(){
            this.$router.push(`/app/accounts/${this.currentUser.account_id}/campaigns/one_off`)
        },
        // end methods
    },
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.common_font {
    font-family: 'Figtree', sans-serif;
    line-height: 1.4;
    color: #1F1F1F;
}
// .font_12{
//     font-size: 12px;
// }
// .font_14{
//     font-size: 14px;
// }

.add_campaign_container{
    max-width: 640px;
    margin: auto;
    margin-top: 32px;
}
.campaign_heading{
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2{
        font-weight: 600;
        font-size: 20px;
        line-height: 1.4;
        font-family: 'Figtree';
        color: #1F1F1F;
    }
}

.step_range_continer{
    margin-top: 16px;
    margin-bottom: 32px;
    p{
        font-weight: 500;
        font-size: 14px;
        line-height: 1.4;
        font-family: 'Figtree';
        color: #1F1F1F;
    }
}
.step_container{
    max-width: 90%;
    @media (max-width: 768px) { // Mobile
        max-width: 100%;
    }
}
.campaign_btns{
    display: flex;
    gap: 16px;
    margin-top: 32px;
    .campaign_btn{
        width: 280px;
    }
    .campaign_btn_solid{
        background-color: #8D25F6 !important;
    }
}
.campaign_types{
    display: flex;
    gap: 12px;
    list-style: none;
    margin-top: 24px;
}
.campaign_type{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    width: 280px;
    height: 240px;
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    padding: 20px;
    margin: 24px 0 0 0;
    cursor: pointer;
    transition:  all ease-in-out 0.2s;

    .type_body{
        margin-top: 16px;
        text-align: center;
        .name{
            @extend .common_font;
            font-weight: 600;
            font-size: 16px;
            color: #000;
        }
        .description{
            @extend .common_font;
            font-weight: 500;
            font-size: 14px;
            color:#858585;
            margin-top: 4px;
        }
    }
}
.active_type{
    border: 2px solid #8D25F6;
    transition:  all ease-in-out 0.2s;
}
.field_label{
    @extend .common_font;
    font-weight: 500;
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 8px;
}
.audience_filter{
    margin-bottom: 8px;
}

// .audience_container{
//     display: flex;
//     gap: 20px;
//     border-radius: 10px;
//     padding: 16px;
//     width: fit-content;
//     background-color: #F2E7FE;
//     div{
//         &:nth-child(1){
//             max-width: 250px;
//         }
//         &:nth-child(2){
//             max-width: 320px;
//         }
//     }
// }
// .credit_purple_box{
//     display: flex;
//     align-items: center;
//     gap: 4px;
//     margin-top: 4px;
// }
// .available_credit_box{
//     display: flex;
//     align-items: center;
// }
// .available_credit{
//     display: inline-block;
//     border-radius: 20px;
//     padding: 4px 12px;
//     background-color: #FFECB2;
//     color: #996600;
// }
// .available_credit_purple{
//     background-color: #E5CEFD;
//     color: #8D25F6;
// }
.campaint_title_wrap{
    position: relative;
    .audience_note{

    }
}

// .topup_box{
//     display: flex;
//     gap: 8px;
// }
// .topup{
//     display: flex;
//     align-items: center;
//     gap: 4px;
//     background: #fff;
//     width: fit-content;
//     padding: 10px 8px;
//     border-radius: 4px;
//     border: 1px solid #EEEEEE;
//     input{
//         width: 100px;
//         margin: 0;
//         padding: 0;
//         height: auto;
//         border: none;
//         text-align: right;
//     }
// }
.canned_msg_wrap{
    position: relative;
    .modal_btns{
        position: absolute;
        top: 0;
        right: 0;
        button{
            margin-left: 8px;
            cursor: pointer;
        }
    }
}


// discard modal styles start
.discard_icon{
    width: fit-content;
    margin: 40px auto 0;
}
.d_title{
    margin-top: 24px;
    text-align: center;
}
.d_description{
    margin-top: 8px;
    text-align: center;
}
// discard modal styles end
</style>


<style>

.audience_filter .filters .filter{
    padding: 0;
    border: none;
    background-color: #fff;
}
.audience_filter .filters .filter .filter-inputs .filter__question,
.audience_filter .filters .filter .filter-inputs .filter__operator{
    margin-right: 12px;
}


.add_campaign_filter svg,
.add_campaign_filter .button__content{
    font-family: 'Figtree', sans-serif;
    line-height: 1.4;
    font-weight: 600;
    font-size: 14px;
    color: #8D25F6;
}
.add_campaign_filter:hover .button__content{
    color: inherit;
}
.campaign_title input{
    font-size: 14px;
    color: #1F1F1F;
}
.campaign_title input::placeholder{
    font-size: 14px;
}
.campaign_title_note{
    position: absolute;
    top: 62px;
    color: #858585;
}
.cann_margin{
    margin-bottom: 25px;
}
.canned_msg textarea{
    font-family: 'Figtree', sans-serif;
    font-size: 14px;
    color: #1F1F1F;
    margin-top: 8px;
}
.add_camp_calendar input{
  font-size: 14px;
  font-family: 'Figtree', sans-serif;
}

.add_campaign_discard{
    max-width: 380px;
}
.add_campaign_discard .discard_btns{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
}
.add_campaign_discard .discard_btns button{
    font-family: 'Figtree', sans-serif;
    line-height: 1.4;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    border: 1px solid !important;
}

.add_campaign_discard .discard_btns button:nth-child(2){
    border-color: #EEEEEE !important;
    color: #858585 !important;
}

</style>