var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "MultiselectDropdown",
        {
          attrs: {
            label: _vm.$t("COUPON.ADD_FORM.TYPE_FORM.DISCOUNT_TYPE.LABEL"),
            error: _vm.$t("COUPON.ADD_FORM.TYPE_FORM.DISCOUNT_TYPE.ERROR")
          }
        },
        [
          _c("RadioList", {
            attrs: {
              options: _vm.discount_types,
              classWrapper: "couponOptions"
            },
            model: {
              value: _vm.discount_type,
              callback: function($$v) {
                _vm.discount_type = $$v
              },
              expression: "discount_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("InputField", {
        attrs: {
          label: _vm.$t("COUPON.ADD_FORM.TYPE_FORM.TITLE.LABEL"),
          placeholder: _vm.$t("COUPON.ADD_FORM.TYPE_FORM.TITLE.PLACEHOLDER"),
          error: _vm.$v.discount_value.$error
            ? _vm.$t("COUPON.ADD_FORM.TYPE_FORM.TITLE.ERROR")
            : "",
          infoList: [],
          textLength: _vm.discount_value,
          postFix: "%"
        },
        on: { blur: _vm.$v.discount_value.$touch },
        model: {
          value: _vm.discount_value,
          callback: function($$v) {
            _vm.discount_value = $$v
          },
          expression: "discount_value"
        }
      }),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.discount_value.length))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "submit_btns" },
        [
          _c(
            "woot-button",
            {
              style: { width: "100%" },
              attrs: {
                "color-scheme": "primary",
                size: "small",
                "is-loading": false,
                "is-disabled": false
              },
              on: { click: _vm.onNext }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("COUPON.ADD_FORM.TYPE_FORM.BTNS.NEXT")) +
                  "\n      "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }