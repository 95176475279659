import { render, staticRenderFns } from "./AddCategoryModal.vue?vue&type=template&id=cb4226cc&scoped=true&"
import script from "./AddCategoryModal.vue?vue&type=script&lang=js&"
export * from "./AddCategoryModal.vue?vue&type=script&lang=js&"
import style0 from "./AddCategoryModal.vue?vue&type=style&index=0&id=cb4226cc&scoped=true&lang=scss&"
import style1 from "./AddCategoryModal.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb4226cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cb4226cc')) {
      api.createRecord('cb4226cc', component.options)
    } else {
      api.reload('cb4226cc', component.options)
    }
    module.hot.accept("./AddCategoryModal.vue?vue&type=template&id=cb4226cc&scoped=true&", function () {
      api.rerender('cb4226cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/catalogue/catelogues/AddCategoryModal.vue"
export default component.exports