<template>
  <div>
    <label class="form_label" :class="{ error:  $v.item.title.$error }">
        {{$t(label.title)}}
        <input
            v-model="item.title" type="text"
            @input="validateInput({ field: 'title', value: item.title, })"
        />
        <span v-if="$v.item.title.$error" class="message">
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}}
            {{constraint.title_limit}}
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}}
        </span>
    </label>
    <label class="form_label" :class="{ error:  $v.item.body.$error }">
        {{$t(label.body)}}
        <textarea
            v-model="item.body" name="" id="" cols="20" rows="6"
            @input="validateInput({ field: 'body', value: item.body, })"
        />
        <span v-if="$v.item.body.$error" class="message">
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}}
            {{constraint.body_limit}}
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}}
        </span>
    </label>
    <label class="form_label" :class="{ error:  $v.item.footer.$error }">
        {{$t(label.footer)}}
        <input
            v-model="item.footer" type="text"
            @input="validateInput({ field: 'footer', value: item.footer, })"
        />
        <span v-if="$v.item.footer.$error" class="message">
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}}
            {{constraint.footer_limit}}
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}}
        </span>
    </label>
    <label class="form_label" :class="{ error:  $v.item.button.$error }">
        {{$t(label.button)}}
        <input
            v-model="item.button" type="text"
            @input="validateInput({ field: 'button', value: item.button, })"
        />
        <span v-if="$v.item.button.$error" class="message">
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}}
            {{constraint.button_limit}}
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}}
        </span>
    </label>

     <!-- based on sub type, show different dropdown -->
    <template v-if="item.sub_type === 'calendar_list'">
        <!-- subtype = calendar_list: popolate dropdown automatic from botpress   -->
    </template>
    <template v-if="item.sub_type === 'appointment_list'">
        <!-- subtype = appointment_list: popolate dropdown automatic from botpress  -->
    </template>
    <template v-if="item.sub_type === 'outstanding_appointment'">
        <!-- subtype = outstanding_appointment: popolate dropdown automatic from botpress   -->
    </template>

    <template v-else v-for="(section,sectionIdx) in item.sections">
        <div :key="'actionBtn'+sectionIdx">
            <label class="form_label" :class="{ error:  $v.item.sections.$each[sectionIdx].title.$error }">
                {{$t(label.sections[sectionIdx].title)}}
                <input
                    v-model="section.title" type="text"
                    @input="validateInput({ field: 'sections', value: section.title, })"
                />
                <span v-if="$v.item.sections.$each[sectionIdx].title.$error" class="message">
                    {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}}
                    {{constraint.section_title_limit}}
                    {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}}
                </span>
            </label>
            <multiselect
                class="column small-12 express_bot_multiselect"
                v-model="section.rows"
                :options="categoryOptions"
                track-by="id"
                label="title"
                :multiple="true"
                :max="constraint.max_row_length"
                :close-on-select="false"
                :clear-on-select="false"
                :hide-selected="true"
                :placeholder="$t('CATALOGUE.TOOLBAR.CATAGORY_PLACEHOLDER')"
                selected-label
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            />
        </div>
    </template>




  </div>
</template>

<script>
import { required, minValue, maxValue,maxLength  } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { countCharacters} from "./expressBot";


export default {
    props: {
        item: { default: {} },
        label: { default: {} },
        constraint: { default: {} },
        categoryOptions:{
            required: false,
            default:[]
        }
    },
    data:{},
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            merchant: 'merchant/getMerchant',
        }),
    },
    validations() {
        return {
            item: {
                title: {
                    required,
                    maxLength: maxLength(this.constraint.title_limit || 20),
                    checkLength(value){
                        const totalLength = countCharacters(this.currentUser,this.merchant,value).length;
                        return totalLength < this.constraint.title_limit
                    },
                },
                body: {
                    required,
                    maxLength: maxLength(this.constraint.body_limit || 100),
                    checkLength(value){
                        const totalLength = countCharacters(this.currentUser,this.merchant,value).length;
                        console.log({totalLength});
                        return totalLength < this.constraint.body_limit
                    },
                },
                footer: {
                    required,
                    maxLength: maxLength(this.constraint.footer_limit || 100),
                    checkLength(value){
                        const totalLength = countCharacters(this.currentUser,this.merchant,value).length;
                        console.log({totalLength});
                        return totalLength < this.constraint.footer_limit;
                    },
                },
                button: {
                    required,
                    maxLength: maxLength(this.constraint.button_limit || 100),
                    checkLength(value){
                        const totalLength = countCharacters(this.currentUser,this.merchant,value).length;
                        console.log({totalLength});
                        return totalLength < this.constraint.button_limit;
                    },
                },
                // do for sections here
                sections:{
                    $each: {
                        title: {
                            required(value) {
                                return value ? true : true;
                            },
                            maxLength: maxLength(this.constraint.section_title_limit || 20),
                            checkLength(value){
                                const totalLength = countCharacters(this.currentUser,this.merchant,value).length;
                                console.log(totalLength);
                                return totalLength < this.constraint.section_title_limit;
                            },
                        },
                        // rows:{
                        //     // min 1 if not subtype
                        // }
                    }
                },
            },
        };
    },
    methods: {
        validateInput({ field,value }) {
            console.log(field,this.$v.item[field]);
            this.$v.item[field]?.$touch();
        },
    }
}
</script>



<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.error{
    position: relative;
    margin-bottom: 25px;
    .message{
        position: absolute;
        bottom: -32x;
        color: var(--r-500);
    }
}

.form_label{
    font-weight: 500;
    font-size: 12px;
    color: #1F1F1F;
    margin-top: 12px;

    input{
        font-size: 12px;
    }
    textarea{
        font-size: 12px;
    }

    .textField{
        margin-top: 8px;
        padding: 12px;
        font-weight: 500;
        font-size: 14px;
        color: #1F1F1F;
        border-color: #EEEEEE;
    }
    .textField::placeholder{
        color: #B8B8B8;
    }
}
.check_label{
    display: flex;
    align-items: center;
    gap: 4px;
    input{
        margin: 0;
    }
}
</style>


