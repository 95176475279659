var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "label",
      { staticClass: "form_label", class: { error: _vm.$v.item.value.$error } },
      [
        _vm._v("\n         " + _vm._s(_vm.$t(_vm.label.value)) + "\n        "),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.value,
              expression: "item.value"
            }
          ],
          attrs: { name: "", id: "", cols: "20", rows: "6" },
          domProps: { value: _vm.item.value },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.item, "value", $event.target.value)
              },
              function($event) {
                return _vm.validateInput({
                  field: "value",
                  value: _vm.item.value
                })
              }
            ]
          }
        }),
        _vm._v(" "),
        _vm.$v.item.value.$error
          ? _c("span", { staticClass: "message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$t(
                      "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1"
                    )
                  ) +
                  "\n            " +
                  _vm._s(_vm.constraint.value_limit) +
                  "\n            " +
                  _vm._s(
                    _vm.$t(
                      "FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2"
                    )
                  ) +
                  "\n        "
              )
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _vm.hasExtraConfig(_vm.item, "foreignContents")
      ? _c(
          "div",
          [
            _vm._l(_vm.item.extraConfig.foreignContents, function(
              extraItem,
              exIdx
            ) {
              return [
                extraItem.contentType === "card"
                  ? _c(
                      "label",
                      { key: "ex" + exIdx, staticClass: "form_label" },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t(_vm.label.foreignContents[exIdx].title)
                            ) +
                            "\n                "
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.form_nodes[extraItem.step].contents[
                                  extraItem.contentIdx
                                ].actionButtons[extraItem.btnIndex].title,
                              expression:
                                "form_nodes[extraItem.step].contents[extraItem.contentIdx].actionButtons[extraItem.btnIndex].title"
                            }
                          ],
                          attrs: { type: "text", name: "", id: "" },
                          domProps: {
                            value:
                              _vm.form_nodes[extraItem.step].contents[
                                extraItem.contentIdx
                              ].actionButtons[extraItem.btnIndex].title
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form_nodes[extraItem.step].contents[
                                  extraItem.contentIdx
                                ].actionButtons[extraItem.btnIndex],
                                "title",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }