<template>
  <div class="row">
    <div class="column content-box">
        <section class="details_container">
            <div>
                <woot-button
                    color-scheme="success"
                    class-names="button--fixed-right-top add_order_item_btn"
                    icon="add"
                    :disabled="isAddItemDisabled"
                    @click="openAddItemModal"
                    >
                        {{$t('ORDER.DETAILS.ADD_ITEM_BTN')}}
                </woot-button>
            </div>
            <div></div>
            <div></div>


            <div>
                <div class="">
                    <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.ORDER_ID')}}</p>
                    <p class="order_value font_regular">{{order.id}}</p>
                    <p class="order_hint font_regular order_hint_warning">{{paymentNotify.message}}</p>
                </div>
            </div>
            <div>
                <div class="">
                    <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.STATUS')}}</p>
                    <p class="order_value font_regular">{{order.order_status.title}}</p>
                    <p v-if="order.delivery_service" class="order_hint font_regular order_hint_mute">
                        <fluent-icon icon="clock" class="order_hint_mute" />
                        <span>
                            {{$t('ORDER.DETAILS.INFO.STATUS_HINT')}} {{formatDateToCustomString(delivery_service.pickup_scheduled_at)}}
                        </span>
                    </p>
                </div>
            </div>
            <div>
                <div v-if="order.shipment_address">
                    <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.SHIP_ADDRESS')}}</p>
                    <p class="order_value font_regular">
                        {{order.shipment_address.line1}},
                        {{order.shipment_address.city}},
                        {{order.shipment_address.country}}
                    </p>
                </div>
            </div>


            <div>
                <div v-if="order.delivery_service && delivery_service.tracking_number">
                    <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.CONSIGNMENT')}}</p>
                    <p class="order_value font_regular">{{delivery_service.tracking_number}}</p>
                </div>
                <!-- <div>
                    <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.ORDER_TIME')}}</p>
                    <p class="order_value font_regular">{{new Date(order.created_at).toGMTString().slice(0,-7)}}</p>
                </div> -->
            </div>
            <div>
                <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.NAME')}}</p>
                <p class="order_value font_regular">
                    {{contactName}}
                    <router-link v-if="orderConversationLink" :to="orderConversationLink">
                        <woot-button
                            size="small"
                            variant="clear"
                            color-scheme="primary"
                            icon="chat"
                        >
                        </woot-button>
                    </router-link>
                </p>
            </div>
            <div>
                <div v-if="order.delivery_service && order.delivery_service.price">
                    <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.DELVARY_PRICE')}}</p>
                    <p class="order_value font_regular">{{order.delivery_service.price}}</p>
                </div>
            </div>



            <div>
                <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.ORDER_TIME')}}</p>
                <p class="order_value font_regular">{{new Date(order.created_at).toGMTString().slice(0,-7)}}</p>
            </div>
            <!-- <div>
                <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.CURRENCY')}}</p>
                <p class="order_value font_regular">{{order.currency}}</p>
            </div> -->
            <div>
                <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.PHONE')}}</p>
                <p class="order_value font_regular">{{contactPhoneNumber}}</p>
            </div>
            <div>
                <div v-if="order.delivery_service && order.delivery_service.status_text">
                    <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.DELVARY_STATUS')}}</p>
                    <p class="order_value font_regular">{{order.delivery_service.status_text}}</p>
                </div>
            </div>



            <div>
                <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.CURRENCY')}}</p>
                <p class="order_value font_regular">{{order.currency}}</p>
            </div>
            <!-- <div>
                <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.REMARK')}}</p>
                <p class="order_value font_regular">{{order.remark}}</p>
            </div> -->
            <div>
                <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.PAID')}}</p>
                <p class="order_value font_regular">{{order.paid_total}}</p>
            </div>
            <div>
                <div v-if="order.delivery_service && order.delivery_service.failed_reason">
                    <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.DELVARY_FAIL_MSG')}}</p>
                    <p class="order_value font_regular">{{order.delivery_service.failed_reason}}</p>
                </div>
            </div>


            <div>
                <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.REMARK')}}</p>
                <p class="order_value font_regular">{{order.remark}}</p>
            </div>
            <!-- <div></div> -->
            <div>
                <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.REFUNDED')}}</p>
                <p class="order_value font_regular">{{order.refund_amount}}</p>
            </div>
            <div>
                <div v-if="order.delivery_service && order.delivery_service.tracking_url">
                    <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.DELVARY_TRAKING_URL')}}</p>
                    <p class="order_value font_regular">
                        <a v-if="order.delivery_service.tracking_url" class="order_value font_regular order_tracking" :href="order.delivery_service.tracking_url" target="_blank" rel="noopener noreferrer">
                        {{$t('ORDER.DETAILS.INFO.DELIVERY_TRAKING_BTN')}}
                        </a>
                    </p>
                </div>
            </div>

            <div>
                <div>
                    <p class="order_label font_regular"></p>
                    <p class="order_value font_regular"></p>
                </div>
            </div>
            <div>
                <div>
                    <p class="order_label font_regular"></p>
                    <p class="order_value font_regular"></p>
                </div>
            </div>
            <div>
                <div v-if="order.delivery_service && order.delivery_service.print_label_url">
                    <p class="order_label font_regular">
                        {{$t('ORDER.DETAILS.INFO.DELVARY_PRINT_URL')}}
                        <span class="tooltip_info">
                            <fluent-icon class="help_icon" icon="question-circle" />
                            <div class="tooltip_card" style="bottom:18px;">
                                <p
                                v-for="(info, index) in $t('ORDER.DETAILS.INFO.DELIVERY_HINTS')"
                                class="mb_0 font_12"
                                :key="index"
                                >{{info}}</p>
                            </div>
                        </span>
                    </p>
                    <p class="order_value font_regular">
                        <a v-if="order.delivery_service.print_label_url" class="order_value font_regular order_tracking" :href="order.delivery_service.print_label_url" target="_blank" rel="noopener noreferrer">
                        {{$t('ORDER.DETAILS.INFO.DELVARY_PRINT_BTN')}}
                        </a>
                    </p>
                </div>
            </div>

            <!--
            <div>
                <div>
                    <p class="order_label font_regular"></p>
                    <p class="order_value font_regular"></p>
                </div>
            </div>
            <div>
                <div>
                    <p class="order_label font_regular"></p>
                    <p class="order_value font_regular"></p>
                </div>
            </div>
            <div>
                <div v-if="order.delivery_service && delivery_service.tracking_number">
                    <p class="order_label font_regular">{{$t('ORDER.DETAILS.INFO.CONSIGNMENT')}}</p>
                    <p class="order_value font_regular">{{delivery_service.tracking_number}}</p>
                </div>
            </div>
            -->


        </section>




        <section class="padding-top mt_20">
            <OrderItemsTable
                :order_items="order.order_items"
                :cols="cols"
                :merchant="merchant"
                :order="order"
                :isLoading="isOrderLoading && !isUpdating"
                :paymentNotify="paymentNotify"
                :isOrderCancelOrComplete="isOrderCancelOrComplete"
                :onRefundAmount="openRefundModal"
                @on-sort-change="onSortChange"
                @onQuantityChange="onQuantityChange"
            />
        </section>


        <div>
            <woot-button
                size="small"
                color-scheme="primary"
                class="button--fixed-left-top"
                :isLoading="isUpdating"
                :isDisabled="isUpdating || isOrderCancelOrComplete"
                @click="updateOrderDetails"
            >
                Update
            </woot-button>
        </div>

        <div v-if="showAddItemModal">
            <AddItemModal
            :show="showAddItemModal"
            :on-close="closeAddItemModal"
            :order="order"
            :categories="categories"
            :catalogueItems="catalogueItems"
            />
        </div>
        <div v-if="showRefundModal">
            <RefundModal
            :show="showRefundModal"
            :on-close="closeRefundModal"
            :order="order"
            :onSuccess="onRefundSuccess"
            />
        </div>


    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderAPI from "../../../../api/orders";
import MerchantAPI from "../../../../api/merchant";
import alertMixin from 'shared/mixins/alertMixin';
import {ORDER_STATUSES,CURRENCY_LIST} from "./order_constant"
import Button from '../../../../components/buttons/Button.vue';
import AddItemModal from './AddItemModal.vue';
import OrderItemsTable from './OrderItemsTable.vue';
import ContactApi from '../../../../api/contacts';
import CatalogAPI from "../../../../api/catalogue/catalog";
import RefundModal from "./RefundModal.vue"

export default {
    components:{
        Button,
        AddItemModal,
        OrderItemsTable,
        RefundModal,
    },
    mixins: [alertMixin],
    data(){
        return{
            isOrderLoading:false,
            order: {order_items:[],contact:{},order_status:{}},
            ORDER_STATUSES,
            CURRENCY_LIST,
            selectedCurrency:{},
            showAddItemModal: false,
            cols: [
                { key: 'item_name', name: 'Name', visible: true, type: 'default' },
                { key: 'currency', name: 'Currency', visible: true, type: 'default' },
                { key: 'quantity', name: 'Quantity', visible: true, type: 'default' },
                { key: 'item_price', name: 'Price', visible: true, type: 'default' },
                { key: 'total', name: 'Total', visible: true, type: 'default' },
                { key: 'available_inventory', name: 'Inventory', visible: true, type: 'default' },
                { key: 'action', name: 'Action', visible: true, type: 'default' },
            ],
            isUpdating: false,
            merchant:{},
            catalog:{},
            display_conversation_id: null,
            showRefundModal: false,
            isStatusChangeByUser: false,
        }
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            categories: "catalog/getCatagories",
            catalogueItems: "catalog/getCatalogItems",
        }),
        orderConversationLink(){
            return `/app/accounts/${this.currentUser.account_id}/conversations/${this.display_conversation_id}`
        },
        contactName(){
            return this.order?.contact?.name || "";
        },
        contactPhoneNumber(){
            return this.order?.contact?.phone_number || "";
        },
        isAddItemDisabled(){
            const enableStatuslList = ['fresh','pending','packed','paid'];
            const isDisable = !enableStatuslList.includes(this.order?.order_status?.id);
            return isDisable;
        },
        isOrderCancelOrComplete(){
            if (this.isStatusChangeByUser) {
                return false;
            }
            const isInclude = ['cancelled','completed'].includes(this.order.order_status?.id)
            return isInclude
        },
        paymentNotify(){
            let message = "";
            let msgClass = "";
            let isRefund = false;
            switch (true) {
                case this.order.paid_total < this.order.grand_total:
                    message =  this.$t('ORDER.DETAILS.NOTIFY_MSG.WAITING');
                    msgClass="pay_waiting_msg";
                    break;
                case this.order.paid_total === this.order.grand_total:
                    message = this.$t('ORDER.DETAILS.NOTIFY_MSG.PAYMENT_RECEIVE');
                    msgClass="pay_receive_msg";
                    break;
                    // check if refund amount > 0
                // case this.order.paid_total > this.order.grand_total:
                case this.order.paid_total > 0:
                    message = this.$t('ORDER.DETAILS.NOTIFY_MSG.REFUND');
                    msgClass="pay_refund_msg";
                    isRefund = true;
                    break;

                default:
                    break;
            }
            return {
                message,
                msgClass,
                isRefund,
            }
        },
        delivery_service(){
            return this.order.delivery_service || {
                tracking_number: "-",
            }
        },
        // end computed
    },
    mounted(){
        this.getOrderById(this.$route.params.order_id);
        this.getCatalog();
        this.$store.dispatch("catalog/getCatagories");
        this.$store.dispatch("catalog/getCatalogProductItems");
    },
    watch: {
        currentUser: {
            handler(newValue, oldValue) {
                this.getMerchant();
            },
            immediate: true
        },
        '$route.params.order_id': {
            handler(newOrderId, oldOrderId) {
                if (newOrderId) {
                    this.getOrderById(newOrderId);
                }
            },
            immediate: true
        }
    },
    methods:{
        async getOrderById(order_id){
            this.isOrderLoading = true;
            try {
                const {data} = await OrderAPI.getOrderById(order_id);
                this.order = data;
                // format status for option way
                this.order.order_status = ORDER_STATUSES.find(el=>el.id === this.order.order_status) || {}
                this.selectedCurrency = CURRENCY_LIST.find(el=>el.id === this.order.currency) || {}
                // set existItem = true at the beginning to keep separate the newly added items to remove id from payload
                this.order.order_items = this.order.order_items.map(el=>({...el,isExistItem:true}) )

                // get conversation id
                this.getConversationDisplayId();
            } catch (error) {
                this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.FETCH_ORDER_FAIL'));
            }finally{
                this.isOrderLoading = false;
            }
        },
        onRefundSuccess(){
            this.getOrderById(this.$route.params.order_id);
        },
        async getMerchant(){
            try {
                if (!this.currentUser.accounts?.length) {
                    return
                }
                const merchantName = this.currentUser.accounts[0]?.name;
                if (merchantName) {
                    const {data} = await MerchantAPI.getMerchantByName(merchantName);
                    this.merchant = data;
                }
            } catch (error) {
                this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.FETCH_MERCHANT_FAIL'));
            }
        },

        async updateOrderStatus(selectedOption){
            this.isStatusChangeByUser = true;
            // console.log({selectedOption,order:this.order});
            // try {
            //     const payload = {order_status:selectedOption.status_code}
            //     const {data} = await OrderAPI.updateOrderById(this.order.id,payload)
            //     this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.UPDATE_SUCCESS'));
            // } catch (error) {
            //     this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.UPDATE_FAIL'));
            // }
        },

        async updateCurrency(selectedOption){
            this.order.currency = selectedOption.id;
        },
        openAddItemModal(){
            this.showAddItemModal = true;
        },
        closeAddItemModal(){
            this.showAddItemModal = false;
        },
        onSortChange(params){
            // console.log({params});
        },
        onQuantityChange(e,rowData,rowIndex){
            // console.log("Got in parent quantity",e,rowData);
            const quantity = parseInt(e.target.value||"0");

            // update the inventory
            const originalInventory = rowData.available_inventory + rowData.quantity;
            const newInventory = originalInventory - quantity;
            // console.log({originalInventory,newInventory});
            if (newInventory >= 0 && quantity <=originalInventory) {
                this.order.order_items[rowIndex].quantity = quantity;
                this.order.order_items[rowIndex].available_inventory = newInventory;
            }

            /*
            const originalInventory = rowData.inventory + rowData.quantity;
            const newInventory = originalInventory - quantity;
            console.log({originalInventory,newInventory});
            if (newInventory > 0) {
                this.order.order_items[rowIndex].inventory = newInventory;
            }
            */
            // console.log("QC item",this.order.order_items[rowIndex].available_inventory);
        },
        async updateOrderDetails(){
            console.log("Update order = ",this.order);

            const updateOrderPayload = {
                currency : this.order.currency,
                paid_total: this.order.paid_total,
                order_items: this.order.order_items
                    .filter(el=>el.quantity > 0)
                    .map(({id,item_price,quantity,catalog_item_id,isExistItem})=>({
                        ...(isExistItem ? {id}:{}),
                        item_price,
                        quantity,
                        catalog_item_id
                    })),
                order_status: this.order.order_status.status_code,
                // remark: this.order.remark,
            }
            console.log(updateOrderPayload);

             try {
                this.isUpdating = true;
                const result = await OrderAPI.updateOrderById(this.order.id,updateOrderPayload)
                await this.getOrderById(this.$route.params.order_id);
                //  get the order again
                 this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.UPDATE_SUCCESS'));
                this.isUpdating = false;
                this.isStatusChangeByUser = false;
            } catch (error) {
                // console.log(error);
                this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.UPDATE_FAIL'));
                this.isUpdating = false;
            }
        },
        closeRefundModal(){
            this.showRefundModal = false;
        },
        openRefundModal(){
            this.showRefundModal = true;
        },
        /*
        async onRefundAmount(){
            // open popup
            try {
                this.isRefunding = true;
                const result = await OrderAPI.refundAmount(this.order.id)
                this.showAlert('Refund successful');
            } catch (error) {
                // console.log(error.response,error.message);
                this.showAlert(error.response?.data?.error||'Failed to refund amount');
            }
            this.isRefunding = true;
        },
        */
        async getCatalog(){
            try {
                const {data} = await CatalogAPI.getDefaultCatalog();
                if (!data) {
                    return;
                }
                this.catalog = data;
                this.getConversationDisplayId();
            } catch (error) {
                // console.log("getCatalog = ",error);
                this.showAlert('error to get catalog');
            }
        },
        async getConversationDisplayId(){
            if (!this.catalog?.inbox_id || !this.order?.contact?.id) {
                return
            }

            try {
                const {data} = await ContactApi.getShopContactConversationUrl({
                    contact_id: this.order?.contact?.id,
                    inbox_id: this.catalog.inbox_id,
                })
                if (data?.length) {
                    this.display_conversation_id = data[0];
                }
            } catch (error) {
                this.showAlert('error to get catalog');
            }
        },
        formatDateToCustomString(dateString) {
            if (!dateString) {
                return
            }
            const date = new Date(dateString);
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
                timeZone: 'UTC',
            };

            return new Intl.DateTimeFormat('en-US', options).format(date);
        },
        /*
        async onChatStart(order){
            console.log("onChatStart",order,this.catalog);
            try {
                const {data} = await ContactApi.getShopContactConversationUrl({
                    contact_id: order.contact.id,
                    inbox_id: this.catalog.inbox_id,
                })
                console.log(data,"Response",this.currentUser);
                if (data?.length) {
                    this.$router.push(`/app/accounts/${this.currentUser.account_id}/conversations/${data[0]}`)
                }
                // https://chat.peasy.ai/app/accounts/{{account_id}}/conversations/{{display_id}}
            } catch (error) {
                console.log(error);
            }
        },
        */

        // end method
    },

}
</script>

<style  lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.status_select_wrapper{
    max-width: 200px;
}
.info_field{
    height: 60px;
    align-items: center;

    .pay_msg{
        // font-family: Figtree, Inter, sans-serif;
        // font-weight: 500;
        // font-size: 12px;
        border-radius: 4px;
        padding: 4px 8px;
        // color: #1F1F1F;
        margin-left: 10px;
    }
    .pay_waiting_msg{
        background-color: #FFECB2;
    }
    .pay_receive_msg{
        background-color: #F2E7FE;
    }
    .pay_refund_msg{
        background-color: #E5F1FF;
    }
}
.margin-bottom{
    margin-bottom: var(--space-small);
}
.margin-top{
    margin-top: var(--space-small);
}
.font_normal{
    font-size: 14px;
}
.disable_field{
    background-color: #ededed;
}

.product_details{
    display: grid;
    grid-template-columns: 2fr 1fr;
}
.details{
    // max-width: 580px;
}

.order_label{
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}
.order_value{
    font-size: 16px;
    margin: 4px 0 0 0;
    font-weight: 500;
}
.order_hint{
    font-size: 12px;
    font-weight: 500;
    display: flex;
    margin: 4px 0 0 0;
    border-radius: 2px;
}
.order_hint_warning{
    max-width: fit-content;
    padding: 6px 12px;
    background: #F7FEE7;
}
.order_hint_mute{
    color: #858585;
}
.add_order_item_btn{
    font-weight: 600;
}
.order_tracking{
    color: #0A75F5;
}

.details_container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    // gap: 10px;
    row-gap: 16px;
    column-gap: 10px;
    margin-top: -16px; // row-gap is adding space on top, to reduce that
}

.help_icon{
    position: absolute;
    top: 0;
    left: 8px;
    width: 15px;
    height: 15px;
}

.tooltip_info{
    position: relative;
    // max-width: 500px;
    &:hover {
        .tooltip_card {
            display: block;
        }
    }
    .tooltip_card{
        display: none;
        position: absolute;
        bottom: 12px;
        left: -17px;
        width: 100%;
        z-index: 1;
        min-width: 400px;
        padding: 5px 5px 5px 22px;
        background: #000;
        color: #fff;
        border-radius: 4px;
    }

}
</style>

<style>
.font_normal .multiselect__tags .multiselect__single{
    font-size: 14px;
}
</style>

