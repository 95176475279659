import SettingsContent from '../Wrapper';
import MessageWrapper from './MessagesWraper.vue';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      // path: frontendURL('accounts/:accountId/settings/messages'),
      path: frontendURL('accounts/:accountId/settings/canned-response'),
      component: SettingsContent,
      props: {
        headerTitle: 'MESSAGE_MGMT.HEADER',
        icon: 'chat',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          // name: 'message_wrapper',
          name: 'canned_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          // name: 'message_list',
          name: 'canned_list',
          component: MessageWrapper,
          roles: ['administrator','agent'],
        },
        // {
        //   path: 'list',
        //   name: 'agent_list',
        //   component: MessageWrapper,
        //   roles: ['administrator'],
        // },
      ],
    },
  ],
};
