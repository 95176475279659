var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stepBaseForm" }, [
    _c(
      "div",
      { staticClass: "campaign_heading" },
      [
        _c("h2", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("woot-button", {
          attrs: {
            icon: "dismiss",
            variant: "clear",
            colorScheme: "secondary"
          },
          on: { click: _vm.onClose }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "step_range_continer" },
      [
        _c("p", [
          _vm._v("\n          " + _vm._s(_vm.range_label) + "\n          ")
        ]),
        _vm._v(" "),
        _c("RangeInput", {
          attrs: {
            disabled: true,
            min: 0,
            max: _vm.max_steps,
            value: _vm.current_step,
            id: "range_step"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }