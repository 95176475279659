import { render, staticRenderFns } from "./CouponTable.vue?vue&type=template&id=3246d231&scoped=true&"
import script from "./CouponTable.vue?vue&type=script&lang=js&"
export * from "./CouponTable.vue?vue&type=script&lang=js&"
import style0 from "./CouponTable.vue?vue&type=style&index=0&id=3246d231&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3246d231",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3246d231')) {
      api.createRecord('3246d231', component.options)
    } else {
      api.reload('3246d231', component.options)
    }
    module.hot.accept("./CouponTable.vue?vue&type=template&id=3246d231&scoped=true&", function () {
      api.rerender('3246d231', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/catalogue/coupons/CouponTable.vue"
export default component.exports