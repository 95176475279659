import ApiClient from './ApiClient';

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  campaignsByPage(params = {}){
    // return axios.get(`${this.url}?page=${page}`);
    return axios.get(`${this.url}`,{params});
  }
  check_eligible_audience(payload){
    return axios.post(`${this.url}/check_eligible_audience`,payload);
  }
}

export default new CampaignsAPI();
