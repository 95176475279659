<template>
  <div>
    <label class="form_label"  :class="{ error:  $v.item.title.$error }">
        {{$t(label.title)}}
        <input
            v-model="item.title"
            type="text"
            @input="validateInput({ field: 'title', value: item.title, })"
        />
        <span v-if="$v.item.title.$error" class="message">
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}}
            {{constraint.title_limit}}
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}}
        </span>
    </label>
    <label class="form_label" :class="{ error:  $v.item.subtitle.$error }">
        {{$t(label.subtitle)}}
        <textarea
            v-model="item.subtitle"
            name="" id="" cols="20" rows="6"
            @input="validateInput({
                field: 'subtitle',
                value: item.subtitle
            })"
        />
        <span v-if="$v.item.subtitle.$error" class="message">
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}}
            {{constraint.subtitle_limit}}
            {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}}
        </span>
    </label>


    <!-- do loop on labels -->
    <template v-for="(actionBtn,btnIndex) in actionButtonlabel">
        <div v-if="actionBtn" :key="'actionBtn'+btnIndex">
            <label v-if="actionBtn.checkbox && !actionBtn.hideCheckbox" class="form_label check_label">
                <input v-model="item.actionButtons[btnIndex].visibility" type="checkbox" name="" id="">
                {{$t(actionBtn.checkbox)}}
            </label>
            <label v-if="actionBtn.button && item.actionButtons[btnIndex].visibility" class="form_label" :class="{ error: $v.item.actionButtons.$each[btnIndex].title.$error }">
                {{$t(actionBtn.button)}}
                <input
                    v-model="item.actionButtons[btnIndex].title"
                    type="text" name="" id=""
                    @input="validateInput({
                        field: 'actionButtons',
                        value: item.actionButtons[btnIndex].title
                    })"
                >
                <span v-if="$v.item.actionButtons.$each[btnIndex].title.$error" class="message">
                    {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_1')}}
                    {{constraint.button_title_limit}}
                    {{$t('FLOW.EXPRESS_BOT.CREATE_MODAL.LIMIT_ERR_MSG.MSG_PART_2')}}
                </span>
            </label>
        </div>
    </template>
  </div>
</template>

<script>
import { required, minValue, maxValue,maxLength  } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { countCharacters} from "./expressBot";

export default {
    props:{
        item:{
            default:{}
        },
        label:{
            default:{}
        },
        actionButtonlabel:{
            default:{}
        },
        constraint:{
            default:{}
        },
    },
    data:{},
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            merchant: 'merchant/getMerchant',
        }),
    },
    validations() {
        return {
            item: {
                title: {
                    required,
                    maxLength: maxLength(this.constraint.title_limit || 20),
                    checkLength(value){
                        const totalLength = countCharacters(this.currentUser,this.merchant,value).length;
                        return totalLength < this.constraint.title_limit
                    },
                },
                subtitle: {
                    required,
                    maxLength: maxLength(this.constraint.subtitle_limit || 100),
                    checkLength(value){
                        const totalLength = countCharacters(this.currentUser,this.merchant,value).length;
                        console.log({totalLength});
                        return totalLength < this.constraint.subtitle_limit
                    },
                },
                actionButtons: {
                    $each: {
                        title: {
                            required(value) {
                                return value ? true : true;
                            },
                            maxLength: maxLength(this.constraint.button_title_limit || 20),
                            checkLength(value){
                                const totalLength = countCharacters(this.currentUser,this.merchant,value).length;
                                console.log(totalLength);
                                return totalLength < this.constraint.button_title_limit
                            },
                        },
                    },
                },
            },
        };
    },
    methods: {
        validateInput({ field,value }) {
            console.log(field,this.$v.item[field]);
            this.$v.item[field]?.$touch();
        },
    }
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.error{
    position: relative;
    margin-bottom: 25px;
    .message{
        position: absolute;
        bottom: -32x;
        color: var(--r-500);
    }
}

.form_label{
    font-weight: 500;
    font-size: 12px;
    color: #1F1F1F;
    margin-top: 12px;

    input{
        font-size: 12px;
    }
    textarea{
        font-size: 12px;
    }

    .textField{
        margin-top: 8px;
        padding: 12px;
        font-weight: 500;
        font-size: 14px;
        color: #1F1F1F;
        border-color: #EEEEEE;
    }
    .textField::placeholder{
        color: #B8B8B8;
    }
}
.check_label{
    display: flex;
    align-items: center;
    gap: 4px;
    input{
        margin: 0;
    }
}
</style>


