/* global axios */
import ApiClient from './ApiClient';

class DeliveryService extends ApiClient {
  constructor() {
    super('delivery_services', { accountScoped: true });
  }

  // get logistic types
  fetchLogisticTypes(){
    return axios.get(`${this.url}/service_types`);
  }

  // update credit by email
  updateDeliveryScheduleTime(delivery_service_id,payload={}){
    return axios.patch(`${this.url}/${delivery_service_id}/confirm_delivery`,{...payload});
  }

}

export default new DeliveryService();
