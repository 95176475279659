<template>
  <label
        class="toggle_field mb_16"
    >
        <button
            type="button"
            class="toggle-button"
            :class="{ active: value }"
            role="switch"
            :aria-checked="value.toString()"
            @click="onToggleChange"
            >
            <span
                aria-hidden="true"
                :class="{ active: value }"
            ></span>
        </button>
        <span class="text_14">
            {{label}}
            <span v-if="infoList.length" class="tooltip_info">
                <fluent-icon class="help_icon" :icon="infoIcon" />
                <div class="tooltip_card">
                    <p v-for="(line,index) in infoList" class="mb_0 font_12" :key="index">{{line}}</p>
                </div>
            </span>
        </span>
    </label>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        infoIcon:{
            type: String,
            default: 'question-circle',
        },
        infoList:{
            type: Array,
            default: [],
        },
        value: {
            type: [Boolean],
            required: true,
            default: false,
        },

    },
    methods:{
        onToggleChange(){
            this.$emit('onToggle',!this.value)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_fonts.scss';
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';
@import '~dashboard/assets/scss/widgets/_fonts.scss';


.toggle_field{
    display: flex;
    align-items: center;
    gap: 8px;
}


.help_icon{
    position: absolute;
    top: 0;
    left: 8px;
    width: 15px;
    height: 15px;
}

.tooltip_info{
    position: relative;
    // max-width: 500px;
    &:hover {
        .tooltip_card {
            display: block;
        }
    }
    .tooltip_card{
        display: none;
        position: absolute;
        bottom: 12px;
        left: -17px;
        width: 100%;
        z-index: 1;
        min-width: 300px;
        padding: 5px 5px 5px 22px;
        background: #000;
        color: #fff;
        border-radius: 4px;
    }

}


// toogle button design START
.toggle-button {
  background-color: #F0F0F0;
  position: relative;
  display: inline-flex;
  height: 19px;
  width: 34px;
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  cursor: pointer;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  flex-shrink: 0;
}

.toggle-button.active {
//   background-color: var(--w-500);
  background-color: #E5CEFD;
}

.toggle-button span {
  --space-one-point-five: 14px;
  height: var(--space-one-point-five);
  width: var(--space-one-point-five);
  display: inline-block;
//   background-color: var(--white);
  background-color: #B8B8B8;
  transform: translate(2px, 1px);
  border-radius: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}
.toggle-button span.active {
    background-color: #8D25F6;
  transform: translate(var(--space-one-point-five), 1px);
}
// toogle button design END
</style>
