var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "add_campaign_container" }, [
        _c(
          "div",
          { staticClass: "campaign_heading" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD_STEP.TITLE")))]),
            _vm._v(" "),
            _c("woot-button", {
              attrs: {
                icon: "dismiss",
                variant: "clear",
                colorScheme: "secondary"
              },
              on: { click: _vm.discardBroadcast }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "step_range_continer" },
          [
            _c("p", [
              _vm._v(
                "\n              " +
                  _vm._s(_vm.step) +
                  "\n              " +
                  _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.RANGE_BAR.OF")) +
                  "\n              " +
                  _vm._s(_vm.max_steps) +
                  "\n              " +
                  _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.RANGE_BAR.SELECT_TEXT")) +
                  "\n          "
              )
            ]),
            _vm._v(" "),
            _c("RangeInput", {
              attrs: {
                disabled: true,
                min: 0,
                max: _vm.max_steps,
                value: _vm.step,
                id: "range_step"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.step == 1
          ? _c("div", [
              _c(
                "ul",
                { staticClass: "campaign_types" },
                _vm._l(_vm.types, function(item) {
                  return _c(
                    "li",
                    {
                      key: item.title,
                      staticClass: "campaign_type",
                      class:
                        _vm.campaign_type === item.value ? "active_type" : "",
                      on: {
                        click: function($event) {
                          return _vm.selectCampaignType(item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "type_icon" }, [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(item.icon) }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "type_body" }, [
                        _c("h3", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.title))
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "description" }, [
                          _vm._v(_vm._s(item.description))
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.step === 2
          ? _c("div", { staticClass: "step_container" }, [
              _c("section", [
                _c("article", [
                  _c("section", [
                    _c(
                      "label",
                      {
                        staticClass: "field_label",
                        class: { error: _vm.$v.appliedFilters.$error }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.AUDIENCE_LABEL")
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.appliedFilters.length
                      ? _c(
                          "div",
                          { staticClass: "medium-12 columns audience_filter" },
                          [
                            _vm._l(_vm.appliedFilters, function(filter, i) {
                              return _c("filter-input-box", {
                                key: i,
                                attrs: {
                                  "filter-groups": _vm.filterGroups,
                                  "grouped-filters": true,
                                  "input-type": _vm.getInputType(
                                    _vm.appliedFilters[i].attribute_key
                                  ),
                                  operators: _vm.getOperators(
                                    _vm.appliedFilters[i].attribute_key
                                  ),
                                  "dropdown-values": _vm.getDropdownValues(
                                    _vm.appliedFilters[i].attribute_key
                                  ),
                                  "show-query-operator":
                                    i !== _vm.appliedFilters.length - 1,
                                  "show-user-input": _vm.showUserInput(
                                    _vm.appliedFilters[i].filter_operator
                                  ),
                                  v: _vm.$v.appliedFilters.$each[i],
                                  reOrderLabels: "false"
                                },
                                on: {
                                  resetFilter: function($event) {
                                    return _vm.resetFilter(
                                      i,
                                      _vm.appliedFilters[i]
                                    )
                                  },
                                  removeFilter: function($event) {
                                    return _vm.removeFilter(i)
                                  }
                                },
                                model: {
                                  value: _vm.appliedFilters[i],
                                  callback: function($$v) {
                                    _vm.$set(_vm.appliedFilters, i, $$v)
                                  },
                                  expression: "appliedFilters[i]"
                                }
                              })
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "filter-actions mt-4" },
                              [
                                _c(
                                  "woot-button",
                                  {
                                    staticClass: "add_campaign_filter",
                                    attrs: {
                                      icon: "add",
                                      "color-scheme": "primary",
                                      variant: "clear",
                                      size: "small"
                                    },
                                    on: { click: _vm.appendNewFilter }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("CONTACTS_FILTER.ADD_NEW_FILTER")
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _vm.campaign_type === "running"
                  ? _c("section", [
                      _c(
                        "label",
                        { class: { error: _vm.$v.appliedFilters.$error } },
                        [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                _vm.$t(
                                  "CAMPAIGN.ADD.FORM.AUDIENCE.CUSTOM_LABEL"
                                )
                              ) +
                              "\n                  "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "medium-12 columns audience_filter" },
                        _vm._l([_vm.appliedFilters[0]], function(filter, i) {
                          return _c("filter-input-box", {
                            key: i,
                            attrs: {
                              "filter-groups": _vm.onGoingDateFilterGroups,
                              "grouped-filters": true,
                              "input-type": "",
                              operators: _vm.onGoingOperators,
                              "dropdown-values": _vm.getDropdownValues(
                                _vm.appliedFilters[i].attribute_key
                              ),
                              "show-query-operator": false,
                              "show-user-input": _vm.showUserInput(
                                _vm.appliedFilters[i].filter_operator
                              )
                                ? true
                                : true,
                              v: _vm.$v.appliedFilters.$each[i],
                              reOrderLabels: "true"
                            },
                            model: {
                              value: _vm.campaign_repeat_at,
                              callback: function($$v) {
                                _vm.campaign_repeat_at = $$v
                              },
                              expression: "campaign_repeat_at"
                            }
                          })
                        }),
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.campaign_type === "one_off"
                  ? _c(
                      "article",
                      { staticClass: "add_camp_calendar" },
                      [
                        _c("label", { staticClass: "field_label" }, [
                          _vm._v(
                            "\n                      " +
                              _vm._s(
                                _vm.$t("CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL")
                              ) +
                              "\n                  "
                          )
                        ]),
                        _vm._v(" "),
                        _c("woot-date-time-picker", {
                          staticStyle: { "margin-top": "'8px'" },
                          attrs: {
                            value: _vm.scheduledAt,
                            "disable-dates": 0,
                            "confirm-text": _vm.$t(
                              "CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM"
                            ),
                            placeholder: _vm.$t(
                              "CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER"
                            ),
                            displayFormat: "D MMM YYYY HH:mm:ss"
                          },
                          on: { change: _vm.onChange }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.campaign_type === "running"
                  ? _c(
                      "label",
                      { staticClass: "add_camp_calendar" },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.$t("CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL")
                            ) +
                            "\n                  "
                        ),
                        _c("woot-date-time-picker", {
                          attrs: {
                            value: _vm.scheduledAt,
                            "disable-dates": 0,
                            "confirm-text": _vm.$t(
                              "CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM"
                            ),
                            placeholder: _vm.$t(
                              "CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER"
                            ),
                            isTimeOnly: "true"
                          },
                          on: { change: _vm.onChange }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.step === 3
          ? _c(
              "div",
              [
                _c(
                  "article",
                  { class: { error: _vm.$v.selectedInbox.$error } },
                  [
                    _c(
                      "div",
                      { staticClass: "font_regular text_12 font_500" },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.INBOX.LABEL")
                            ) +
                            "\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedInbox,
                            expression: "selectedInbox"
                          }
                        ],
                        staticClass: "font_regular text_14 font_500",
                        staticStyle: { "margin-top": "8px" },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedInbox = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function($event) {
                              return _vm.onChangeInbox($event)
                            }
                          ]
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { disabled: "" },
                            domProps: { value: null }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "CAMPAIGN.ADD_STEP.ONE_OFF.INBOX.PLACEHOLDER"
                                )
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.inboxes, function(item) {
                          return _c(
                            "option",
                            { key: item.name, domProps: { value: item } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.$v.selectedInbox.$error
                      ? _c("span", { staticClass: "message" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("CAMPAIGN.ADD_STEP.ONE_OFF.INBOX.ERROR")
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "campaint_title_wrap",
                    class: { cann_margin: !_vm.$v.title.$error }
                  },
                  [
                    _c("woot-input", {
                      staticClass: "campaign_title  font_500 text_12",
                      class: { error: _vm.$v.title.$error },
                      attrs: {
                        label: _vm.isEmailTemplateAllow
                          ? _vm.$t("CAMPAIGN.ADD.FORM.EMAIL_SUBJECT.LABEL")
                          : _vm.$t("CAMPAIGN.ADD.FORM.TITLE.LABEL"),
                        type: "text",
                        error: _vm.$v.title.$error
                          ? _vm.$t("CAMPAIGN.ADD.FORM.TITLE.ERROR")
                          : "",
                        placeholder: _vm.isEmailTemplateAllow
                          ? _vm.$t(
                              "CAMPAIGN.ADD.FORM.EMAIL_SUBJECT.PLACEHOLDER"
                            )
                          : _vm.$t("CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER")
                      },
                      on: { blur: _vm.$v.title.$touch },
                      model: {
                        value: _vm.title,
                        callback: function($$v) {
                          _vm.title = $$v
                        },
                        expression: "title"
                      }
                    }),
                    _vm._v(" "),
                    !_vm.$v.title.$error
                      ? _c("p", { staticClass: "campaign_title_note" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.isEmailTemplateAllow
                                  ? _vm.$t(
                                      "CAMPAIGN.ADD.FORM.EMAIL_SUBJECT.NOTE"
                                    )
                                  : _vm.$t("CAMPAIGN.ADD.FORM.TITLE.NOTE")
                              ) +
                              "\n\n              "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("article", { staticClass: "canned_msg_wrap" }, [
                  _c(
                    "div",
                    {
                      staticClass: "canned_msg font_regular text_12 font_500",
                      class: { error: _vm.$v.message.$error }
                    },
                    [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.LABEL")) +
                          "\n                  "
                      ),
                      _vm.selectedTemplateHtml
                        ? _c("div", {
                            staticStyle: {
                              height: "300px",
                              "overflow-y": "scroll",
                              "margin-bottom": "10px",
                              "margin-top": "10px"
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.selectedTemplateHtml)
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showMentions &&
                      _vm.hasSlashCommand &&
                      !_vm.selectedTemplateHtml
                        ? _c("canned-response", {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.hideMentions,
                                expression: "hideMentions"
                              }
                            ],
                            attrs: {
                              top: "37",
                              "search-key": _vm.mentionSearchKey
                            },
                            on: { click: _vm.replaceText }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.selectedTemplateHtml
                        ? _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.message,
                                expression: "message"
                              }
                            ],
                            attrs: {
                              rows: "5",
                              type: "text",
                              placeholder: _vm.$t(
                                "CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER"
                              )
                            },
                            domProps: { value: _vm.message },
                            on: {
                              blur: _vm.$v.message.$touch,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.message = $event.target.value
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$v.message.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.ERROR"))
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal_btns" }, [
                    _vm.isEmailTemplateAllow
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "font_regular text_purple_primary font_500 text_12",
                            on: { click: _vm.openEmailTemplateListModal }
                          },
                          [
                            _vm._v(
                              "\n                      " +
                                _vm._s(
                                  _vm.$t("CAMPAIGN.ADD_STEP.BTNS.EMAIL_TPL")
                                ) +
                                "\n                  "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("AudienceAndTopup", {
                  attrs: {
                    broadcast_limit: _vm.broadcast_limit,
                    campaign_type: _vm.campaign_type,
                    eligible_audience: _vm.eligible_audience,
                    getCampaignDetails: _vm.getCampaignDetails,
                    onChangeBroadcastLimit: _vm.onChangeBroadcastLimit
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "campaign_btns" },
          [
            _c(
              "woot-button",
              {
                staticClass: "campaign_btn",
                attrs: { isDisabled: _vm.isDisableBackBtn, variant: "clear" },
                on: { click: _vm.handleBack }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.BTNS.BACK")) +
                    "\n          "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "woot-button",
              {
                staticClass: "campaign_btn campaign_btn_solid",
                attrs: { isDisabled: _vm.isDisableNextBtn },
                on: { click: _vm.handleNext }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.BTNS.NEXT")) +
                    "\n          "
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.isOpenEmailTemplateListModal
        ? _c("email-template-list", {
            on: {
              close: _vm.closeEmailTemplateListModal,
              selectTemplate: _vm.selectTemplate
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.isPreviewPopupOpen,
            "on-close": _vm.hidePreviewPopup
          },
          on: {
            "update:show": function($event) {
              _vm.isPreviewPopupOpen = $event
            }
          }
        },
        [
          _c("add-campaign", {
            attrs: {
              "initial-filter-types": _vm.filterTypes,
              initialForm: _vm.initialForm,
              onDone: _vm.onDiscardBroadcast
            },
            on: { "on-close": _vm.hidePreviewPopup }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("StripeComponent", {
        attrs: { "modal-value": _vm.isTopupModal },
        on: { "close-modal": _vm.closeTopup }
      }),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.isDiscardOpen,
            "on-close": _vm.closeDiscardBroadcast,
            modalMainContainerClass: "add_campaign_discard"
          },
          on: {
            "update:show": function($event) {
              _vm.isDiscardOpen = $event
            }
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "discard_icon" }, [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.discardIcon) } })
            ]),
            _vm._v(" "),
            _c("h2", { staticClass: "font_regular font_700 text_20 d_title" }, [
              _vm._v(_vm._s(_vm.$t("CAMPAIGN.ADD_STEP.DISCARD_MODAL.TITLE")))
            ]),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "d_description font_regular font_500 text_14" },
              [
                _vm._v(
                  _vm._s(_vm.$t("CAMPAIGN.ADD_STEP.DISCARD_MODAL.DESCRIPTION"))
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "discard_btns" },
              [
                _c(
                  "woot-button",
                  {
                    attrs: { "color-scheme": "alert", variant: "clear" },
                    on: { click: _vm.onDiscardBroadcast }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CAMPAIGN.ADD_STEP.DISCARD_MODAL.YES_BTN")
                        ) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "woot-button",
                  {
                    attrs: { variant: "clear", "color-scheme": "secondary" },
                    on: { click: _vm.closeDiscardBroadcast }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("CAMPAIGN.ADD_STEP.DISCARD_MODAL.NO_BTN")
                        ) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }