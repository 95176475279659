<template>
  <div class="column Xcontent-box">
    <nav>
      <ul class="campaignNav">
        <li
          class="campaignNav_item font_regular text_14"
          :class="campaignActiveNav == 'one_off' ? 'campaignNav_item campaignNav_itemActive' : ''"
          @click="campaignNavToggle('one_off')"
        >
          {{$t('CAMPAIGN.TABS.ONE_OFF')}}
        </li>
        <li
          class="campaignNav_item font_regular text_14"
          :class="campaignActiveNav == 'running' ? 'campaignNav_item campaignNav_itemActive' : ''"
          @click="campaignNavToggle('running')"
        >
          {{$t('CAMPAIGN.TABS.REPEAT')}}
        </li>
      </ul>
    </nav>

    <section>
      <div class="search_container">
        <SearchInput
          :placeholder="$t('CAMPAIGN.FILTERS.SEARCH_PLACEHOLDER')"
          v-model="searchValue"
          @search="onSearch"
        />
      </div>
      <div class="campaing_filters">
        <div class="campaing_filter">
          <p class="font_regular font_500 text_12">{{$t('CAMPAIGN.FILTERS.DATE')}}</p>
          <!-- <DateTimePicker
            :confirm-text="$t('CAMPAIGN.FILTERS.DATE_CONFIRM_BTN')"
            :placeholder="$t('CAMPAIGN.FILTERS.DATE_PLACEHOLDER')"
            :disable-dates="-2000"
            :value="selectedDate"
            @change="handleDateChange"
          /> -->
          <date-range-picker
            :confirm-text="$t('CAMPAIGN.FILTERS.DATE_CONFIRM_BTN')"
            :placeholder="$t('CAMPAIGN.FILTERS.DATE_PLACEHOLDER')"
            class="font_normal"
            :value="dateRange"
            @change="handleDateChange"
            :disabledDate="disableFilterDate"
          />
        </div>
        <div class="campaing_filter">
          <p class="font_regular font_500 text_12">{{$t('CAMPAIGN.FILTERS.STATUS')}}</p>
          <multiselect
            v-model="selectedStatus"
            :options="statusList"
            class="small-12 font_normal"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CAMPAIGN.FILTERS.STATUS_PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @select="applyFilterByStatuses($event)"
          />
        </div>
        <div class="campaing_filter">
          <p class="font_regular font_500 text_12">{{$t('CAMPAIGN.FILTERS.SORT_BY')}}</p>
          <multiselect
            v-model="sortBy"
            :options="sortByList"
            class="small-12 font_normal"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CAMPAIGN.FILTERS.SORTBY_PLACEHOLDER')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @select="applyFilterBySortBy($event)"
          />
        </div>

      </div>
    </section>

    <div>
      <campaigns-table
        :campaigns="campaigns"
        :show-empty-result="showEmptyResult"
        :is-loading="uiFlags.isFetching"
        :campaign-type="type"
        :isCampaignType="campaignActiveNav"
        @on-edit-click="openEditPopup"
        @on-delete-click="openDeletePopup"
      />
    </div>

    <div>
      <table-footer
          :on-page-change="onPageChange"
          :current-page="Number(meta.currentPage)"
          :total-count="meta.count"
        />
    </div>



    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-campaign
        :selected-campaign="selectedCampaign"
        @on-close="hideEditPopup"
      />
    </woot-modal>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CAMPAIGN.DELETE.CONFIRM.TITLE')"
      :message="$t('CAMPAIGN.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('CAMPAIGN.DELETE.CONFIRM.YES')"
      :reject-text="$t('CAMPAIGN.DELETE.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import CampaignsTable from './CampaignsTable';
import EditCampaign from './EditCampaign';
import timeMixin from 'dashboard/mixins/time';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import SearchInput from 'dashboard/components/ui/SearchInput.vue'
// import DateTimePicker from 'dashboard/components/ui/DateTimePicker.vue'
import DateRangePicker from "dashboard/components/ui/DateRangePicker.vue"

export default {
  components: {
    CampaignsTable,
    EditCampaign,
    TableFooter,
    SearchInput,
    // DateTimePicker,
    DateRangePicker,
  },
  mixins: [alertMixin, campaignMixin, timeMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    const endDate = new Date(); // Current date
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1); // One month ago

    return {
      showEditPopup: false,
      selectedCampaign: {},
      showDeleteConfirmationPopup: false,
      campaignActiveNav:'one_off',
      searchValue:'',
      dateRange: [
        // startDate,endDate
      ],
      selectedStatus:'',
      statusList:[
        {id:0,title:this.$t('CAMPAIGN.FILTERS.STATUS_LIST.ACTIVE')},
        {id:1,title:this.$t('CAMPAIGN.FILTERS.STATUS_LIST.COMPLETE')},
        {id:2,title:this.$t('CAMPAIGN.FILTERS.STATUS_LIST.PENDING')},
        {id:3,title:this.$t('CAMPAIGN.FILTERS.STATUS_LIST.REJECT')},
      ],
      sortBy:'',
      // it will be sort list
      sortByList:[
        {id:0,order:"scheduled_at",direction:"asc",
        title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.SCHEDULED_ASC')
        },
        {id:1,order:"scheduled_at",direction:"desc",
        title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.SCHEDULED_DESC')
        },
        {id:2,order:"broadcast_amount",direction:"asc",
        title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.BROADCAST_COST_ASC')
        },
        {id:3,order:"broadcast_amount",direction:"desc",
        title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.BROADCAST_COST_DESC')
        },
        {id:4,order:"recipient_count",direction:"asc",
        title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.TOTAL_RECIPIENT_ASC')
        },
        {id:5,order:"recipient_count",direction:"desc",
        title:this.$t('CAMPAIGN.FILTERS.CAMPAIGN_SORTBYS.TOTAL_RECIPIENT_DESC')
        },
      ],

    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      meta: 'campaigns/getMeta',
      labelList: 'labels/getLabels',
    }),

    campaigns() {
      // return all since filter is doing by API database
      const campaignList = this.$store.getters['campaigns/getAllCampaigns']
        .map(item => {

          return {
            ...item,
            url: item.trigger_rules.url,
            timeOnPage: item.trigger_rules.time_on_page,
            scheduledAt: item.scheduled_at
              ? this.messageStamp(new Date(item.scheduled_at), 'MMM d, h:mm a')
              : '---',
            childrens: item.childrens?.map(child => ({
              ...child,
              scheduledAt: child.scheduled_at
                ? this.messageStamp(new Date(child.scheduled_at), 'MMM d, h:mm a')
                : '---'
            }))
          };
        });



        return campaignList;

      /*
      // only running campaign
      if (this.campaignActiveNav == 'running') {
        const runninCampaignList = this.$store.getters['campaigns/getAllCampaigns']
                  .filter(campainRecord=>campainRecord.campaign_type === 'running')
                  .map(item => {
                    return {
                      ...item,
                      url: item.trigger_rules.url,
                      timeOnPage: item.trigger_rules.time_on_page,
                      scheduledAt: item.scheduled_at
                        ? this.messageStamp(new Date(item.scheduled_at), 'h:mm a')
                        : '---',
                    };
                  });
        return runninCampaignList;
      }


      // all campaign
      const allcampaigns = this.$store.getters['campaigns/getCampaigns'](this.campaignType).map(item => {
        return {
          ...item,
          url: item.trigger_rules.url,
          timeOnPage: item.trigger_rules.time_on_page,
          scheduledAt: item.scheduled_at
            ? this.messageStamp(new Date(item.scheduled_at), 'LLL d, h:mm a')
            : '---',
        };
      });

      return allcampaigns;
      */
    },
    showEmptyResult() {
      const hasEmptyResults =
        !this.uiFlags.isFetching && this.campaigns.length === 0;
      return hasEmptyResults;
    },
  },
  methods: {
    onSearch(value){
      this.fetchCampaignByFilter({q:value});
    },
    openEditPopup(response) {
      const { row: campaign } = response;
      this.selectedCampaign = campaign;
      this.showEditPopup = true;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedCampaign = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      const {
        row: { id },
      } = this.selectedCampaign;
      this.deleteCampaign(id);
    },
    async deleteCampaign(id) {
      try {
        await this.$store.dispatch('campaigns/delete', id);
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.ERROR_MESSAGE'));
      }
    },
    campaignNavToggle(campaignType){
      this.campaignActiveNav = campaignType;
      // if running: call api with kind=2, else call without kind
      if (campaignType === 'running') {
        const runningTypeCode = 2;
        this.fetchCampaignByFilter({kind: runningTypeCode});
      }else{
        this.fetchCampaignByFilter({});
      }
    },
     onPageChange(page) {
        // this.$store.dispatch('campaigns/getPageByCampaigns',{page});
        this.fetchCampaignByFilter({page});
    },
    fetchCampaignByFilter(newQuery={}){
      const order = typeof this.sortBy === 'object' ? this.sortBy?.order : null;
      const direction = typeof this.sortBy === 'object' ? this.sortBy?.direction : null;

      const query = {
        page: this.meta.currentPage,
        q: this.searchValue,
        start_at: this.dateRange?.[0],
        end_at: this.dateRange?.[1],
        kind: this.campaignActiveNav === 'running' ? 2 : null,
        order: order,
        direction: direction,
        status: this.selectedStatus?.id,
        ...newQuery,
      }
      // parse date appropriately
      const {end_date,start_date} = this.formatStartEndDate(query.start_at,query.end_at);
      query.start_at = start_date;
      query.end_at = end_date;

      const filteredQuery = Object.fromEntries(
        Object.entries(query).filter(([_, value]) => value !== null && value !== "" && value !== undefined)
      );
      this.$store.dispatch('campaigns/getPageByCampaigns',{...filteredQuery});
    },
    formatStartEndDate(startDate,endDate){
      if (!startDate || !endDate) {
        return {}
      }
      const providedStartDate = new Date(startDate);
      const providedEndDate = new Date(endDate);
      const option = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      const formattedStartDate = providedStartDate.toLocaleDateString('fr-CA', option);
      const formattedEndDate = providedEndDate.toLocaleDateString('fr-CA', option);

      return {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
    },
    handleDateChange(dateRange){
      this.dateRange = dateRange;
      this.fetchCampaignByFilter({
        start_at: dateRange[0],
        end_at: dateRange[1],
      });
    },
    applyFilterByStatuses(selectedOption){
      this.selectedStatus = selectedOption;
      this.fetchCampaignByFilter({status:selectedOption.id});
    },
    applyFilterBySortBy(selectedOption){
      this.sortBy = selectedOption;
      this.fetchCampaignByFilter({
        order: selectedOption.order,
        direction: selectedOption.direction,
      });
    },
    disableFilterDate(date,currentValues){
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 12*10); // 12*10 months ago// 10 year

      if (date > today) {
        return false;
      }

      if (date < threeMonthsAgo) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/widgets/_fonts.scss';

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: var(--space-one);
}

.content-box .page-top-bar::v-deep {
  padding: var(--space-large) var(--space-large) var(--space-zero);
}

.campaignNav{
  display: flex;
  margin: 0;
  list-style: none;
  gap: 1rem;
  padding: var(--space-small);
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius-normal);
  color: var(--s-600);
  line-height: var(--space-two);
  font-size: var(--font-size-small);
}
.campaignNav_item{
  font-weight: 500;
  border-color: transparent;
  padding: 10px 20px;
  cursor: pointer;

  &:hover{
    background: var(--w-25);
    color: var(--w-500);
  }
}
.campaignNav_itemActive{
  font-weight: 600;
  background: #F2E7FE;
  color: #8D25F6;
  border-radius: 6px;
}

.search_container{
  margin-top: 16px;
  max-width: 280px;
}

.campaing_filters{
  display: flex;
  gap: 12px;
  margin-top: 16px;
  .campaing_filter{
    // max-width: 350px;
    p{
      margin-bottom: 8px;
    }
  }
}
</style>


<style>
.campaing_filters .campaing_filter input{
  font-family: 'Figtree', sans-serif;
  line-height: 1.4;
  color: #1F1F1F;
  font-size: 14px;
  font-weight: 500;
}
</style>